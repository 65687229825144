import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Web3 from 'web3';
import {
    Col, Spinner, Container, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Switch, Stack } from '@mui/material';
import { Legend, Pie, Tooltip } from 'recharts';
import { fetchAllCurrencies1 } from '@/redux/actions/FetchCoinLists';
import Panel from '@/shared/components/Panel';
import getTooltipStyles from '@/shared/helpers';
import useMatchMedia from '@/shared/hooks/useMatchMedia';
import {
    colorAdditional, colorDarkText, scrollbarStyles, colorAccent,
} from '@/utils/palette';
import numeral from 'numeral';
import {
    DashboardPieChartFlex, Bullet,
    DashboardChartLegendTable,
    DashboardPieChartContainer,
} from '../BasicDashboardComponents';
import { useChainContext } from '../../context/context';
import { userInternalWalletBalanceRefresh, getUserPendingBalanceWebapp, userBalanceInternalWalletSubWallet, getUserLockBalanceWebapp } from '../../../utils/services/user.service';
import Alert from '../../../shared/components/Alert';
import { optionColors } from '../../../utils/constants/contants';


const tooltipFormatter = (value, name, entry) => {
    return [
        <span key="custom-label">{`${entry?.payload?.symbol
            } : ${numeral(entry.payload.graphVal).format('0,0.0000')}`}</span>
    ];
};
const OtcWalletCoins = ({ dir }) => {
    const { t } = useTranslation('common');
    const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
    const { refreshInternalBalace } = useChainContext()
    const themeName = useSelector(state => state.theme.className);
    const [isLoading, setIsLoading] = useState(false);
    const isMdBreakpoint = useMatchMedia(1199);
    const [twoFactors2, setTwoFactor2] = useState(false);
    const [pendingBalance, setPendingBalance] = useState([]);
    const [isLoading1, setIsLoading1] = useState(false);
    const [filterData, setFilterData] = useState([])

    const [otcLockInfo, setOtcLockBalance] = useState([]);

    const [data, setData] = useState([])
    const [coinList, setCoinList] = useState([])

    const fetchSubWallet = async () => {
        setIsLoading1(true)
        try {

            const res = await userBalanceInternalWalletSubWallet()
            setCoinList(res?.data?.data)
            setFilterData(res?.data?.data)
            setIsLoading1(false)

        } catch (error) {
            console.log("🚀 ~ fetchSubWal ~ error:", error)
            setIsLoading1(false)
        }
    }

    useEffect(() => {
        fetchSubWallet()
    }, [])

    // useEffect to  create array for graph data from colorOptions and CoinList
    useEffect(() => {
        const res = otcLockInfo?.map((item, index) => {
            const newVal = Number(item?.quantity);
            const priceValue = newVal.toFixed(2) || 0;
            const graphVal = parseFloat(priceValue);

            const fill = optionColors[index]?.value || '#ffff';
            const symbol = item.symbol;
            return { fill, graphVal, symbol };
        });
        setData(res);
    }, [otcLockInfo]);

    const refreshStatus = async () => {

        try {
            const res = await userInternalWalletBalanceRefresh();

            if (res.data.status == 200) {
                userBalanceInternalWalletSubWallet()
            }
            return res?.data?.Message
        } catch (error) {
            console.log("🚀 ~ refreshStatus ~ error:", error.response.data)
            return error.response.data
        }

    };


    const onMouseMove = (e) => {
        if (e.tooltipPosition) {
            setCoordinates({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
        }
    };

    const CustomSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: colorAccent,
            '&:hover': {
                backgroundColor: '#4ce1b65e',
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: colorAccent,
        },
    }));

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const handleTwoFactorChange = async (e) => {
        const value = e.target.checked;
        if (value) {
            // Filter out coins with quantity greater than 0
            const filteredCoinList = otcLockInfo.filter((coin) => Number(coin.quantity) > 0);
            const resGraphValues = data.filter((data) => data.graphVal > 0);
            setFilterData(filteredCoinList);
            setData(resGraphValues)
            setTwoFactor2(true);
        } else {
            // Set the entire data
            setFilterData(otcLockInfo);
            setData(data)
            setTwoFactor2(false);
        }
    };

    const handleRefresh = async () => {
        setIsLoading(true)
        fetchBalanceInfo()

        userBalanceInternalWalletSubWallet()


        setIsLoading(false)
    };

    useState(() => {
        if (refreshInternalBalace === true) {
            handleRefresh()
        }
    }, [refreshInternalBalace])

    const fetchBalanceInfo = async () => {
        setIsLoading(true);
        const res = await getUserPendingBalanceWebapp();
        setPendingBalance(res?.data?.data);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchBalanceInfo();
    }, []);


    const fetchOtcLockInfo = async () => {
        setIsLoading(true);
        const res = await getUserLockBalanceWebapp();
        setOtcLockBalance(res?.data?.data);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchBalanceInfo();
        fetchOtcLockInfo()
    }, []);
    return (
        <Panel lg={12} xxl={9} title={t('wallet.coins')} isLoading={isLoading} refreshRequest={handleRefresh}>
            {isLoading1 ? <Spinner style={{ color: colorAccent }} />
                : (
                    <>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <Stack direction="row" spacing={1}>
                                    <CustomSwitch
                                        {...label}
                                        value={twoFactors2}
                                        checked={twoFactors2}
                                        onChange={(e) => {
                                            handleTwoFactorChange(e);
                                        }}
                                        color="default"
                                    />
                                    <div className="mx-auto text-white mt-1">Hide Zero Balance</div>
                                </Stack>
                            </Col>
                        </Row>
                        <div className='d-flex d-xl-none w-100 align-items-center justify-content-center  ' >
                            <DashboardCoinsChartContainer width="90%">
                                <DashboardPieChartFlex>
                                    <Tooltip position={coordinates} {...getTooltipStyles(themeName)} formatter={tooltipFormatter} />
                                    <Pie
                                        data={data}
                                        dataKey="graphVal"
                                        cy={210}
                                        innerRadius={40}
                                        outerRadius={60}
                                        label
                                        onMouseMove={onMouseMove}
                                        minAngle={20}
                                        width={'50%'}
                                    />


                                </DashboardPieChartFlex>
                            </DashboardCoinsChartContainer>
                        </div>
                        <div dir="ltr" >
                            <DashboardCoinsChartContainer width="100%">
                                <DashboardPieChartFlex>
                                    <Tooltip position={coordinates} {...getTooltipStyles(themeName)} formatter={tooltipFormatter} />
                                    <Pie
                                        data={data}
                                        dataKey="graphVal"
                                        cy={230}
                                        innerRadius={80}
                                        outerRadius={100}
                                        className='d-none d-xl-block' // Apply responsive classes here
                                        label
                                        onMouseMove={onMouseMove}
                                        minAngle={20}
                                        width={'50%'}
                                    />

                                    <Legend
                                        layout="vertical"
                                        verticalAlign={isMdBreakpoint ? 'bottom' : 'top'}
                                        content={
                                            <DashboardChartLegendTable>
                                                <LegendCol>
                                                    <LegendColTitle>Coin List</LegendColTitle>
                                                    <LegendColContent>
                                                        {otcLockInfo.map((entry, index) => (
                                                            <li
                                                                key={index}
                                                                style={{
                                                                    width: "max-content",
                                                                    display: 'flex',
                                                                    flexWrap: 'nowrap',
                                                                    alignItems: 'center'
                                                                }}
                                                            >

                                                                <Bullet color={optionColors[index]?.value} />

                                                                {entry?.symbol}
                                                            </li>
                                                            //  )
                                                        ))}

                                                    </LegendColContent>
                                                </LegendCol>
                                                <LegendCol>
                                                    <LegendColTitle>Quantity</LegendColTitle>
                                                    <LegendColContent>
                                                        {otcLockInfo?.map(entry => (
                                                            <li key={entry.id} style={{
                                                                width: "max-content",
                                                                display: 'flex',
                                                                flexWrap: 'nowrap',
                                                                alignItems: 'center'
                                                            }}> {/* Assuming entry has an id */}
                                                                {numeral(entry.quantity).format('0,0.00000')}
                                                            </li>
                                                        ))}

                                                    </LegendColContent>
                                                </LegendCol>

                                                <LegendCol>
                                                    <LegendColTitle>Balance</LegendColTitle>
                                                    <LegendColContent>
                                                        {otcLockInfo.map(entry => (
                                                            <li style={{
                                                                width: "max-content",
                                                                display: 'flex',
                                                                flexWrap: 'nowrap',
                                                                alignItems: 'center'
                                                            }}>
                                                                {numeral(entry.balance).format('0,0.0000')}
                                                            </li>
                                                        ))}

                                                    </LegendColContent>
                                                </LegendCol>


                                            </DashboardChartLegendTable>
                                        }
                                        align={isMdBreakpoint ? 'center' : 'right'}
                                    />
                                </DashboardPieChartFlex>
                            </DashboardCoinsChartContainer>
                        </div>
                    </>
                )}

            <Alert color="info" neutral icon>
                <p> Portfolio with otc-related movements
                </p>
            </Alert>

        </Panel>
    );
};


export default OtcWalletCoins;

// region

const DashboardCoinsChartContainer = styled(DashboardPieChartContainer)`
  min-height: 360px !important;
  height: 100% !important;
  
  svg {
    min-height: 360px;
    position: absolute;
    left: 0;
    top: 0;
    
    @media(max-width: 1640px) {
      max-width: 90%;
    }
    
    @media(max-width: 1199px) {
      max-width: 100%;
    }
    
    @media(max-width: 768px) {
      height: 80% !important;
    }
    
    @media(max-width: 400px) {
      height: 60% !important;
    }

    .recharts-pie {
      transform: translateX(-60px);
      
      @media(max-width: 1919px) {
        transform: translateX(0);
      }
    }
  }
  
  .recharts-legend-wrapper {
    position: relative !important;
    max-width: 55%;
    width: 100% !important;
    z-index: 5;

    @media(max-width: 1919px) {
      max-width: 50%;
    }
    @media(max-width: 1199px) {
      max-width: 100%;
    }
    @media(max-width: 768px) {
      overflow-x: auto;
      
      ${scrollbarStyles};
    }
  }
`;

const LegendCol = styled('div')`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled('div')`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled('ul')`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;

const LegendSubtext = styled('span')`
  color: ${colorAdditional};
`;