import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
} from "@fortawesome/free-regular-svg-icons";
import {
  fetchTodoListData,
  editTodoElement,
  deleteTodoElement, addTodoElement,
} from './redux/actions';
import ItemEditModal from './components/form/ItemEditModal';
import TodoListWrapper from './components/TodoList';
import TodoSidebar from './components/TodoSidebar';
import DividerLine from './components/DividerLine';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { fetchCalendarEvents } from '../DefaultPage/Calendar/components/events';
import moment from 'moment';
import { updateCalenderActivity } from '../../utils/services/user.service';
import { Ring } from '@uiball/loaders';
import ViewModal from './components/form/ViewModal';


const Todo = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common');
  const [currentEditItem, setCurrentEditItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [filterByPriority, setFilterByPriority] = useState('all');

  const [prevTodoElements, setPrevTodoElements] = useState(null);
  const [loading, setLoading] = useState(false)
  const [reFetch, setReFetch] = useState(false)
  const [productDetails, openProductDetails] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState([]);


  const
    { calendarEvents }
    = useSelector(state => ({
      calendarEvents: state.calendarEvents.data,
    }));

    useEffect(() => {
      // Function to filter events based on priority
      const filterEvents = () => {
        if (filterByPriority === 'all') {
          // If filterByPriority is 'all', return all events
          return calendarEvents;
        } else {
          // Otherwise, filter events based on priority
          return calendarEvents.filter(event => event.priority === filterByPriority);
        }
      };
  
      // Set filteredEvents state
      setFilteredEvents(filterEvents());
    }, [filterByPriority, calendarEvents]);

  useEffect(() => {
    dispatch(fetchCalendarEvents());
  }, [dispatch]);
  const {
    theme, rtl, todoElements, isFetching,
  } = useSelector(state => ({
    todoElements: state.todo && state.todo.data && state.todo.data.elements
      && state.todo.data.elements.length > 0 ? [...state.todo.data.elements] : [],
    isFetching: state.todo && state.todo.isFetching,
    theme: state.theme,
    rtl: state.rtl && state.rtl.direction,
  }));

  const editTodoElementAction = (data) => {
    dispatch(editTodoElement(data));
  };

  const addTodoElementAction = (data) => {
    dispatch(addTodoElement(data));
  };

  const deleteTodoElementAction = (id) => {
    dispatch(deleteTodoElement(id));
  };

  useEffect(() => {
    if (JSON.stringify(todoElements) !== JSON.stringify(prevTodoElements)) {
      if (todoElements.length === 0 && prevTodoElements === null) { // You can delete it if you need
        dispatch(fetchTodoListData());
      }
      setPrevTodoElements([...todoElements]);
    }
  }, [prevTodoElements, todoElements, filterByPriority, dispatch]);

  const changeShowEditModal = (data) => {
    setShowEditModal(!showEditModal);
    setCurrentEditItem(data);
  };

  const filteringByPriority = (priority) => {
    setFilterByPriority(priority);
  };

  const incompleteTodoElements = useMemo(() => {
    const todos = todoElements.filter(item => !item.data.isArchived && !item.data.isCompleted);
    if (filterByPriority !== '') {
      return todos.filter(item => item.data.priority === filterByPriority);
    }
    return todos;
  }, [todoElements, filterByPriority]);

  const completedTodoElements = useMemo(() => {
    const todos = todoElements.filter(item => !item.data.isArchived && item.data.isCompleted);
    if (filterByPriority !== '') {
      return todos.filter(item => item.data.priority === filterByPriority);
    }
    return todos;
  }, [todoElements, filterByPriority]);

  const archivedTodoElements = useMemo(() => todoElements.filter(item => item.data.isArchived), [todoElements]);
  const header = [
    { accessor: '_id', Header: 'Activity id' },
    { accessor: 'allDay', Header: 'All Day' },
    // { accessor: 'contract', Header: 'Contract' },

    { accessor: 'endDate', Header: 'End Date' },
    { accessor: 'startDate', Header: 'Start Date' },
    { accessor: 'Priority', Header: 'Priority' },
    { accessor: 'Title', Header: 'Title' },
    {
      accessor: 'status',
      Header: 'Status',
    },
    {
      accessor: 'action',
      Header: 'Action',
    },
    {
      accessor: 'description',
      Header: 'Description',
    },
  ];
  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    isPagination: true,
  };

  const handleSelectChange = async (event, id) => {
    setLoading(true)
    const payload = {
      activity_id: id,
      status: event
    }
    const res = await updateCalenderActivity(payload)
    if (res) {
      setReFetch(!reFetch)
      dispatch(fetchCalendarEvents());
      setLoading(false)
    }
    setLoading(false)
  };

  const setProductDetails = (index) => {
    openProductDetails(true)
    const selectedItem = index; // Get the selected item from the data array
    setSelectedItem(selectedItem);
  };

  const data = [];

  filteredEvents?.map((item) => {
    const row = {
      _id: item?._id,
      allDay: item?.allDay ? 'Yes' : 'No',
      endDate: moment(item?.end).format('MM/DD/YYYY'),
      startDate: moment(item?.start).format('MM/DD/YYYY'),
      Priority: item?.priority,
      Title: item?.title,

      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
        //  style={{ backgroundColor: getStatusColor(item?.status) }}
        >
          <h6 className="text-white"><b>{item?.status}</b></h6>
        </span>
      ),
      action: (
        <div className='d-flex gap-3'>
          <select
            className="btn btn-primary success-button btn-sm  shadow-none"
            style={{ marginTop: '0px' }}
            onChange={(e) => handleSelectChange(e.target.value, item?._id)}
            disabled={item?.status === 'done'}
            value={item?.status}
          >
            <option value="active">Active</option>
            <option value="done" disabled={item?.status === 'done'} >Done</option>
          </select>


        </div>

      ),
      description: (
        <button
          className="btn btn-primary purple-button btn-sm ml-2 px-2 shadow-none"
          type="button"
          style={{ marginTop: '0px' }}
          onClick={() => setProductDetails(item)}
        >
          <FontAwesomeIcon icon={faEye} />
        </button>

      ),
    };

    data.push(row);
  });

  const CalendarTableData = useMemo(() => {
    return <ReactTableBase
      columns={header}
      data={data}
      tableConfig={tableConfig}
    />
  }, [filteredEvents,filterByPriority])
  return (

    <Container>

      {
        productDetails === true && <ViewModal showEditModal={productDetails} changeShowEditModal={() => openProductDetails(false)} selectedItem={selectedItem} />
      }

      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('todo_application.page_title')}</h3>
          {loading && <Spinner style={{ color: '#4ce1b6' }} />}

        </Col>
      </Row>
      <Row>
        <Col md={9} xl={10}>
          {!loading &&
            [CalendarTableData]
          }
        </Col>
        <Col md={3} xl={2}>
          <TodoSidebar
            changeShowEditModal={changeShowEditModal}
            filterByPriority={filteringByPriority}
          />
        </Col>
        <ItemEditModal
          theme={theme}
          rtl={rtl}
          showEditModal={showEditModal}
          currentEditItem={currentEditItem && currentEditItem.data}
          changeShowEditModal={changeShowEditModal}
          todoElements={todoElements}
          addTodoElementAction={addTodoElementAction}
          editTodoElementAction={editTodoElementAction}
        />
      </Row>
    </Container>
  );
};

export default Todo;
