import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import TopbarProfile from '../components/topbar/TopbarProfile';
import { useCart } from '../../../store/Layout/product/CartContext';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
import TopbarNotification from '../components/topbar/TopbarNotification';
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarButton,
} from '../components/topbar/BasicTopbarComponents';
import TopbarWallet from '../components/topbar/TopbarWallet';
import Logo from '../../../utils/img/custodiy.png';
import CustodiyLogo from '../../../utils/img/custodiyOld.png';
import TopbarNew from './TopbarNew';
import './topbar.css';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
}) => {
  const { cartCount, incrementCartCount, decrementCartCount } = useCart();
  return (
    <TopbarContainer>
      <TopbarLeft className='d-flex  '>
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
       <Link to={'/'} style={{
        display:'flex',
        justifySelf:'center'
       }}>
       <TopbarLogo src={Logo} />
       </Link>
      </TopbarLeft>
      <TopbarRight>
      
        <TopbarRightOver>
          <Link to="/showcart" className="cicon"> 
            {/* <FontAwesomeIcon className="mt-3" icon={faShoppingCart} size="2x" /> */}
            <TopbarButton type="button" className="mx-0 p-0">
              {/* <NotificationsIcon style={{ width: '24px', height: '24px' }} /> */}
              <FontAwesomeIcon
                className="mr-0 icon-hover"
                icon={faShoppingCart}
                style={{
                  width: '24px',
                  height: '24px',
                  marginTop: '32%',
                  transition: 'color 0.3s',
                }}
              />
              {cartCount > 0 && (
              <h5 className="leading-9 mt-2 ml-1 mr-0">
                <Badge bg="danger rounded-md">{cartCount}</Badge>
              </h5>
              )}
            </TopbarButton>
          </Link>
          <TopbarNotification />
          <TopbarProfile />
          <TopbarWallet />
        </TopbarRightOver>
      </TopbarRight>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
