/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import EyeIcon from 'mdi-react/EyeIcon';
import { Ring } from '@uiball/loaders';
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEnvelope, faUser, faMap, faFile,
}
  from '@fortawesome/free-regular-svg-icons';
// eslint-disable-next-line import/order
// import Modal from 'react-modal'; // Import react-modal
import Panel from '@/shared/components/Panel';
import { CustomSelect, CustomMultiSelect } from '../../../shared/components/CustomSelect';
import SignaturePad from './SignaturePad';
import PdfSignature from './PdfSignature';
import {
  getMyApproval,
  approveOrder,
  buyerApproveOrder,
  buyerRejectOrder,
  rejectOrder,
  approveOrderBank,
  rejectOrderBank,
  buyerApproveOrderBank,
  buyeRejectOrderBank,
} from '../../../utils/services/user.service';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import WebCam from '../../../shared/components/WebCam/WebCam';


const GetMyApproval = () => {
  const [orders, setOrders] = useState([]);
  const [getTransactions1, setTransactions1] = useState([]);
  const [rejectionReason, setRejectionReason] = useState('');
  const [acceptReason, setAcceptReason] = useState('');
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedOrderId1, setSelectedOrderId1] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [isModalOpen1, setIsModalOpen1] = useState(false); // State to control modal visibility
  const [gettotalChargesTable, totalChargesTable] = useState(0);
  const [gettotalQtyTable, totalQtyTable] = useState(0);
  const [cartSybmol, setCartSybmol] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [IsBuyerApprover, setIsBuyerApprover] = useState(false);
  const [IsApprover, setIsApprover] = useState(false);
  const [IsRejected, setIsRejected] = useState(false);
  const [selectedOrderQty, setSelectedOrderQty] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState('');
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  const [selectedOperation, setSelectedOperation] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedOrderNo, setSelectedOrderNo] = useState('');
  const [selectedAmount, setSelectedAmount] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selectedApprover, setSelectedApprover] = useState('');
  const [isSearchOrderNo, setIsSearchOrderNo] = useState(false);
  const [isSearchAddress, setIsSearchAddress] = useState(false);
  const [isSearchStatus, setIsSearchStatus] = useState(false);
  const [isSearchPaymentStatus, setIsSearchPaymentStatus] = useState(false);
  const [isSearchApprovalStatus, setIsSearchApprovalStatus] = useState(false);
  const [isSearchOrderStatus, setIsSearchOrderStatus] = useState(false);
  const [isSearchAmount, setIsSearchAmount] = useState(false);
  const [isSearchOperation, setIsSearchOperation] = useState(false);
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [rejectFile, setFile] = useState(null);
  const [approveFile, setFile1] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [approveFile2, setFile2] = useState(null);

  const [approverDoc, setApproverDoc] = useState(null);
  const [approverReason, setApproverReason] = useState(null);
  const [bapproverDoc, setBApproverDoc] = useState(null);
  const [bapproverReason, setBApproverReason] = useState(null);
  const [rejectDoc, setRejectDoc] = useState(null);
  const [rejectReason, setRejectReason] = useState(null);
  const [brejectDoc, setBRejectDoc] = useState(null);
  const [brejectReason, setBRejectReason] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [signatureImage1, setSignatureImage1] = useState(null);
  const [orderSymbol, setOrderSymbol] = useState("");

  const [getSubmit, setSubmit] = useState(false);
  const REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL = 'https://testnet.bscscan.com/tx/';
  const REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL = 'https://sepolia.etherscan.io/tx/';
  const REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL = 'https://mumbai.polygonscan.com/tx/';
  const { REACT_APP_POLYGON_MATIC, REACT_APP_SMART_CHAIN, REACT_APP_ETHERIUM_CHAIN } = process.env;


  const [showWebCam, setShowWebCap] = useState(false);

  const [capturedImage, setCapturedImage] = useState(null);



  const [imageSelfie, setImageSelfie] = useState(null);

  const fileInputRef2 = useRef(null);
  const fileSelfieUpload = useRef(null);




  function base64ToFile(base64String, filename) {
    if (
      filename && base64String
    ) {
      // Split the base64 string to get the content type and the data
      const parts = base64String.split(';base64,');
      const contentType = parts[0]?.split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw?.length;
      const uint8Array = new Uint8Array(rawLength);

      // Convert the raw binary data to Uint8Array
      for (let i = 0; i < rawLength; ++i) {
        uint8Array[i] = raw?.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: contentType });

      // Create a File object from the Blob
      return new File([blob], filename, { type: contentType });
    }
  }
  const capturedImageFile = base64ToFile(capturedImage, "captured_image.png");


  const handleCaptureImage = (imageSrc) => {
    // Handle the captured image here (e.g., upload it to a server)
    setCapturedImage(imageSrc);
    setShowWebCap(false); // Hide the webcam modal after capturing the image
  };
  const handleButtonSelfieClick = () => {
    fileSelfieUpload.current.click();
  };
  const handleSelfieUpload = (e) => {
    const selectedImage = e.target.files[0];
    setImageSelfie(selectedImage);
  };

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const payload = {
        limit: 10,
        pageNumber: 1,
        approver_status: 'all',
      };
      const response = await getMyApproval(payload);

      const responseData = response.data;
      // console.log('response', response);
      if (responseData.data && responseData.data.length > 0) {
        // console.log('Daaaata', responseData.data);
        setOrders(responseData.data);
        setTransactions1(responseData.data);
      } else {
        console.error('No orders found.');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleApprove = async (orderId) => {
    const formData = new FormData();
    formData.append('order_id', selectedOrderId1);
    formData.append('approver_reason', acceptReason);
    formData.append('File', capturedImageFile || imageSelfie);
    formData.append('File2', approveFile2);
    const requestData = {
      order_id: selectedOrderId1,
      approver_reason: acceptReason,
      File: approveFile2,
    };

    setIsModalOpen1(false);
    setIsLoading(true);
    try {
      if (!acceptReason && !approveFile2) {
        setIsLoading(false);
        toast.error('Approve reason & document is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (!capturedImageFile && !imageSelfie) {
        setIsLoading(false);
        toast.error('e-signed document is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (!acceptReason) {
        setIsLoading(false);
        toast.error('Approve reason is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (!approveFile2) {
        setIsLoading(false);
        toast.error('Approve document is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      let response;
      if (IsBuyerApprover === true) {

        response = orderSymbol == "EURO" ? await buyerApproveOrderBank(formData) : await buyerApproveOrder(formData);
      }
      if (IsApprover === true) {


        response = orderSymbol == "EURO" ? await approveOrderBank(formData) : await approveOrder(formData);
      }

      if (response.status === 200) {
        fetchOrders()
        setIsLoading(false);
        toast.success('Order approved successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        // Order approved successfully, you can handle this as needed
        // console.log('Order approved successfully');
        // Reload orders or update state if needed
      } else {
        setIsLoading(false);
        console.error('Error approving order:', response.statusText);
        toast.error('Error approving order:', response.statusText, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setIsModalOpen1(false);
    } catch (error) {
      setIsLoading(false);
      setIsModalOpen1(false);
      console.error('Error approving order:', error);
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };



  const getPDf = (value) => {

    setSubmit(true);
    setFile2(value);
  };

  const getPDf1 = (value) => {
    setSubmit(false);
  };

  const handleShow = (order) => {

    setSelectedOrder(order);
    // console.log('handleShow order', order);
    let totalPrice = 0;
    // Iterate through the array and sum up the prices
    const orderData = order?.product_details;
    totalPrice = orderData?.reduce((total, product) => total + product?.quantity, 0);
    setSelectedOrderQty(totalPrice);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const history = useHistory();

  const handleClick = () => {
    // Redirect to '/roomchat' route
    history.push('/roomchat');
  };

  const openRejectModal = (orderId) => {
    setSelectedOrderId(orderId._id);
    if (orderId?.buyer_is_approved_status === 'pending') {
      setIsBuyerApprover(true);
      setOrderSymbol(orderId?.symbol)

    }
    if (orderId?.is_approved_status === 'pending') {
      setIsApprover(true);
      setOrderSymbol(orderId?.symbol)

    }
    setIsModalOpen(true); // Open the modal when "Reject" is clicked
  };

  const openAcceptModal = (orderId) => {
    setSelectedOrderId1(orderId._id);
    if (orderId.buyer_is_approved_status === 'pending') {
      setIsBuyerApprover(true);
    }
    if (orderId.is_approved_status === 'pending') {
      setIsApprover(true);
      setOrderSymbol(orderId?.symbol)
    }
    setIsModalOpen1(true); // Open the modal when "Reject" is clicked
    setOrderSymbol(orderId?.symbol)
  };

  const closeRejectModal = () => {
    setSelectedOrderId(null);
    setRejectionReason('');
    setFile(null);
    setSubmit(false);
    setIsModalOpen(false); // Close the modal when "Cancel" is clicked
  };

  const closeAcceptModal = () => {
    setSelectedOrderId1(null);
    setFile1(null);
    setSubmit(false);
    setAcceptReason('');
    setApproverReason(null);
    setApproverDoc(null);
    setBApproverReason(null);
    setBApproverDoc(null);
    setRejectReason(null);
    setRejectDoc(null);
    setBRejectReason(null);
    setBRejectDoc(null);
    setIsModalOpen1(false); // Close the modal when "Cancel" is clicked
  };

  const openProductDetails = (order) => {


    // function base64ToArrayBuffer(base64) {
    //   const binaryString = window.atob(base64); // Decode base64
    //   const len = binaryString.length;
    //   const bytes = new Uint8Array(len);
    //   for (let i = 0; i < len; i++) {
    //     bytes[i] = binaryString.charCodeAt(i);
    //   }
    //   return bytes.buffer;
    // }
    // if (order.approve_documents !== '') {
    //   const parsedData = JSON?.parse(order?.approve_documents);
    //   const arrayBuffer = base64ToArrayBuffer(parsedData.pdfData);
    //   const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    //   const blobUrl = URL.createObjectURL(blob);
    //   setApproverDoc(blobUrl);
    //   setApproverReason(order.approve_reason);
    // }

    // if (order.buyer_approve_documents !== '') {
    //   const parsedData = JSON.parse(order.buyer_approve_documents);
    //   const arrayBuffer = base64ToArrayBuffer(parsedData.pdfData);
    //   const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    //   const blobUrl = URL.createObjectURL(blob);
    //   setBApproverDoc(blobUrl);
    //   setBApproverReason(order.buyer_approve_reason);
    // }

    // if (order.rejection_documents !== '') {
    //   const parsedData = JSON.parse(order.rejection_documents);
    //   const arrayBuffer = base64ToArrayBuffer(parsedData.pdfData);
    //   const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    //   const blobUrl = URL.createObjectURL(blob);
    //   setRejectDoc(blobUrl);
    //   setRejectReason(order.rejection_reason);
    // }

    // if (order.buyer_rejection_documents !== '') {
    //   const parsedData = JSON.parse(order.buyer_rejection_documents);
    //   const arrayBuffer = base64ToArrayBuffer(parsedData.pdfData);
    //   const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    //   const blobUrl = URL.createObjectURL(blob);
    //   setBRejectDoc(blobUrl);
    //   setBRejectReason(order.buyer_rejection_reason);
    // }

    if (order.buyer_is_approved_status === 'pending') {
      setIsBuyerApprover(true);
    }
    if (order.is_approved_status === 'pending') {
      setIsApprover(true);
    }
    setSelectedOrder(order);
    const Store = order.product_details;
    const calculateTotalCharges = () => Store.reduce((total, item) => total + item.price, 0);
    const calculateTotalQty = () => Store.reduce((total, item) => total + item.quantity, 0);
    setCartSybmol(order.symbol);
    totalChargesTable(calculateTotalCharges());
    totalQtyTable(calculateTotalQty());
    setRatings({}); // Reset ratings when opening the modal
    setComments({}); // Reset comments when opening the modal
    setModalIsOpen(true);
  };

  const approveTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Approve
    </Tooltip>
  );

  const rejectTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Reject
    </Tooltip>
  );

  const BapproveTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Buyer Approve
    </Tooltip>
  );

  const BrejectTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Buyer Reject
    </Tooltip>
  );

  const detailTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Detail
    </Tooltip>
  );

  const trackTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Track Order
    </Tooltip>
  );

  const closeProductDetails = () => {
    setSelectedOrder(null);
    setApproverDoc(null);
    setApproverReason(null);
    setBApproverDoc(null);
    setBApproverReason(null);
    setRejectDoc(null);
    setRejectReason(null);
    setBRejectDoc(null);
    setBRejectReason(null);
    setIsBuyerApprover(false);
    setIsApprover(false);
    setModalIsOpen(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleFileChange1 = (e) => {
    const selectedFile1 = e.target.files[0];
    setFile1(selectedFile1);
  };

  function dataURLtoBlob1(dataUrl) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const handleSignatureEnd = (dataUrl) => {

    const blob = dataURLtoBlob1(dataUrl);
    const fileName = 'signature.png'; // You can set the desired file name
    const fileType = 'image/png'; // Adjust the file type accordingly
    const file = new File([blob], fileName, { type: fileType });

    setSignatureImage(dataUrl);
    setSignatureImage1(file);
  };

  const optionOrderStatus = [
    { value: 'pending', label: 'Pending' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'paid', label: 'Paid' },
    { value: 'completed', label: 'Completed' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'declined', label: 'Declined' },
    { value: 'failed', label: 'Failed' },
  ];

  const optionPaymentStatus = [
    { value: 'pending', label: 'Pending' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'paid', label: 'Paid' },
    { value: 'completed', label: 'Completed' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'declined', label: 'Declined' },
    { value: 'failed', label: 'Failed' },
  ];

  const optionApprovalStatus = [
    { value: 'pending', label: 'Pending' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'paid', label: 'Paid' },
    { value: 'completed', label: 'Completed' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'declined', label: 'Declined' },
    { value: 'failed', label: 'Failed' },
  ];

  const searchFilters = [
    { value: 'order_no', label: 'Order No' },
    { value: 'address', label: 'Delivery Address' },
    { value: 'amount', label: 'Amount' },
    { value: 'payment_status', label: 'Payment Status' },
    { value: 'approval_status', label: 'Approval Status' },
    { value: 'order_status', label: 'Order Status' },
  ];

  const handleSearchFilterChange = (value) => {
    if (value.value === 'order_no') {
      setIsSearchOrderNo(true);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'address') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(true);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'amount') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(true);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'payment_status') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(true);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'approval_status') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(true);
      setIsSearchOrderStatus(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'order_status') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(true);
      setSelectedSearch(value.value);
    }
  };

  const handlePaymentStatusChange = (value) => {
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.Payment_status === value.value);
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    setSelectedPaymentStatus(value.value);
    setOrders(completedTransactions);
  };

  const handleApprovalStatusChange = (value) => {
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.is_approved_status === value.value);
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    setSelectedApprovalStatus(value.value);
    setOrders(completedTransactions);
  };

  const handleOrderStatusChange = (value) => {
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.order_status === value.value);
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    setSelectedOrderStatus(value.value);
    setOrders(completedTransactions);
  };

  const handleAddressChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.delivery_address.toLowerCase().includes(partialValue.toLowerCase()));
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    setOrders(completedTransactions);
    setSelectedAddress(value.target.value);
  };

  const handleOrderNoChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction._id.toLowerCase().includes(partialValue.toLowerCase()));
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    setOrders(completedTransactions);
    setSelectedOrderNo(value.target.value);
  };

  const handleAmountChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => String(transaction.total_amount).includes(partialValue));
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    setOrders(completedTransactions);
    setSelectedAmount(value.target.value);
  };

  const resetData = (order) => {
    setIsLoading(true);
    setSelectedPaymentStatus('');
    setSelectedApprovalStatus('');
    setSelectedOrderStatus('');
    setSelectedAddress('');
    setSelectedOrderNo('');
    setSelectedAmount('');
    setOrders(getTransactions1);
    setIsLoading(false);
  };

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    isPagination: true,
  };
  const header = [
    { accessor: 'order_no', Header: 'Order No.' },
    { accessor: 'quantity', Header: 'Quantity' },
    { accessor: 'address', Header: 'Delivery Address' },
    { accessor: 'total', Header: 'Total', disableGlobalFilter: true },
    { accessor: 'payment_status', Header: 'Payment' },
    { accessor: 'approval_status', Header: 'Approval' },
    { accessor: 'buyer_approval_status', Header: 'Buyer Approval' },
    { accessor: 'order_status', Header: 'Order' },
    {
      accessor: 'action', Header: 'Action', disableGlobalFilter: true, width: 80,
    },
  ];

  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case 'completed':
        return '#4ce1b6';
      case 'paid':
        return '#4ce1b6';
      case 'pending':
        return '#f6da6e';
      case 'rejected':
        return '#ff4861';
      case 'waiting':
        return '#f6da6e';
      case 'declined':
        return '#ff4861';
      case 'failed':
        return '#ff4861';
      default:
        return '#70bbfd';
    }
  };

  const data = [];

  orders.forEach((item) => {
    data.push({
      order_no: item._id,
      quantity: item.total_quantity,
      address: item.delivery_address.length > 15 ? `${item.delivery_address.substring(0, 12)}...` : item.delivery_address,
      total: `${item.total_amount} ${item.symbol}`,
      payment_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.Payment_status) }}
        >
          <h6 className="text-white"><b>{item.Payment_status}</b></h6>
        </span>
      ),
      approval_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.is_approved_status) }}
        >
          <h6 className="text-white"><b>{item.is_approved_status}</b></h6>
        </span>
      ),
      buyer_approval_status: (
        item.buyer_approver_userId !== '' ? (
          <span
            className="badge px-2 py-1 text-capitalize"
            style={{ backgroundColor: getStatusColor(item.buyer_is_approved_status) }}
          >
            <h6 className="text-white"><b>{item.buyer_is_approved_status}</b></h6>
          </span>
        ) : (
          '-'
        )
      ),
      order_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.order_status) }}
        >
          <h6 className="text-white"><b>{item.order_status}</b></h6>
        </span>
      ),
      // payment_status: (
      //   item.Payment_status === 'paid' ? (
      //     <Badge bg="success" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.Payment_status}</Badge>
      //   ) : (
      //     <Badge bg="danger" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.Payment_status}</Badge>
      //   )
      // ),
      // approval_status: (
      //   item.is_approved_status === 'waiting' ? (
      //     <Badge bg="warning" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   ) : item.is_approved_status === 'pending' ? (
      //     <Badge bg="primary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   ) : item.is_approved_status === 'shipped' ? (
      //     <Badge bg="secondary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   ) : item.is_approved_status === 'delivered' ? (
      //     <Badge bg="info" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   ) : (
      //     <Badge bg="success" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   )
      // ),
      // order_status: (
      //   item.order_status === 'waiting' ? (
      //     <Badge bg="warning" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : item.order_status === 'pending' ? (
      //     <Badge bg="primary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : item.order_status === 'shipped' ? (
      //     <Badge bg="secondary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : item.order_status === 'delivered' ? (
      //     <Badge bg="info" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : (
      //     <Badge bg="success" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   )
      // ),
      action: (
        <HashLink>
          {item.is_approved_status === 'pending' && (
            <>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 200, hide: 200 }}
                overlay={approveTooltip}
              >
                <i
                  onClick={() => openAcceptModal(item)}
                  style={{
                    marginLeft: '6px', marginRight: '2px', color: '#108f2d', marginTop: '5px', fontSize: '23px',
                  }}
                  className="fa fa-check-circle"
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 200, hide: 200 }}
                overlay={rejectTooltip}
              >
                <i
                  onClick={() => openRejectModal(item)}
                  style={{
                    marginLeft: '6px', marginRight: '5px', color: '#dc3545', marginTop: '4px', fontSize: '25px',
                  }}
                  className="fa fa-close"
                />
              </OverlayTrigger>
            </>
          )}
          {item.buyer_is_approved_status === 'pending' && (
            <>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 200, hide: 200 }}
                overlay={BapproveTooltip}
              >
                <i
                  onClick={() => openAcceptModal(item)}
                  style={{
                    marginLeft: '6px', marginRight: '2px', color: '#108f2d', marginTop: '5px', fontSize: '23px',
                  }}
                  className="fa fa-check-circle"
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 200, hide: 200 }}
                overlay={BrejectTooltip}
              >
                <i
                  onClick={() => openRejectModal(item)}
                  style={{
                    marginLeft: '6px', marginRight: '5px', color: '#dc3545', marginTop: '4px', fontSize: '25px',
                  }}
                  className="fa fa-close"
                />
              </OverlayTrigger>
            </>
          )}
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 200, hide: 200 }}
            overlay={detailTooltip}
          >
            {/* <i
              onClick={() => openProductDetails(item)} 
              style={{
 marginLeft: '6px', marginRight: '5px', color: 'white', marginTop: '9px', fontSize: '18px',
}}
              className="fa fa-eye"
            /> */}
            {/* <EyeIcon style={{ padding: '2px' }} /> */}
            <button
              className="btn btn-primary purple-button btn-sm ml-2 px-2 shadow-none rounded-circle"
              type="button"
              style={{ marginTop: '0px' }}
              onClick={() => openProductDetails(item)}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 200, hide: 200 }}
            overlay={trackTooltip}
          >
            <button
              className="btn btn-secondary btn-sm ml-2 py-1 pl-1 shadow-none rounded-circle"
              type="button"
              style={{ marginTop: '0px' }}
              onClick={() => handleShow(item)}
            >
              <i style={{ marginLeft: '2px', marginRight: '-2px' }} className="fas fa-shipping-fast" />
            </button>
          </OverlayTrigger>
        </HashLink>
      ),
    });
  });

  const handleRejectOrder = async () => {
    const formData = new FormData();
    formData.append('order_id', selectedOrderId);
    formData.append('rejection_reason', rejectionReason);
    formData.append('File', capturedImageFile || imageSelfie);
    formData.append('File2', approveFile2);
    setIsModalOpen(false);
    setIsLoading(true);
    try {
      if (!rejectionReason && !approveFile2) {
        setIsLoading(false);
        toast.error('Rejection reason & document is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (!capturedImage && !imageSelfie) {
        setIsLoading(false);
        toast.error('e-signed document is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (!rejectionReason) {
        setIsLoading(false);
        toast.error('Rejection reason is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (!approveFile2) {
        setIsLoading(false);
        toast.error('Rejection document is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      let responseReject;
      // if (IsBuyerApprover === true) {
      //   responseReject = await buyerRejectOrder(formData);
      // } else {
      //   responseReject = await rejectOrder(formData);
      // }
      // console.log('IsBuyerApprover', IsBuyerApprover);
      // console.log('IsApprover', IsApprover);
      if (IsBuyerApprover === true) {

        responseReject = orderSymbol == "EURO" ? await buyeRejectOrderBank(formData) : await buyerRejectOrder(formData);


      }
      if (IsApprover === true) {
        // responseReject = await rejectOrder(formData);
        responseReject = orderSymbol == "EURO" ? await rejectOrderBank(formData) : await rejectOrder(formData);

      }
      // console.log('responseReject', responseReject);

      if (responseReject.status === 200) {
        fetchOrders()
        setIsLoading(false);
        toast.success('Order is successfully rejected and system refund the payment', {
          position: toast.POSITION.TOP_RIGHT,
        });
        // Order approved successfully, you can handle this as needed
        // console.log('Order approved successfully');
        // Reload orders or update state if needed
      } else {
        setIsLoading(false);
        console.error('Error rejecting order:', responseReject.statusText);
        toast.error('Error rejecting order:', responseReject.statusText, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setIsModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      setIsModalOpen(false);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel
        lg={12}
        title="My Approval Orders"
        // isLoading={isLoading}
        refreshRequest={() => fetchOrders()}
      >
        <Row className="mb-5">
          <Col xs={12} md={4} lg={3} hidden>
            <label htmlFor="features" className="form-label text-white mb-0">Search Filter</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedSearch}
                  onChange={handleSearchFilterChange}
                  options={searchFilters}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* {isSearchOrderNo && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Order No</label>
              <ChatSearchInput
                type="text"
                value={selectedOrderNo}
                className="form-control mt-2"
                placeholder="Search by Order No"
                onChange={handleOrderNoChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchAddress && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Address</label>
              <ChatSearchInput
                type="text"
                value={selectedAddress}
                className="form-control mt-2"
                placeholder="Search by Address"
                onChange={handleAddressChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchAmount && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Amount</label>
              <ChatSearchInput
                type="text"
                value={selectedAmount}
                className="form-control mt-2"
                placeholder="Search by Amount"
                onChange={handleAmountChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchPaymentStatus && ( */}
          <Col xs={12} md={4} lg={3}>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0">Payment Status</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedPaymentStatus}
                  onChange={handlePaymentStatusChange}
                  options={optionPaymentStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchApprovalStatus && ( */}
          <Col xs={12} md={4} lg={3}>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Approval Status</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedApprovalStatus}
                  onChange={handleApprovalStatusChange}
                  options={optionApprovalStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchOrderStatus && ( */}
          <Col xs={12} md={4} lg={3}>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Order Status</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedOrderStatus}
                  onChange={handleOrderStatusChange}
                  options={optionOrderStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* )}       */}
          <Col xs={12} md={4} lg={3}>
            <label htmlFor="color" style={{ fontSize: 'small', color: 'transparent' }} className="form-label mb-0">.</label>
            {/* <Button   
              variant="primary" 
              className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"   
              onClick={() => resetData(1)}
            >
              Reset  <i className="fa fa-refresh" />
            </Button> */}
            <Button
              onClick={() => resetData(1)}
              style={{
                background: '#4ce1b6',
              }}
              size="md"
              className="py-1 btn-block mt-2"
              variant="success"
            >Reset  <i className="fa fa-refresh" />
            </Button>
          </Col>
          {!isSearchApprovalStatus && !isSearchOrderNo && !isSearchStatus && !isSearchAddress && !isSearchPaymentStatus && !isSearchOrderStatus && !isSearchAmount && !isSearchOperation && (
            <Col xs={12} md={4} lg={3} />
          )}
          <Col xs={12} md={4} lg={4} />
          <Col xs={12} md={4} lg={2} hidden>
            <label htmlFor="color" style={{ fontSize: 'small', color: 'transparent' }} className="form-label">.</label>
            <Button
              variant="primary"
              className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"
              onClick={() => resetData(1)}
            >
              Reset  <i className="fa fa-refresh" />
            </Button>
          </Col>
        </Row>
        <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfig}
        />
      </Panel>
      <div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          show={isModalOpen1}
          onHide={closeAcceptModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Approve Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container mt-0 containerDiv" style={{ overflowX: 'hidden' }}>
              <div>
                {imageSelfie && !capturedImage && (
                  <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                    <span>
                      <button
                        className="btn btn-danger btn-sm py-0 px-1 mb-3 shadow-none text-capitalize"
                        type="button"
                        onClick={() => setImageSelfie(null)}
                      >
                        {" "}
                        Remove
                      </button>
                      <img
                        src={URL.createObjectURL(imageSelfie)}
                        className="uploaded-image createImg mx-2"
                      />
                    </span>
                  </div>
                )}
                {capturedImage && (
                  <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                    <span>
                      <button
                        className="btn btn-danger btn-sm py-0 px-1 mb-3 shadow-none text-capitalize"
                        type="button"
                        onClick={() => setCapturedImage(null)}
                      >
                        {" "}
                        Remove
                      </button>
                      <img
                        key={capturedImage}
                        src={capturedImage}
                        className="uploaded-image createImg mx-2"
                      />
                    </span>
                  </div>
                )}
                <div className="row mt-0 mb-2">
                  <div className="col-12">
                    <label
                      htmlFor="description"
                      style={{ color: '#7b7b7b' }}
                      className="form-label mb-1"
                    > <b>Enter Reason</b>
                    </label>
                    <textarea
                      value={acceptReason}
                      onChange={e => setAcceptReason(e.target.value)}
                      className="form-control"
                      rows={2}
                      cols={50}
                      placeholder="Enter Approve Reason"
                    />
                    <label
                      htmlFor="description"
                      style={{ color: '#7b7b7b' }}
                      className="form-label mb-1 mt-2"
                    > <b>Approve Doc</b>
                    </label>
                    <input
                      type="file"
                      className="form-control mt-0"
                      onChange={handleFileChange1}
                      id="File"
                      name="File"
                      accept=".pdf"
                    />
                    {approveFile
                      && (
                        <>
                          <label
                            htmlFor="description"
                            style={{ color: '#7b7b7b' }}
                            className="form-label mb-1 mt-3"
                          > <b>Signature here</b>
                          </label>
                          <div style={{ border: '2px solid #7b7b7b', borderRadius: '6px' }}>
                            <SignaturePad getPDf1={getPDf1} onEnd={handleSignatureEnd} />
                            {signatureImage1 && <> <PdfSignature pdfFile={approveFile} signatureImage={signatureImage1} getPDf={getPDf} /></>}
                          </div>
                        </>
                      )}
                  </div>

                  <div className="d-flex flex-column flex-sm-row gap-2 ">
                    <Button
                      onClick={() => setShowWebCap(true)}
                      size="md"
                      className="py-2 btn-block align-self-center mt-2 "
                      variant="outline-success"
                      disabled={imageSelfie}
                    >
                      Verify Selfie
                    </Button>

                    <div className="d-flex w-100 justify-content-center  align-items-center ">
                      <input
                        hidden
                        type="file"
                        ref={fileSelfieUpload}
                        // value={values.File}
                        name="File[]"
                        id="File"
                        // multiple
                        onChange={handleSelfieUpload}

                        accept="image/*"
                      />
                      <br />
                      <Button
                        onClick={handleButtonSelfieClick}
                        size="md"
                        className="py-2 btn-block mb-0 w-100 align-self-center  "
                        variant="outline-success"
                        disabled={capturedImage}

                      >
                        Upload Selfie
                      </Button>
                    </div>
                  </div>
                  {getSubmit
                    && (
                      <div className="col-12 my-2">
                        <button
                          type="button"
                          className="btn purple-button btn-block w-100 shadow-none text-capitalize"
                          onClick={handleApprove}
                          disabled={!capturedImageFile && !imageSelfie}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          show={isModalOpen}
          onHide={closeRejectModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Rejection Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container mt-0 containerDiv" style={{ overflowX: 'hidden' }}>
              <div>

                {imageSelfie && !capturedImage && (
                  <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                    <span>
                      <button
                        className="btn btn-danger btn-sm py-0 px-1 mb-3 shadow-none text-capitalize"
                        type="button"
                        onClick={() => setImageSelfie(null)}
                      >
                        {" "}
                        Remove
                      </button>
                      <img
                        src={URL.createObjectURL(imageSelfie)}
                        className="uploaded-image createImg mx-2"
                      />
                    </span>
                  </div>
                )}
                {capturedImage && (
                  <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                    <span>
                      <button
                        className="btn btn-danger btn-sm py-0 px-1 mb-3 shadow-none text-capitalize"
                        type="button"
                        onClick={() => setCapturedImage(null)}
                      >
                        {" "}
                        Remove
                      </button>
                      <img
                        key={capturedImage}
                        src={capturedImage}
                        className="uploaded-image createImg mx-2"
                      />
                    </span>
                  </div>
                )}
                <div className="row mt-0 mb-2">
                  <div className="col-12">
                    <label
                      htmlFor="description"
                      style={{ color: '#7b7b7b' }}
                      className="form-label mb-1"
                    > <b>Enter Reason</b>
                    </label>
                    <textarea
                      value={rejectionReason}
                      onChange={e => setRejectionReason(e.target.value)}
                      className="form-control"
                      rows={2}
                      cols={50}
                      placeholder="Enter Reject Reason"
                    />
                    <label
                      htmlFor="description"
                      style={{ color: '#7b7b7b' }}
                      className="form-label mb-0 mt-2"
                    > <b>Rejection Doc</b>
                    </label>
                    <input
                      type="file"
                      className="form-control mt-1"
                      onChange={handleFileChange}
                      id="File"
                      name="File"
                      accept=".pdf"
                    />
                    {rejectFile
                      && (
                        <>
                          <label
                            htmlFor="description"
                            style={{ color: '#7b7b7b' }}
                            className="form-label mb-1 mt-3"
                          > <b>Signature here</b>
                          </label>
                          <div style={{ border: '2px solid #7b7b7b', borderRadius: '6px' }}>
                            <SignaturePad getPDf1={getPDf1} onEnd={handleSignatureEnd} />
                            {signatureImage1 && <> <PdfSignature pdfFile={rejectFile} signatureImage={signatureImage1} getPDf={getPDf} /></>}
                          </div>
                        </>
                      )}
                  </div>
                  <div className="d-flex flex-column flex-sm-row gap-2 ">
                    <Button
                      onClick={() => setShowWebCap(true)}
                      size="md"
                      className="py-2 btn-block align-self-center mt-2 "
                      variant="outline-success"
                      disabled={imageSelfie}
                    >
                      Verify Selfie
                    </Button>

                    <div className="d-flex w-100 justify-content-center  align-items-center ">
                      <input
                        hidden
                        type="file"
                        ref={fileSelfieUpload}
                        // value={values.File}
                        name="File[]"
                        id="File"
                        // multiple
                        onChange={handleSelfieUpload}

                        accept="image/*"
                      />
                      <br />
                      <Button
                        onClick={handleButtonSelfieClick}
                        size="md"
                        className="py-2 btn-block mb-0 w-100 align-self-center  "
                        variant="outline-success"
                        disabled={capturedImage}

                      >
                        Upload Selfie
                      </Button>
                    </div>
                  </div>
                  {getSubmit
                    && (
                      <div className="col-12 my-2">
                        <button
                          type="button"
                          className="btn purple-button btn-block w-100 shadow-none text-capitalize"
                          onClick={handleRejectOrder}
                          disabled={!capturedImageFile && !imageSelfie}

                        >
                          Submit
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalIsOpen}
          onHide={closeProductDetails}
        >
          <Modal.Header closeButton>
            {selectedOrder && (
              <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Order No: <span style={{ fontWeight: 400 }}> {selectedOrder._id}</span></Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            {selectedOrder && (
              <>
                <div className="container mt-0 containerDiv pt-0" style={{ overflowX: 'hidden' }}>
                  {/* <div className="row">
                <div className="col-12 text-center">
                  <h3 style={{ color: '#7b7b7b' }} className="mb-2"> Order Details</h3>
                </div>
              </div> */}
                  <div className="row mt-2 mb-1">
                    <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                      <b>Order Detail</b>
                    </p>
                  </div>
                  <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                    <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Quantity</b>
                      </p>
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      >{selectedOrder.product_details.length}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Shipping</b>
                      </p>
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      >{selectedOrder.total_delivery_charges} {selectedOrder.symbol}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Grand Total</b>
                      </p>
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      >{selectedOrder.total_amount} {selectedOrder.symbol}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Payment Hash</b>
                      </p>
                      <a
                        // eslint-disable-next-line max-len
                        href={`${'https://bscscan.com/tx'}/${selectedOrder.payment_transaction_hash}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                      >
                        View
                      </a>
                    </div>
                  </div>
                  <div className="row mt-2 mb-1">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="row mt-2 mb-1">
                        <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                          <b>Buyer Detail</b>
                        </p>
                      </div>
                      <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Name <FontAwesomeIcon icon={faUser} /> </b>
                          </p>
                          {selectedOrder.buyer_detail[0] ? (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.buyer_detail[0].name}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Email <FontAwesomeIcon icon={faEnvelope} /></b>
                          </p>
                          {selectedOrder.buyer_detail[0] ? (
                            <p
                              style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.buyer_detail[0].email}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="row mt-2 mb-1">
                        <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                          <b>Store Detail</b>
                        </p>
                      </div>
                      <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Name</b>
                          </p>
                          {selectedOrder.store_detail[0] ? (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.store_detail[0].storeName}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Address</b> <i className="mr-1 fa fa-map-marker" />
                          </p>
                          {selectedOrder.store_detail[0] ? (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.store_detail[0].storeAddress}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedOrder.approver_detail[0] && (
                    <>
                      <div className="row mt-2 mb-1">
                        <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                          <b>Store Approver Detail</b>
                        </p>
                      </div>
                      <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Name</b> <FontAwesomeIcon icon={faUser} />
                          </p>
                          {selectedOrder.approver_detail[0] ? (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.approver_detail[0].name}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Email</b> <FontAwesomeIcon icon={faEnvelope} />
                          </p>
                          {selectedOrder.approver_detail[0] ? (
                            <p
                              style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.approver_detail[0].email}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        {selectedOrder.approve_documents && (
                          <>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Approve Document</b> <FontAwesomeIcon icon={faFile} />
                              </p>
                              <a
                                href={selectedOrder.approve_documents}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                              >
                                View
                              </a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <span>
                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                ><b>Approve Reason</b>
                                </p>

                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                >{selectedOrder?.approve_reason}
                                </p>
                              </span>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <span>
                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                ><b>image</b>
                                </p>

                                <a
                                  href={selectedOrder.approver_image}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                                >
                                  View Image
                                </a>
                              </span>
                            </div>
                          </>
                        )}
                        {selectedOrder?.rejection_documents && (
                          <>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Rejection Document</b> <FontAwesomeIcon icon={faFile} />
                              </p>
                              <a
                                href={selectedOrder?.rejection_documents}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                              >
                                View
                              </a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <span>
                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                ><b>Rejection Reason</b>
                                </p>

                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                >{selectedOrder?.rejection_reason}
                                </p>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {selectedOrder.buyer_approver_detail[0] && (
                    <>
                      <div className="row mt-4 mb-1">
                        <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                          <b>Buyer Approver Detail</b>
                        </p>
                      </div>
                      <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Name</b> <FontAwesomeIcon icon={faUser} />
                          </p>
                          {selectedOrder.buyer_approver_detail[0] ? (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.buyer_approver_detail[0].name}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Email</b> <FontAwesomeIcon icon={faEnvelope} />
                          </p>
                          {selectedOrder.buyer_approver_detail[0] ? (
                            <p
                              style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.buyer_approver_detail[0].email}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        {selectedOrder.buyer_approve_documents && (
                          <>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Approve Document</b> <FontAwesomeIcon icon={faFile} />
                              </p>
                              <a
                                href={selectedOrder.buyer_approve_documents}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                              >
                                View
                              </a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <span>
                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                ><b>Approve Reason</b>
                                </p>

                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                >{selectedOrder.buyer_approve_reason}
                                </p>
                              </span>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <span>
                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                ><b>image</b>
                                </p>

                                <a
                                  href={selectedOrder.buyer_approver_image}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                                >
                                  View Image
                                </a>
                              </span>
                            </div>
                          </>
                        )}
                        {selectedOrder.buyer_rejection_documents && (
                          <>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Rejection Document</b> <FontAwesomeIcon icon={faFile} />
                              </p>
                              <a
                                href={selectedOrder.buyer_rejection_documents}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                              >
                                View
                              </a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <span>
                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                ><b>Rejection Reason</b>
                                </p>

                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                >{selectedOrder?.buyer_rejection_reason}
                                </p>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {selectedOrder.seller_wallet.length !== 0 && (
                    <>
                      <div className="row mt-3 mb-1">
                        <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                          <b>Seller Detail</b>
                        </p>
                      </div>
                      <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Name</b> <FontAwesomeIcon icon={faUser} />
                          </p>
                          {selectedOrder.seller_wallet[0] ? (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.seller_wallet[0].name}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Seller ID</b> <FontAwesomeIcon icon={faEnvelope} />
                          </p>
                          {selectedOrder.seller_wallet[0] ? (
                            <p
                              style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                              className="py-1"
                            >{selectedOrder.seller_wallet[0]._id}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Seller Amount</b>
                          </p>
                          {selectedOrder.seller_wallet[0] ? (
                            <p
                              style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                              className="py-1"
                            >
                              {`${selectedOrder?.seller_total_amount || 0} USDT`}

                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Agency Amount</b>
                          </p>
                          {selectedOrder.seller_wallet[0] ? (
                            <p
                              style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                              className="py-1"
                            >
                              {`${selectedOrder?.agency_total_amount || 0} USDT`}
                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Agency % </b>
                          </p>
                          {selectedOrder.seller_wallet[0] ? (
                            <p
                              style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                              className="py-1"
                            >
                              {`${selectedOrder?.agency_percentage || 0}`}

                            </p>
                          ) : (
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            >-
                            </p>
                          )}
                        </div>




                        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1 mt-1"
                          ><b>Payment Hash</b>
                          </p>
                          <a
                            // eslint-disable-next-line max-len
                            href={`${'https://bscscan.com/tx'}/${orders[0].payment_transaction_hash_agency}`}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                          >
                            View
                          </a>
                        </div>
                        {selectedOrder.buyer_approve_documents && (
                          <>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Approve Document</b> <FontAwesomeIcon icon={faFile} />
                              </p>
                              <a
                                href={selectedOrder.buyer_approve_documents}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                              >
                                View
                              </a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <span>
                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                ><b>Approve Reason</b>
                                </p>

                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                >{bapproverReason}
                                </p>
                              </span>
                            </div>
                          </>
                        )}
                        {brejectDoc && (
                          <>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Rejection Document</b> <FontAwesomeIcon icon={faFile} />
                              </p>
                              <a
                                href={brejectDoc}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                              >
                                View
                              </a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                              <span>
                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                ><b>Rejection Reason</b>
                                </p>

                                <p
                                  style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                  className="py-1"
                                >{brejectReason}
                                </p>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  <hr hidden style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
                  <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0 mt-4">
                    <b>Invoice</b>
                  </p>
                  <div>
                    <div className="row mt-2 mb-2">
                      <div className="col-12">
                        <div style={{ overflowY: 'auto' }}>
                          <table className="table table-sm" style={{ textTransform: 'Capitalize' }}>
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Color</th>
                                <th scope="col">Discount</th>
                                <th scope="col">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedOrder.product_details.map(product => (
                                <tr key={product._id}>
                                  <td>{product.title ? product.title : '-'}</td>
                                  <td>{product.quantity}</td>
                                  <td>{product.color}</td>
                                  <td>{product.discount} %</td>
                                  <td>{product.price} {selectedOrder.symbol}</td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}>-</td>
                                <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}><b style={{ fontWeight: '600' }}> {gettotalQtyTable} </b> </td>
                                <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}>-</td>
                                <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}>-</td>
                                <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}> <b style={{ fontWeight: '600' }}> {gettotalChargesTable} {cartSybmol}</b></td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" hidden>
                  Submit Ratings
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Track Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedOrder && (
              <div className="container padding-bottom-3x mb-1 p-0">
                <div className="card mb-3 mt-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
                  <div className="row steps flex-wrap flex-sm-nowrap justify-content-between py-2 mb-3 px-2 bg-secondary">
                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
                      <span>
                        <div className="step completed mb-0">
                          <div className="step-icon-wrap bg-transparent" style={{ border: 'none', height: '60px' }}>
                            <div className="step-icon bg-transparent" style={{ color: 'black', border: 'none' }}><i className="pe-7s-box2" /></div>
                          </div>
                        </div>
                      </span>
                      <span>
                        <div className="py-1 px-0">
                          {selectedOrderQty > 1 ? (
                            <span className="mb-2"> {selectedOrderQty} Items </span>
                          ) : (
                            <span className="mb-2"> {selectedOrderQty} Item </span>
                          )}
                          <br />
                          {/* <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>Deliver on April 27, 2023 </p>  */}
                          <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>{selectedOrder.delivery_address} <i className="mr-1 fa fa-map-marker" /></p>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                      <div className="step completed mb-2">
                        <div className="step-icon-wrap">
                          {selectedOrder.order_status === 'pending' || selectedOrder.order_status === 'Pending' ? (
                            <div className="step-icon" style={{ borderColor: '#007bff', backgroundColor: '#007bff', color: '#fff' }}><i className="pe-7s-config" /></div>
                          ) : (
                            <div className="step-icon"><i className="pe-7s-config" /></div>
                          )}
                        </div>
                        <h4 className="step-title mt-0"> Pending</h4>
                      </div>
                      <div className="step completed mb-2">
                        <div className="step-icon-wrap">
                          {selectedOrder.order_status === 'waiting' || selectedOrder.order_status === 'Waiting' ? (
                            <div className="step-icon" style={{ borderColor: '#ffc107', backgroundColor: '#ffc107', color: '#fff' }}><i className="pe-7s-hourglass" /></div>
                          ) : (
                            <div className="step-icon"><i className="pe-7s-hourglass" /></div>
                          )}
                        </div>
                        <h4 className="step-title mt-0">Waiting</h4>
                      </div>
                      <div className="step completed mb-2">
                        <div className="step-icon-wrap">
                          {selectedOrder.order_status === 'shipped' || selectedOrder.order_status === 'Shipped' ? (
                            <div className="step-icon" style={{ borderColor: '#6c757d', backgroundColor: '#6c757d', color: '#fff' }}><i className="pe-7s-car" /></div>
                          ) : (
                            <div className="step-icon"><i className="pe-7s-car" /></div>
                          )}
                        </div>
                        <h4 className="step-title mt-0">Shipped</h4>
                      </div>
                      <div className="step completed mb-2">
                        <div className="step-icon-wrap">
                          {selectedOrder.order_status === 'delivered' || selectedOrder.order_status === 'Delivered' ? (
                            <div className="step-icon" style={{ borderColor: '#17a2b8', backgroundColor: '#17a2b8', color: '#fff' }}><i className="pe-7s-home" /></div>
                          ) : (
                            <div className="step-icon"><i className="pe-7s-home" /></div>
                          )}
                        </div>
                        <h4 className="step-title mt-0">Delivered</h4>
                      </div>
                      <div className="step completed mb-2">
                        <div className="step-icon-wrap">
                          {selectedOrder.order_status === 'completed' || selectedOrder.order_status === 'Completed' ? (
                            <div className="step-icon" style={{ borderColor: '#28a745', backgroundColor: '#28a745', color: '#fff' }}><i className="pe-7s-check" /></div>
                          ) : (
                            <div className="step-icon"><i className="pe-7s-check" /></div>
                          )}
                        </div>
                        <h4 className="step-title mt-0">Completed</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <div className="bg-transparent chat-box1 open">
          <Button
            variant="primary"
            className="btn btn-info mt-1 py-3 px-3 shadow-none rounded-circle button-hover"
            onClick={handleClick}
          >
            <i style={{ fontSize: 'x-large', color: 'white' }} className="fa fa-comments" />
          </Button>
        </div>
        {showWebCam && (
          <WebCam
            show={showWebCam}
            hide={() => setShowWebCap(false)}
            onCapture={handleCaptureImage}
          />
        )}
      </div>
    </>
  );
};

export default GetMyApproval;

const HashLink = styled.button`
  border: none; 
  background: transparent; 
  color: #4ce1b6;
  display: flex;  
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;  
  }
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
