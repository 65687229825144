import React, { useState } from "react";
import { Badge, Col } from "react-bootstrap";
import styled from "styled-components";
import MessageTextOutlineIcon from "mdi-react/MessageTextOutlineIcon";
import { Card } from "@/shared/components/Card";
import { Button } from "@/shared/components/Button";
import { colorAdditional, colorBlue, colorBorder } from "@/utils/palette";
import { paddingLeft, left } from "@/utils/directions";
import { ProfileCard } from "../../../containers/Account/Profile/ProfileBasicComponents";
import UpdateGeneralPackageCard from "./UpdateGeneralPackageCard";
import DeleteGeneralPackageCard from "./DeleteGeneralPackageCard";


const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const GeneralPackageCard = ({ data, reFetchPackages, otcId }) => {
  console.log("🚀 ~ GeneralPackageCard ~ data:", data)
  let priceSymbol, secondSymbol;
  if (data?.pool?.length > 0 && data.pool[0]?.pool) {
      [priceSymbol, secondSymbol] = data.pool[0].pool.split("-");
  } else if (data?.new_pool) {
      [priceSymbol, secondSymbol] = data.new_pool.split("-");
  }  const [show, setShow] = useState(false);
  const [updatePackage, setUpdatePackage] = useState(false);
  const [deletePackage, setDeletePackage] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const chainIds = [
    { value: 1, label: "ETHEREUM" },
    { value: 56, label: "BSC" },
    { value: 137, label: "MATIC" },
  ];
  const getChainName = (chainId) => {
    const chain = chainIds.find((chain) => chain.value === chainId);
    return chain ? chain.label : "Unknown";
  };
  return (
    <Card>
      <ProfileCard>
        <ProfileInformation>
          <ProfileData>
            <ProfileName>{data?.package_name}</ProfileName>
            <ProfileWork>{data?.package_description}</ProfileWork>
            <ProfileContact>
              <div className="d-flex text-uppercase justify-content-between align-items-center ">
                <h5>Package Price:</h5>
                <h5>{`${data.price.toFixed(3)} ${priceSymbol == "SOLANA" ? "SOL" : priceSymbol}`}</h5>
              </div>
            </ProfileContact>
           {data.unlock_days && <ProfileContact>
              <div className="d-flex text-uppercase justify-content-between align-items-center ">
                <h5>Unlock Days:</h5>
                <h5>{`${data.unlock_days}`}</h5>
              </div>
            </ProfileContact>}
            <ProfileContact dir="ltr">
              <div className="d-flex text-uppercase justify-content-between align-items-center ">
                <h5>Sell Token:</h5>
                <h5>{`${data.token} ${secondSymbol}`}</h5>
              </div>
            </ProfileContact>
            <ProfileContact dir="ltr">
              <div className="d-flex text-uppercase justify-content-between align-items-center ">
                <h5 className="flex text-nowrap "> package status:</h5>
                <h5
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "400",
                  }}
                  className="currentPrice"
                >
                  <Badge
                    bg={
                      data.package_status === "pending"
                        ? "warning"
                        : data.package_status === "active"
                        ? "success"
                        : data.package_status === "inactive"
                        ? "danger"
                        : "primary"
                    }
                  >
                    {data.package_status}{" "}
                  </Badge>
                </h5>
              </div>
            </ProfileContact>
          </ProfileData>
        </ProfileInformation>
        <div className="d-flex flex-column flex-md-row px-2">
          <FormButton
            type="submit"
            variant="contain-success"
            className="py-2 mt-2 w-100"
            onClick={() => {
              setUpdatePackage(true);
              setShow(false);
            }}
          >
            Edit Package
          </FormButton>

          <FormButton
            type="submit"
            variant="outline-success"
            className="py-2 w-100"
            onClick={() => {
              setDeletePackage(true);
              setShow(false);
            }}
          >
            Delete Package
          </FormButton>
        </div>
      </ProfileCard>
      {updatePackage && (
        <UpdateGeneralPackageCard
          show={updatePackage}
          hide={() => setUpdatePackage(false)}
          items={data}
          reFetchPackages={reFetchPackages}
          otcId={otcId}
        />
      )}
      {deletePackage && (
        <DeleteGeneralPackageCard
          show={deletePackage}
          hide={() => setDeletePackage(false)}
          reFetchPackages={reFetchPackages}
          package_id={data._id}
        />
      )}
    </Card>
  );
};

export default GeneralPackageCard;

// region STYLES

const ProfileInformation = styled.div`
  padding: 30px 0px;
  display: flex;
  //   text-align: ${left};
  border-bottom: 1px solid ${colorBorder};
  display: flex; /* Add display flex */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */

  @media (max-width: 1345px) and (min-width: 1200px) {
    padding: 30px px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const ProfileAvatar = styled.div`
  height: 140px;
  width: 140px;
  overflow: hidden;
  border-radius: 50%;
  display: flex; /* Add display flex */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */

  img {
    height: 100%;
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }
`;

const ProfileData = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 360px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
`;

const ProfileName = styled.p`
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
  align-items: center;
  display: flex;

  flex-direction: row;
  justify-content: center;
`;

const ProfileWork = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfileContact = styled.p`
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
`;

const ProfileButton = styled(Button)`
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px 15px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfileStats = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  padding: 0px 10px;
`;

const ProfileStat = styled.div`
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
`;

const ProfileStatNumber = styled.p`
  color: ${colorBlue};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
`;

const ProfileStatTitle = styled.p`
  margin: 0;
  color: ${colorAdditional};
  font-size: 12px;
  line-height: 14px;
`;

// endregion
