import { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "@/shared/components/Button";
import {
  transferBalance,
  transferBalance_sol,
  verifyTFAEmail,
} from "../../../utils/services/user.service";
import { toast } from "react-toastify";
import { Ring } from "@uiball/loaders";

const VirtualBalanceModal = ({marketplaceData, show, hide, reFetch, modalId }) => {
  const [selectedOption, setSelectedOption] = useState("internal_wallet");
  const [walletAddress, setWalletAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const symbol = modalId.pool.split("-")[1].trim();
  const [isTfaVarified, setIsTfaVarified] = useState(false);
  const userSessionData = JSON.parse(localStorage.getItem("user"));
  const [tfaCode, setTfaCode] = useState("");


  const handleTfaCode = async () => {
    const requestData = {
      token: tfaCode,
      email: userSessionData.data.user.email,
    };


    try {
      setIsLoading(true);
      const res = await verifyTFAEmail(requestData);

      if (res) {
        setIsLoading(false);
        if (res.data.verified === false) {
          setTfaCode("");
          toast.error("Incorrect TFA Code", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (res.data.verified === true) {
          setIsTfaVarified(true);
          toast.success("Verify TFA Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsLoading(false);
          setTfaCode("");
          toast.error("Verify TFA failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setIsLoading(false);
        console.error("verifyTFA failed:", res);
      }
    } catch (error) {
      console.log("🚀 ~ handleTfaCode ~ error:", error);

      setIsLoading(false);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleTransferClick = async () => {
    setIsLoading(true);
    try {
      if (selectedOption == "external_wallet" && !walletAddress) {
        toast.error("Please Add Wallet Address");
        return;
      }
      const payload = {
        buy_package_id: modalId._id,
        wallet_address: walletAddress,
      };

      if (
        symbol === "SoLpy" ||
        symbol === "WEN" ||
        symbol === "LTX" ||
        symbol === "SOLANA"
      ) {
        const response = await transferBalance_sol(payload);
        toast.success(response?.data?.Message);

      } else {
        const response = await transferBalance(payload);
        toast.success(response?.data?.Message);

      }
      setIsLoading(false);
      reFetch();
      hide();
    } catch (error) {
      console.error("Transfer failed:", error);
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row flex-column gap-2 container ">
            <h5 className="text-black">Select where you want to transfer</h5>
            <select
              className="text-start container "
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              style={{
                textTransform: "capitalize",
                padding: "4px",
                borderRadius: "4px",
              }}
            >
              {/* <option disabled value="" style={{ textTransform: "capitalize" }}>
                {order.order_status}
              </option> */}
              <option value="internal_wallet">Virtual Wallet</option>
              {marketplaceData?.otc_type != "general"  && <option value="external_wallet">External Wallet </option>}
            </select>

            {selectedOption === "external_wallet" && (
              <div className="col-lg-6 col-md-12 col-sm-12 mt-1">
                <InputDiv>
                  <label
                    htmlFor="email"
                    className="text-black mt-2"
                    style={{ fontSize: "small" }}
                  >
                    Enter Wallet Address *
                  </label>
                  <div>
                    <ChatSearchInput
                      name="otp"
                      type="text"
                      placeholder=" Enter Wallet Address"
                      value={walletAddress}
                      onChange={(e) => setWalletAddress(e.target.value)}
                      className={`form-control w-100 text-black `}
                    />
                  </div>
                </InputDiv>
              </div>
            )}


              {userSessionData.data.user.TFA && (
                <div className=" d-flex col-lg-12 col-md-12 col-sm-12 mt-1 gap-2  justify-content-between ">
                  <InputDiv>
                    <label htmlFor="email" className="text-black mb-3">
                      Verify TFA *
                    </label>
                    <input
                      name="tfa"
                      type="text"
                      placeholder="Enter Code"
                      className="text-black"
                      value={tfaCode}
                      onChange={(e) => {
                        setTfaCode(e.target.value);
                      }}
                    />
                  </InputDiv>

                  <InputDiv>
                    <FormButton
                      variant="contain-success"
                      className="py-2 mt-4 w-75 btn-sm"
                      onClick={handleTfaCode}
                      disabled={userSessionData.data.user.TFA && isTfaVarified}
                    >
                      {userSessionData.data.user.TFA && isTfaVarified ? "Verified" : "Verify"}
                    </FormButton>
                  </InputDiv>
                </div>
              )}

            <div>
              <FormButton
                type="submit"
                variant="contain-success"
                className="col-lg-4 col-md-12 col-sm-12 mt-1"
                onClick={handleTransferClick}
                disabled={userSessionData.data.user.TFA && !isTfaVarified}
              >
                Transfer
              </FormButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default VirtualBalanceModal;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color: white;
    border-bottom: 1px solid #555555;
  }
  input.disabled {
    cursor: not-allowed !important;
    color: #d9d9d9;
  }
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
