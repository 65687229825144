/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../style/home.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import {
  Col, Container, Row, Nav, NavDropdown, Navbar, Table,
} from 'react-bootstrap';
import { Ring } from '@uiball/loaders';
import StarRating from './StarRating';
import {
  getAllStore,
  searchStoretByNameOrCategory,
  getCategory,
} from '../../../utils/services/user.service';
import '../style/Rating.css';
import '../style/homecard.css';


export default function Home() {
  const [storeData, setStoreData] = useState([]);
  console.log("🚀 ~ Home ~ storeData:", storeData)
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filteredStroe, setFilteredStore] = useState([]);
  const user = JSON.parse(localStorage?.getItem('user'))

  const totalPages = Math.ceil(storeData.length / 10);
  // console.log('searchTerm', searchTerm);
  // console.log('storeData>>>>>', storeData);
  const fetchData = async () => {
    try {
      const requestData = {
        limit: 10,
        pageNumber: currentPage,
      };
      const response = await getAllStore(requestData);

      if (!response.data.result) {
        throw new Error('No data received');
      }

      // console.log('API Response Data:', response.data.result);

      setStoreData(response.data.result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(); // Load initial data
  }, [currentPage]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleArrowNavigation = (direction) => {
    if (direction === 'prev') {
      handlePageChange(Math.max(currentPage - 1, 1));
    } else if (direction === 'next') {
      handlePageChange(Math.min(currentPage + 1, totalPages));
    }
  };

  const [isHovered1, setIsHovered1] = useState(false);

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  useEffect(() => {
    // Fetch category data from your API
    const fetchCategories = async () => {
      try {
        const response = await getCategory();

        if (response.data.status) {
          setCategories(response.data.category);
        } else {
          console.error('No category data received');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = async () => {
    try {
      const requestData = {
        store_name: searchTerm, // Send the search query to the API
        sub_category_id: '',
        pageNumber: 1,
        limit: 10,
      };
      setIsLoading(true);
      const response = await searchStoretByNameOrCategory(requestData);
      // console.log('response', response);
      // console.log('response.data.result', response.data.result);

      if (response.data.result) {
        setStoreData(response.data.result);
        setIsLoading(false);
      } else if (searchTerm.trim() === '') {
        fetchData(1); // Call fetchAllData when searchTerm is empty
      } else {
        setIsLoading(false);
        setStoreData([]); // No matching products found
      }
    } catch (error) {
      fetchData(1);
      setIsLoading(false);
      console.error('Error fetching search results:', error);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubcategoryClick = async (subcategoryID) => {
    // console.log('handleCategoryClickhandleCategoryClickhandleCategoryClickhandleCategoryClick');
    try {
      const requestData = {
        store_name: '',
        category_id: subcategoryID.id, // Send the selected category ID to the API
        pageNumber: 1,
        limit: 10,
      };
      setIsLoading(true);
      const response = await searchStoretByNameOrCategory(requestData);
      // console.log('response data result >>>>>> 112233', response.data.result);
      // console.error('Error fetching stores >>>>>>> ', response.data.Message);
      if (response.data.result) {
        // console.log('11111');
        setStoreData(response.data.result);
        // setStoreData(subcategoryID); // Store the selected category ID
        setIsLoading(false);
        // console.log('matchingProductsFoundCategory>>>>>>>>>', setStoreData);
      } else {
        // console.log('22222');
        setStoreData([]); // No matching products found
      }
    } catch (error) {
      setIsLoading(false);
      setStoreData([]);
      console.error('Error fetching stores >>>>>>> ', error);
      console.error('Error fetching stores >>>>>>> ', error.response);
      // console.error('Error fetching stores >>>>>>> ', error.response.data.Message);
      if (error.response.data.Message === 'Product not found') {
        // console.log('33333');
        setStoreData([]);
        // console.log(error.response.data.Message);
        // toast.error(`${error.response.data.Message}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 5000, // Adjust as needed
        // });
        console.error('Error setMatchingProductsFoundCategory stores >>>>>>> 11 ', setStoreData);
      }
    }
  };

    // filter user data to avoid user own store list 
    useEffect(()=>{
      const filteredData = storeData.filter(
        (item) => item?.storeOwnerId !== user?.data?.user?.user_id
      );
  
      setFilteredStore(filteredData);
    },[storeData])

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="container ">
        <div className="row px-3">
          <div className="col-lg-2 text-center mt-1">
            <Navbar className="text-center" expand="sm">
              <Navbar.Collapse className="show w-100" id="my-nav">
                <Nav className="text-center w-100" style={{ borderRadius: '5px', border: '2px solid #b1b1b1', padding: '0px' }}>
                  <NavDropdown className="text-center w-100" title={<span style={{ color: '#b1b1b1', fontSize: 'large' }}>Categories&nbsp;</span>} id="my-nav">
                    <NavDropdown.Item onClick={() => fetchData()}> All </NavDropdown.Item>
                    <NavDropdown.Divider style={{ margin: '0px 0px' }} />
                    {categories.map((category, index) => (
                      <>
                        <NavDropdown.Item
                          onMouseEnter={handleMouseEnter1}
                          onMouseLeave={handleMouseLeave1}
                          className={isHovered1 ? 'hovered-dropdown1' : ''}
                          style={{ textTransform: 'capitalize' }}
                          onClick={() => handleSubcategoryClick({
                            id: category._id,
                            name: category.category_name,
                          })}
                        > {category.category_name}
                        </NavDropdown.Item>
                        {index < categories.length - 1 && <NavDropdown.Divider style={{ margin: '2px 0px' }} />}
                      </>
                    ))}
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          <div className="col-lg-3 text-center" hidden>
            <ul className="navbar-nav ms-auto">

              <li className="nav-item dropdown">
                <button
                  className="nav-link btn btn-link dropdown-toggle catbtn text-dark"
                  type="button"
                  id="navbarDropdownMenuLinkRight"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                  style={{ background: 'white' }}
                >
                  Cateogories
                </button>
                <ul
                  className="dropdown-menu w-100"
                  aria-labelledby="navbarDropdownMenuLinkRight"
                >
                  <button type="button" className="allbtn" onClick={() => fetchData()}>All</button>
                  {categories.map(category => (
                    <li key={category._id}>
                      <button
                        className="dropdown-item btn btn-link"
                        type="button"
                        onClick={() => handleSubcategoryClick({
                          id: category._id,
                          name: category.category_name,
                        })}
                      >
                        {category.category_name}
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-lg-10">
            <InputDiv>
              <ChatSearchInput
                className="mt-2 form-control"
                type="text"
                placeholder="&#128269; Search Stores..."
                value={searchTerm}
                onChange={(e) => {
                  handleInputChange(e);
                  handleSearch(); // Call the handleSearch function here
                }}
              />
            </InputDiv>
            <input
              hidden
              className="w-100 form-control"
              type="text"
              placeholder="Search Stores..."
              value={searchTerm}
              onChange={(e) => {
                handleInputChange(e);
                handleSearch(); // Call the handleSearch function here
              }}
            />
          </div>
        </div>
        {/* <div className="pmenu">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <ul className="navbar-nav ms-auto">
             
                <li className="nav-item dropdown">
                  <button
                    className="nav-link btn btn-link dropdown-toggle catbtn mx-3 px-4"
                    type="button"
                    id="navbarDropdownMenuLinkRight"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cateogories
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLinkRight"
                  >
                    <button type="button" className="allbtn" onClick={() => fetchData()}>All</button>
                    {categories.map(category => (
                      <li key={category._id}>
                        <button
                          className="dropdown-item btn btn-link"
                          type="button"
                          onClick={() => handleSubcategoryClick({
                            id: category._id,
                            name: category.category_name,
                          })}
                        >
                          {category.category_name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
          <h1 className="text-center">Stores</h1>
          <div className="search-bar py-2 px-3 mx-3 w-25">
            <input
              className="w-100 form-control"
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => {
              handleInputChange(e);
              handleSearch(); // Call the handleSearch function here
            }}
            />
          </div>
        </div> */}
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-12 col-md-12 col-sm-12 text-center py-3">
              <h3>Stores</h3>
            </div>
            {filteredStroe.length > 0 ? (
              filteredStroe.map(store => (
                <div
                  className="col-lg-3 col-md-6 col-sm-12"
                  style={{ textTransform: 'capitalize' }}
                  key={store._id}
                >
                  <Link to={`/storedetailnew/${store?._id}/${store?.storeName}`}>
                    <div className="card productCard" style={{ background: '#232329' }}>
                      <div className="square-img-wrapper">
                        <img
                          src={store.storeLogo}
                          className="card-img-top square-img"
                          alt={store.storeName}
                        />
                      </div>
                      <div className="card-body">
                        <span className="d-flex">
                          <span style={{ width: '100%' }}>
                            <h4 className="card-title producttitle mt-0 mb-1">{store.storeName}</h4>
                          </span>
                        </span>

                        <div className="row mb-3">
                          <div className="col-6">
                            <span
                              style={{ textTransform: 'Capitalize', float: 'left' }}
                              className={`badge px-2 py-1 mt-2 ${
                                // eslint-disable-next-line max-len, no-nested-ternary
                                store.status === 'rejected' || store.status === 'deactivated' ? 'badge-danger' : store.status === 'pending' ? 'badge-warning' : 'badge-success'
                                }`}
                            > <h6 className="currentPrice"> {store.status}</h6>
                            </span>
                          </div>
                          <div className="col-6">
                            <div className="Rating mb-3" style={{ float: 'right', marginTop: '5%' }}>
                              {store.ProductRatting?.length > 0 ? (
                                <StarRating rating={store.Store_avg_ratting} />
                              ) : (
                                <StarRating rating={0} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="col-lg-12 text-center">
                <p className="text-center">No Stores Available.</p>
              </div>
            )}
          </div>
        </div>
        {storeData.length > 0 && (
          <div className="pagination-container">
            <div className="pagination mb-4">
              <button
                style={{ textDecoration: 'none' }}
                className="btn btn-link"
                type="button"
                disabled={currentPage === 1}
                onClick={() => handleArrowNavigation('prev')}
              >
                &lt; Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                <button
                  key={pageNumber}
                  type="button"
                  className={`btn ${currentPage === pageNumber ? 'btn-primary' : 'btn-secondary'}`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <button
                style={{ textDecoration: 'none' }}
                className="btn btn-link"
                type="button"
                disabled={currentPage === totalPages}
                onClick={() => handleArrowNavigation('next')}
              >
                Next &gt;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}


const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 20px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 2px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
`;
