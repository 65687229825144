import ReactTableBase from "@/shared/components/table/ReactTableBase";
import { getOtcPackageTrx } from "../../../utils/services/user.service";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { faEye } from "@fortawesome/free-regular-svg-icons";
import AllTransectionDetailModal from "./AllTransectionDetailsModal";
import moment from "moment";
import { useSelector } from "react-redux";
import { Pie, Tooltip } from "recharts";
import getTooltipStyles from "@/shared/helpers";
import { colorDarkText } from "@/utils/palette";

import {
  DashboardPieChartContainer,
  DashboardPieChartFlex,
  DashboardChartLegendTable,
  Bullet,
} from "../../../containers/Dashboards/BasicDashboardComponents";
import { optionColors } from "../../../utils/constants/contants";
import SwapModal from "./SwapModal";
import numeral from "numeral";

const AllTransectionTable = ({ marketplaceID, marketplaceData }) => {
  const [transectionData, setTransectionData] = useState([]);

  const [show, setShow] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const themeName = useSelector((state) => state.theme.className);

  const data2 = [{ value: 1 }];

  const tooltipFormatter = (value, name, entry) => {
    return [
      <span key="custom-label">{`${entry?.payload?.symbol} : ${entry?.payload?.graphVal} `}</span>,
    ];
  };
  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({ x: e.tooltipPosition.x, y: e.tooltipPosition.y });
    }
  };
  let colorIndex = 0; // Track the current index of the color array
  const newArray = transectionData
    .filter((item) => item.transfer_status !== "success") // Filter out items with transfer_status === 'success'
    .map((item) => {
      const symbol = item.pool.split("-")[0].trim();
      const fill = optionColors[colorIndex].value;
      const newVal = Number(item?.receive_amount);
      const priceValue = newVal.toFixed(2) || 0;
      const graphVal = parseFloat(priceValue);
      const userName = item.user_details[0].name;

      colorIndex = (colorIndex + 1) % optionColors.length;

      return {
        fill,
        symbol,
        graphVal,
        userName,
      };
    });
  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    isPagination: true,
  };
  const fetchTransection = async () => {
    const payload = {
      limit: 1000,
      page_number: 1,
      marketplace_id: marketplaceID,
    };
    const res = await getOtcPackageTrx(payload);
    setTransectionData(res?.data?.data);
  };
  useEffect(() => {
    fetchTransection();
  }, [marketplaceID]);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case "completed":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      case "success":
        return "green";
      default:
        return "#70bbfd";
    }
  };
  const chainIds = [
    { value: 1, label: "ETHEREUM" },
    { value: 56, label: "BSC" },
    { value: 137, label: "MATIC" },
  ];
  const getChainName = (chainId) => {
    const chain = chainIds.find((chain) => chain.value === chainId);
    return chain ? chain.label : "Unknown";
  };
  const header = [
    // { accessor: "transection_Id", Header: "Trx Id" },
    { accessor: "date", Header: "Buy Date" },
    { accessor: "endDate", Header: "End Date" },
    { accessor: "user_name", Header: "Name" },
    { accessor: "user_email", Header: "Email" },
    { accessor: "buy_token", Header: "Buy Token" },
    { accessor: "recieve_symbol", Header: "Receive Token" },
    { accessor: "buy_amount", Header: "Buy Amount" },
    { accessor: "receive_amount", Header: "Receive Amount" },
    { accessor: "status", Header: "Status" },
    { accessor: "transfer_status", Header: "Transfer Status" },
    { accessor: "view_detail", Header: "View Detail" },
    { accessor: "user_referral_code", Header: "Referral Code" },
  ];
  const header2 = [
    // { accessor: "transection_Id", Header: "Trx Id" },
    { accessor: "date", Header: "Buy Date" },
    { accessor: "endDate", Header: "End Date" },
    { accessor: "user_name", Header: "Name" },
    { accessor: "user_email", Header: "Email" },
    { accessor: "buy_token", Header: "Buy Token" },
    { accessor: "recieve_symbol", Header: "Receive Token" },
    { accessor: "buy_amount", Header: "Buy Amount" },
    { accessor: "receive_amount", Header: "Receive Amount" },
    { accessor: "status", Header: "Status" },
    { accessor: "transfer_status", Header: "Transfer Status" },
    { accessor: "view_detail", Header: "View Detail" },
  ];

  const handleDetailModal = (items) => {
    setShow(true);
    setDetailData(items);
  };
  const data = [];
  transectionData.map((item) =>
    data.push({
      transection_Id: item._id,
      user_name: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.user_details[0]?.name
            ? item?.user_details[0]?.name.charAt(0) + "***"
            : "-"}
        </h6>
      ),
      user_email: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.user_details[0]?.email
            ? item.user_details[0].email.charAt(0) + "***"
            : "-"}
        </h6>
      ),
      buy_token: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.pool.split("-")[0]}
        </h6>
      ),
      date: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item?.createdAt).format("L")}
        </h6>
      ),
      endDate: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item?.unlock_time).format("L")}
        </h6>
      ),
      recieve_symbol: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.pool.split("-")[1]}
        </h6>
      ),

      buy_tax_hash: (
        <HashLink
          role="link"
          onClick={() => {
            let baseUrl;
            switch (item?.chain_id) {
              case 1:
                baseUrl = "https://etherscan.io/tx/";
                break;
              case 137:
                baseUrl = "https://polygonscan.com/tx/";
                break;
              case 56:
              default:
                baseUrl = "https://bscscan.com/tx/";
                break;
            }
            openInNewTab(`${baseUrl}${detailData?.buy_tax_hash}`);
          }}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </HashLink>
      ),
      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.status) }}
        >
          <h6 className="text-white">
            <b>{item.status}</b>
          </h6>
        </span>
      ),
      transfer_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.transfer_status) }}
        >
          <h6 className="text-white">
            <b>{item.transfer_status}</b>
          </h6>
        </span>
      ),
      contract_hash: (
        <HashLink
          role="link"
          onClick={() => {
            let baseUrl;
            switch (detailData?.chain_id) {
              case 1:
                baseUrl = "https://etherscan.io/tx/";
                break;
              case 137:
                baseUrl = "https://polygonscan.com/tx/";
                break;
              case 56:
              default:
                baseUrl = "https://bscscan.com/tx/";
                break;
            }
            openInNewTab(`${baseUrl}${detailData?.contract_hash}`);
          }}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </HashLink>
      ),
      receive_amount: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {numeral(item?.receive_amount || 0).format("0,0.0000")}
        </h6>
      ),
      user_referral_code: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.user_referral_code[0]?.referral_code || " --"}
        </h6>
      ),
      buy_amount: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {numeral(item?.buy_amount || 0).format("0,0.0000")}
        </h6>
      ),
      view_detail: (
        <HashLink onClick={() => handleDetailModal(item)}>
          <FontAwesomeIcon icon={faEye} />
        </HashLink>
      ),
    })
  );
  return (
    <>
      {/* <div className="d-flex flex-column flex-md-row py-5 ">
        <div className="w-100 w-md-50">
          <DashboardPieChartContainer>
            <DashboardPieChartFlex>
              <Tooltip
                position={coordinates}
                {...getTooltipStyles(themeName)}
                formatter={tooltipFormatter}
              />
              <Pie
                data={newArray}
                dataKey="graphVal"
                cy={130}
                innerRadius={80}
                outerRadius={100}
                label
                onMouseMove={onMouseMove}
                minAngle={20}
              />
            </DashboardPieChartFlex>
          </DashboardPieChartContainer>
        </div>

        <DashboardChartLegendTable>
          <LegendCol>
            <LegendColTitle>Coin List</LegendColTitle>
            <LegendColContent>
              {newArray.map((entry, index) => (
                <li
                  key={index}
                  style={{
                    width: "max-content",
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <Bullet color={optionColors[index]?.value} />

                  {entry?.symbol}
                </li>
                //  )
              ))}
            </LegendColContent>
          </LegendCol>
          <LegendCol>
            <LegendColTitle>User Name</LegendColTitle>
            <LegendColContent>
              {newArray?.map((entry) => (
                <li className="flex text-nowrap ">{entry?.userName}</li>
              ))}
            </LegendColContent>
          </LegendCol>
          <LegendCol>
            <LegendColTitle>Quantity</LegendColTitle>
            <LegendColContent>
              {newArray.map((entry) => (
                <li>{(entry?.graphVal || 0).toFixed(2)}</li>
              ))}
            </LegendColContent>
          </LegendCol>
        </DashboardChartLegendTable>
      </div> */}

      {transectionData.length > 0 ? (
        <ReactTableBase
          columns={marketplaceData?.otc_type != "general" ? header2 : header}
          data={data}
          tableConfig={tableConfig}
        />
      ) : (
        <h3>No Transaction Found</h3>
      )}

      {show && (
        <AllTransectionDetailModal
          show={show}
          hide={() => setShow(false)}
          detailData={detailData}
        />
      )}
    </>
  );
};
export default AllTransectionTable;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const LegendCol = styled("div")`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled("div")`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled("ul")`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;
