/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Ring } from "@uiball/loaders";
import QRCode from "qrcode.react";
import Badge from "react-bootstrap/Badge";
// import { Form, Field } from 'react-final-form';
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Switch, Stack } from "@mui/material";
import { Modal as BootstrapModal, Spinner } from "react-bootstrap";
import { FormButtonToolbar } from "@/shared/components/form/FormElements";
import { AccountButton } from "@/shared/components/account/AccountElements";
import { Button } from "@/shared/components/Button";

import {
  MaterialFormContainer,
  MaterialTextField,
  MaterialFormLabel,
} from "@/shared/components/form/MaterialFormElements";
import Panel from "@/shared/components/Panel";
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
  colorLightAccent,
} from "@/utils/palette";
import { SpinnerDiv } from "../../../Dashboards/Operations/Contract/component/ContractTable";
import SimpleModal from "../../../../shared/components/Modal/SimpleModal";
import {
  SendTwoFactor,
  getUserInfo,
  getBecomeApprover,
  create,
  verifyTFA,
  tfaEnable,
  tfaDisable,
  changePassword,
  becomeApprover,
  submitOtcMarketplaceRequest,
  getMarketPlaceStatus,
} from "../../../../utils/services/user.service";
// import { changePassword, clearMsg } from '../../../../redux/actions/authActions';
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  initialValue,
  disabled,
  type,
}) => (
  <MaterialTextField
    label={label}
    error={touched && error}
    value={input.value || initialValue}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
    disabled={disabled}
    type={type}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  label: "",
  initialValue: "",
  disabled: false,
  type: "text",
};

const ProfileSetting = ({ onSubmit }) => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useState([]);
  const [load, setLoad] = useState(false);
  const [load1, setLoad1] = useState(false);
  const [load12, setLoad12] = useState(false);
  const [load11, setLoad11] = useState(false);
  const [twoFactors, setTwoFactor] = useState(false);
  const [twoFactors2, setTwoFactor2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [twoFactorModal, settwoFactorModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [loading, setloading] = useState(false);
  const [tfaUrl, setTfaUrl] = useState("");
  const [tfaUrl1, setTfaUrl1] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [rejectionReason1, setRejectionReason1] = useState("");
  const [acceptReason, setAcceptReason] = useState("");
  const [approveFile, setFile1] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [isBecomeApproverModal, setIsBecomeApproverModal] = useState(false); // State to control modal visibility
  const [isOtcApproverModal, setIsOtcApproverModal] = useState(false); // State to control modal visibility
  const [Msg, setMsg] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [otcRequestStatus, setOtcRequestStatus] = useState('');
  const [becomeApproverRequest, setBecomeApproverRequest] = useState(sessionStorage.getItem("approverStatus") || "");
  const [marketplaceVal, setMarketplaceVal] = useState("");
  const dispatch = useDispatch();

  const getUsers = () => {
    setLoad(true);
    getUserInfo()
      .then((res) => {
        // console.log('getUsers >>>>>', res);
        if (res.status === 200) {
          // console.log('res', res);
          setLoad(false);
          setUserInfo(res?.data?.User);
          setTwoFactor(res.data.User?.status_2fa);
          setTwoFactor2(res.data.User?.TFA === true);
        } else {
          setLoad(false);
          setUserInfo(JSON.parse(localStorage.getItem("user")));
          setTwoFactor(userInfo?.data?.user?.status_2fa);
        }
      })
      .catch(() => {
        setUserInfo(JSON.parse(localStorage.getItem("user")));
        setLoad(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);


  useEffect(() => {
    const userSessionData = JSON.parse(localStorage.getItem('user'));
    userSessionData.data.user.TFA = twoFactors2; // Update the value to true


    // Save the updated object back to session storage
   localStorage.setItem('user', JSON.stringify(userSessionData))
  }, [twoFactors2])

  const handleSubcategoryClick = async () => {
    // debugger;
    setModal(false);
    setIsBecomeApproverModal(true);
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: colorAccent,
      "&:hover": {
        backgroundColor: "#4ce1b65e",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colorAccent,
    },
  }));

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleTwoFactorChange = async (e) => {
    const value = e.target.checked;
    setQrCode(true);
    // console.log('handleTwoFactorChange', value);
    if (value === false) {
      try {
        setLoad(true);
        setLoad11(true);
        setTfaUrl1(true);
        setLoad11(false);
        setLoad(false);
      } catch (error) {
        setLoad(false);
        setLoad11(false);
        console.error("Error verifyTFA:", error);
        console.error("verifyTFA Response data:", error.response.data);
        console.error("verifyTFA Response status:", error.response.status);
        console.error("verifyTFA Response headers:", error.response.headers);
        toast.error(error.response.data.Message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      try {
        setLoad(true);
        setLoad1(true);
        const response = await create();
        if (response.data) {
          // console.log('create Response >>>>>>> ', response.data);
          setTfaUrl(response.data.tfaURL);
          setLoad(false);
          setLoad1(false);
        } else {
          console.error("Create Not Found");
        }
      } catch (error) {
        setLoad(false);
        setLoad1(false);
        console.error("Error create data:", error);
      }
    }
  };

  const handleRejectOrder = async () => {
    const requestData = {
      token: rejectionReason,
    };
    try {
      setLoad(true);
      setLoad1(true);
      const responseReject = await verifyTFA(requestData);
      if (responseReject.data) {
        // console.log('verifyTFA Update', responseReject.data);
        if (responseReject.data.verified === true) {
          const tfaEnableResponse = await tfaEnable();
          if (tfaEnableResponse.data) {
            setTfaUrl("");
            setTwoFactor2(true);
            toast.success(tfaEnableResponse.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoad(false);
            setLoad1(false);
          } else {
            setLoad(false);
            setLoad1(false);
            toast.error("TFA Enable failed", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          setLoad(false);
          setLoad1(false);
          toast.error("Verify TFA failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setLoad(false);
        setLoad1(false);
        console.error("verifyTFA failed:", responseReject);
      }
    } catch (error) {
      setLoad(false);
      setLoad1(false);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleRejectOrder1 = async () => {
    const requestData = {
      token: rejectionReason1,
    };
    try {
      setLoad(true);
      setLoad11(true);
      const responseReject = await verifyTFA(requestData);
      // console.log('responseReject', responseReject);
      if (responseReject.data) {
        // console.log('verifyTFA Update', responseReject.data);
        if (responseReject.data.verified === true) {
          const tfaEnableResponse = await tfaDisable();
          // console.log('tfaDisable', tfaEnableResponse);
          if (tfaEnableResponse.data) {
            setTwoFactor2(false);
            setTfaUrl1(false);
            toast.success(tfaEnableResponse.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoad(false);
            setLoad11(false);
          } else {
            setLoad(false);
            setLoad11(false);
            toast.error("TFA Disable failed", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          setLoad(false);
          setLoad11(false);
          toast.error("Verify TFA failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setLoad(false);
        setLoad11(false);
        console.error("verifyTFA failed:", responseReject);
      }
    } catch (error) {
      setLoad(false);
      setLoad1(false);
      console.error("Error verifyTFA:", error);
      console.error("verifyTFA Response data:", error.response.data);
      console.error("verifyTFA Response status:", error.response.status);
      console.error("verifyTFA Response headers:", error.response.headers);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case "completed":
        return "#4ce1b6";
      case "approved":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      default:
        return "#70bbfd";
    }
  };

  const closeBecomeApproverModal = () => {
    setIsBecomeApproverModal(false);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0].type !== 'application/pdf') {
      toast.error('Please chose only PDF file ')
      return
    }
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleButtonClickClick = () => {
    fileInputRef.current.click();
  };

  const handleFileRemove = () => {
    setFile(null);
    toast.success("Document Removed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleBecomeApproverStore = async (values) => {
    try {
      setIsLoading(true);
      // Create the form data with all necessary values
      const formData = new FormData();
      formData.append("approver_comment", values.comments);
      formData.append("File", file);

      const approverRequest = await becomeApprover(formData);
      if (approverRequest.status === 200) {
        toast.success("approver created successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
       localStorage.setItem("approverStatus", "pending");
        setBecomeApproverRequest('pending')
        setIsLoading(false);
        history.push("/profile");
        closeBecomeApproverModal()
      } else {
        console.error(`Error creating approver: ${approverRequest.statusText}`);
        toast.error("Error creating approver", approverRequest.Error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        closeBecomeApproverModal()
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating approver:", error);
      toast.error(
        `Error creating approver: ${error.response?.data?.Error || error.message
        }`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        if (error.response.data && error.response.data.Message) {
          toast.error(error.response.data.Message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
      closeBecomeApproverModal()
    }
  };

  const handleOtcRequest = async (value) => {

    setIsLoading(true)
    try {
      const payload = {
        market_place_name: value
      }
      const res = await submitOtcMarketplaceRequest(payload);
      if (res) {
        toast.success(res?.data?.Message);
        setIsOtcApproverModal(false)
        setIsLoading(false)
        getProfileStatus()
      }
    } catch (error) {
      toast.error(error?.response?.data.Message);
      setIsOtcApproverModal(false)
      setIsLoading(false)

    }
  };

  const status = JSON.parse(localStorage.getItem("user"))

  const getProfileStatus = async () => {
    const res = await getMarketPlaceStatus()

    setOtcRequestStatus(res?.data?.data?.status)
  }

  useEffect(() => {
    getProfileStatus()
    setBecomeApproverRequest(status?.data?.user?.approver_status ? status?.data?.user?.approver_status : becomeApproverRequest)
  }, [status])

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div
        className="row mt-0 mb-2 mx-auto "
        style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
      >
        {userInfo && (
          <>
            <Formik
              initialValues={{
                comments: "",
                File: null,
              }}
              onSubmit={(values, { resetForm }) => {

                handleBecomeApproverStore(values);
              }}
              validate={(values) => {
                const errors = {};
                setShowAlert(false);

                if (!values.comments) {
                  errors.comments = "Comments are required";
                }
                if (!file) {
                  errors.File = "Document is Required!";
                }
                return errors;
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                } = props;
                return (
                  <div>
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                        <label htmlFor="title" style={{ fontSize: "small" }} className="form-label text-white mb-0">
                          Approver Status
                        </label>
                        <br />
                        <Button onClick={() => handleSubcategoryClick()}
                          disabled={becomeApproverRequest !== ""}
                          size="md" className="py-2 mt-2 transform-lowercase" variant="outline-success">
                          Become Approver
                        </Button>
                        <br />
                        {becomeApproverRequest && <Badge
                          bg={`${becomeApproverRequest === 'pending' ? 'warning' : becomeApproverRequest === 'approved' ? 'success' : 'danger'}`}
                          className="mb-2"
                          style={{
                            paddingBottom: "6px",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          {becomeApproverRequest}
                        </Badge>}
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 text-center mx-auto mt-3 mt-md-0">
                        <label htmlFor="title" style={{ fontSize: "small" }} className="form-label text-white mb-0">
                          Approver Status
                        </label>
                        <br />
                        <Button onClick={() => setIsOtcApproverModal(true)} disabled={otcRequestStatus !== "" && otcRequestStatus !== undefined} size="md" className="py-2 mt-2 transform-lowercase" variant="outline-success">
                          OTC Marketplace Request
                        </Button>
                        <br />
                        {otcRequestStatus && <Badge
                          bg={`${otcRequestStatus === 'pending' ? 'warning' : otcRequestStatus === 'approved' ? 'success' : 'danger'}`}
                          className="mb-2"
                          style={{
                            paddingBottom: "6px",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          {otcRequestStatus}
                        </Badge>}
                      </div>
                    </div>

                    <Modal
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={isBecomeApproverModal}
                      onHide={closeBecomeApproverModal}
                    >
                      <Modal.Header
                        style={{ backgroundColor: "#2a2a31" }}
                        closeButton
                      >
                        <Modal.Title
                          className="text-center"
                          style={{ fontSize: "large", color: "white" }}
                        >
                          Become Approver Request
                        </Modal.Title>
                      </Modal.Header>
                      {isLoad ? (
                        <Modal.Body style={{ backgroundColor: "#2a2a31" }}>
                          <SpinnerDiv>
                            <Spinner style={{ color: colorAccent }} />
                          </SpinnerDiv>
                        </Modal.Body>
                      ) : (
                        <Modal.Body style={{ backgroundColor: "#2a2a31" }}>
                          <Form onSubmit={handleSubmit}>
                            <div className="row mb-1">
                              <div className="col-md-5 mt-1">
                                <InputDiv>
                                  <label
                                    htmlFor="email"
                                    className="text-white mb-1"
                                  >
                                    Comments *
                                  </label>
                                  <ChatSearchInput
                                    name="comments"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder="Enter the request comments"
                                    className={`mb-0 form-control ${errors.comments &&
                                      touched.comments &&
                                      "error"
                                      }`}
                                  />
                                  {errors.comments && touched.comments && (
                                    <Error className="input feedback">
                                      {errors.comments}
                                    </Error>
                                  )}
                                </InputDiv>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-5 my-2">
                                <label
                                  htmlFor="File"
                                  className="form-label text-white"
                                >
                                  Document
                                </label>
                                <input
                                  hidden
                                  type="file"
                                  ref={fileInputRef}
                                  className="form-control"
                                  onChange={handleFileChange}
                                  id="File"
                                  name="File"
                                  accept=".pdf"
                                />
                                <Button
                                  size="md"
                                  onClick={handleButtonClickClick}
                                  className="py-2 btn-block mb-0"
                                  variant="outline-success"
                                >
                                  Upload Document
                                </Button>
                                {errors.File && touched.File && (
                                  <ErrorMsg className="input feedback">
                                    {errors.File}
                                  </ErrorMsg>
                                )}
                              </div>
                              {file && (
                                <div className="col-md-7">
                                  <label
                                    htmlFor="features"
                                    style={{ fontSize: "small" }}
                                    className="form-label text-white my-2"
                                  >
                                    Uploaded Document{" "}
                                  </label>
                                  <br />
                                  <div
                                    className="pt-1"
                                    style={{
                                      border: "1px solid white",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <label
                                      htmlFor="features"
                                      className="form-label text-white mt-1 mx-2"
                                    >
                                      {file.name}{" "}
                                    </label>
                                    <button
                                      type="button"
                                      style={{ marginTop: "2px" }}
                                      className="btn btn-danger py-0 float-right mr-1 mb-2"
                                      onClick={handleFileRemove}
                                    >
                                      Remove <i className="fa fa-file-pdf-o" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="row d-flex justifyContent center">
                              <div className="col-md-3">
                                <Button
                                  type="submit"
                                  size="md"
                                  className="py-2 btn-block"
                                  variant="outline-success"
                                >
                                  {isLoading ? "Creating..." : "Submit"}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </Modal.Body>
                      )}
                    </Modal>


                  </div>
                );
              }}
            </Formik>

            <Formik
              initialValues={{
                marketplaceName: "",
              }}
              onSubmit={(values, { resetForm }) => {
                handleOtcRequest(values.marketplaceName);
                resetForm(); // Optionally reset the form after submission
              }}
              validate={(values) => {
                const errors = {};

                if (!values.marketplaceName) {
                  errors.marketplaceName = "Marketplace name is required";
                }

                return errors;
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                } = props;
                return (
                  <div>
                    <Modal
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={isOtcApproverModal}
                      onHide={() => setIsOtcApproverModal(false)}
                    >
                      <Modal.Header
                        style={{ backgroundColor: "#2a2a31" }}
                        closeButton
                      >
                        <Modal.Title
                          className="text-center"
                          style={{ fontSize: "large", color: "white" }}
                        >
                          Become Approver Request
                        </Modal.Title>
                      </Modal.Header>
                      {isLoad ? (
                        <Modal.Body style={{ backgroundColor: "#2a2a31" }}>
                          <SpinnerDiv>
                            <Spinner style={{ color: colorAccent }} />
                          </SpinnerDiv>
                        </Modal.Body>
                      ) : (
                        <Modal.Body style={{ backgroundColor: "#2a2a31" }}>
                          <Form onSubmit={handleSubmit}>
                            {/* Marketplace name input */}
                            <div className="row mb-1">
                              <div className="col-md-5 mt-1">
                                <InputDiv>
                                  <label htmlFor="marketplaceName" className="text-white mb-1">
                                    Marketplace Name *
                                  </label>
                                  <ChatSearchInput
                                    id="marketplaceName"
                                    name="marketplaceName"
                                    type="text"
                                    value={values.marketplaceName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Marketplace Name"
                                    className={`mb-0 form-control ${errors.marketplaceName && touched.marketplaceName && "error"
                                      }`}
                                  />
                                  {errors.marketplaceName && touched.marketplaceName && (
                                    <Error className="input feedback">
                                      {errors.marketplaceName}
                                    </Error>
                                  )}
                                </InputDiv>
                              </div>
                            </div>

                            {/* Submit button */}
                            <div className="row d-flex justifyContent center">
                              <div className="col-md-3">
                                <Button
                                  type="submit"
                                  size="md"
                                  className="py-2 btn-block"
                                  variant="outline-success"
                                  disabled={props.isSubmitting} // Disable the button while submitting
                                >
                                  {props.isSubmitting ? "Submitting..." : "Submit"}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </Modal.Body>
                      )}
                    </Modal>
                  </div>
                );
              }}
            </Formik>


            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
              <hr
                className="mt-2 mx-0"
                style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
              />
            </div>
          </>
        )}
        <div className="col-lg-6 col-md-8 col-sm-12 text-center mx-auto">
          <label
            htmlFor="title"
            style={{ fontSize: "small" }}
            className="form-label text-white mb-0"
          >
            Two Factor
          </label>
          {load12 ? (
            <SpinnerDiv className="py-2" style={{ height: "auto" }}>
              <Spinner style={{ color: colorAccent }} />
            </SpinnerDiv>
          ) : (
            <Stack direction="row" spacing={1} alignItems="center">
              <div className="mx-auto">Disable</div>
              <CustomSwitch
                {...label}
                value={twoFactors}
                checked={twoFactors}
                onChange={(e) => {
                  setloading(true);
                  setTwoFactor(e.target.checked);
                  setLoad12(true);
                  SendTwoFactor()
                    .then((response) => {
                      // console.log("Response from SendTwoFactor:", response);
                      if (response.data.data.status_2fa === true) {
                        // console.log("F", response.data.data.status_2fa);
                        toast.success("Two Factor Enabled", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      } else {
                        // console.log("T", response.data.data.status_2fa);
                        toast.success("Two Factor Disabled", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }
                      setloading(false);
                      setLoad12(false);
                      getUsers();
                    })
                    .catch((err) => {
                      // console.log('===2fa', err, err?.message);
                      setMsg(err?.message);
                      setTwoFactor(null);
                      setloading(false);
                      setLoad12(false);
                    });
                }}
                // onClick={() => settwoFactorModal(true)}
                color="default"
              />
              <div className="mx-auto">Enable</div>
            </Stack>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
          <hr
            className="mt-2 mx-0"
            style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
          />
        </div>
        <div className="col-lg-6 col-md-8 col-sm-12 text-center mx-auto">
          <label
            htmlFor="title"
            style={{ fontSize: "small" }}
            className="form-label text-white mb-0"
          >
            2FA Authenticator
          </label>
          <Stack direction="row" spacing={1} alignItems="center">
            <div className="mx-auto">Disable</div>
            <CustomSwitch
              {...label}
              value={twoFactors2}
              checked={twoFactors2}
              onChange={(e) => {
                handleTwoFactorChange(e);
              }}
              color="default"
            />
            <div className="mx-auto">Enable</div>
          </Stack>
        </div>
        {load1 ? (
          <SpinnerDiv>
            <Spinner style={{ color: colorAccent }} />
          </SpinnerDiv>
        ) : (
          tfaUrl && (
            <>
              <div className="col-lg-12 col-md-12 col-sm-12 my-2 text-center mx-auto">
                <hr
                  className="mt-2 mx-0"
                  style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                />
                <span className="d-flex">
                  <span className="ms-auto">
                    <p
                      style={{ fontSize: "medium" }}
                      className="py-1 text-white"
                    >
                      <b>Scan the QR code on your Authenticator App</b>
                    </p>
                  </span>
                  <span className="ms-auto">
                    <i
                      onClick={() => {
                        setTfaUrl("");
                        setRejectionReason("");
                      }}
                      style={{
                        marginLeft: "6px",
                        marginRight: "5px",
                        color: "#dc3545",
                        marginTop: "6px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      className="fa fa-close float-right"
                    />
                  </span>
                </span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-2 text-center mx-auto">
                <div className="d-flex bg-white w-100 p-4">
                  <QRCode
                    size="200"
                    level="H"
                    value={tfaUrl}
                    style={{ width: "100%" }}
                    renderAs="canvas"
                  // className="'d-flex bg-white "
                  />
                </div>
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Enter Code"
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  className="form-control mt-2"
                />
                <Button
                  onClick={handleRejectOrder}
                  size="sm"
                  className="btn w-50 mb-2 px-2 mt-2 w-100"
                  variant="outline-success"
                >
                  Verify
                </Button>
              </div>
            </>
          )
        )}
        {load11 ? (
          <SpinnerDiv>
            <Spinner style={{ color: colorAccent }} />
          </SpinnerDiv>
        ) : (
          tfaUrl1 && (
            <>
              <div className="col-lg-12 col-md-12 col-sm-12 my-2 text-center mx-auto">
                <hr
                  className="mt-2 mx-0"
                  style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                />
                <span className="d-flex">
                  <span className="ms-auto">
                    <p
                      style={{ fontSize: "medium" }}
                      className="py-1 text-white"
                    >
                      <b>Open your Authenticator App for code</b>
                    </p>
                  </span>
                  <span className="ms-auto">
                    <i
                      onClick={() => {
                        setTfaUrl1("");
                        setRejectionReason1("");
                      }}
                      style={{
                        marginLeft: "6px",
                        marginRight: "5px",
                        color: "#dc3545",
                        marginTop: "6px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      className="fa fa-close float-right"
                    />
                  </span>
                </span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-2 text-center mx-auto">
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Enter Code"
                  value={rejectionReason1}
                  onChange={(e) => setRejectionReason1(e.target.value)}
                  className="form-control mt-2"
                />
                <Button
                  onClick={handleRejectOrder1}
                  size="sm"
                  className="btn w-50 mb-2 px-2 mt-2 w-100"
                  variant="outline-success"
                >
                  Verify
                </Button>
              </div>
            </>
          )
        )}
        {/* {
           load1 ? (
             <SpinnerDiv>
               <Spinner style={{ color: colorAccent }} />
             </SpinnerDiv>
            ) : (
          tfaUrl && (
          <div className="col-lg-6 col-md-6 col-sm-12 mb-2 text-center mx-auto">
            <QRCode size="190" level="H" value={tfaUrl} style={{ width: '100%' }} renderAs="canvas" />
            <input
              type="text"
              name="title"
              id="title"
              placeholder="Enter Code" 
              value={rejectionReason}
              onChange={e => setRejectionReason(e.target.value)}
              className="form-control mt-2"
            />
            <Button onClick={handleRejectOrder} size="sm" className="btn w-50 mb-2 px-2 mt-2 w-100" variant="outline-success">Verify</Button>
          </div>
                  )
            )
} */}
        {/* {tfaUrl1 && (
          <div className="col-lg-6 col-md-6 col-sm-12 mb-2 text-center mx-auto">
            <input
              type="text"
              name="title"
              id="title"
              placeholder="Enter Code" 
              value={rejectionReason1}
              onChange={e => setRejectionReason1(e.target.value)}
              className="form-control mt-2"
            />
            <Button onClick={handleRejectOrder1} size="sm" className="btn w-50 mb-2 px-2 mt-2 w-100" variant="outline-success">Verify</Button>
          </div>
                  )} */}
      </div>
      <SimpleModal
        isActive={twoFactorModal}
        onClose={() => settwoFactorModal(false)}
      >
        {loading ? (
          <ModalBody>
            <SpinnerDiv>
              <Spinner style={{ color: colorAccent }} />
            </SpinnerDiv>
          </ModalBody>
        ) : (
          <ModalDiv>
            {twoFactors ? (
              <span
                className="lnr lnr-checkmark-circle"
                style={{ color: colorAccent, fontSize: "55px" }}
              />
            ) : twoFactors === null ? (
              <span
                className="lnr lnr-warning"
                style={{ color: "red", fontSize: "55px" }}
              />
            ) : (
              <span
                className="lnr lnr-circle-minus"
                style={{ color: "red", fontSize: "55px" }}
              />
            )}
            { }
            <TwoFactorTitle
              style={{ color: twoFactors ? colorAccent : "#ff000096" }}
            >
              {twoFactors
                ? "Two Factor Enabled"
                : twoFactors === null
                  ? "Something Went Wrong !!"
                  : "Two Factor Disabled"}
              { }
            </TwoFactorTitle>
            <TwoFactorSubTitle>
              {twoFactors === null ? Msg : " Status Changed Successfully!!"}
            </TwoFactorSubTitle>
            <div>
              <AccountButton
                variant="outline-success"
                onClick={() => {
                  settwoFactorModal(false);
                }}
                style={{
                  padding: "10px 20px",
                }}
              >
                ok
              </AccountButton>
            </div>
          </ModalDiv>
        )}
      </SimpleModal>
    </>
  );
};

ProfileSetting.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProfileSetting;

const ErrorMsg = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

export const ModalDiv = styled.div`
  height: 16rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TwoFactorTitle = styled.h4`
  padding: 5px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: ${colorAccent};
`;

export const TwoFactorSubTitle = styled.h6`
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;

const ModalBody = styled.div``;
