/* eslint-disable no-restricted-syntax */
/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Select from 'react-select';
import { Ring } from '@uiball/loaders';
import Modal from 'react-bootstrap/Modal';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { FormLabel } from '@mui/material';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '@/config/walletConnect';
import Panel from '@/shared/components/Panel';
import { Button } from '../../../../../shared/components/Button';
import {
  // createMarketPlace,
  getCategory,
  transferStoreFee,
  checkStore,
  updateProduct,
  getProductData,
  getMyStore,
  delProductImg,
  deleteProductDoc,
} from '../../../../../utils/services/user.service';
import { CustomSelect, CustomMultiSelect } from '../../../../../shared/components/CustomSelect';
import './productedit.css';

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const CreateProduct = () => {
  const [msg, setMsg] = useState('');
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [statusCode, setstatusCode] = useState(400);
  let countriesArray = [];
  // let decodedArray = [];
  let transformedColorArray = [];
  let transformedCountryArray = [];
  let subCategoryArray = [];
  let fieldValuesArray = [];
  let categoryArrayDetail = [];
  let categoryArrayDetail2 = [];

  const [fieldValuesArrayVal, setfieldValuesArray] = useState([]);

  const {
    projectId,
    REACT_APP_ETHERIUM_CHAIN,
    REACT_APP_SMART_CHAIN,
    REACT_APP_POLYGON_MATIC,
  } = config;
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [CountriesNew, setCountriesNew] = useState([]);
  const [CountriesNew1, setCountriesNew1] = useState([]);
  const [ColorsNew1, setColorsNew1] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSymbol, SetSelectedSymbol] = useState();
  const [selectedImages1, setSelectedImages1] = useState([]);
  const [selectedImages2, setSelectedImages2] = useState([]);
  const [selectedImages3, setSelectedImages3] = useState([]);
  const [SelectedWarranty, setSelectedWarranty] = useState(0);
  const [setDeliveryChargesvalNew, setDeliveryChargesNew] = useState(0);
  const [selectedChainDetails, setSelectedChainDetails] = useState({}); // Initialize an empty object
  const [selectedCountryDetails, setCountryChainDetails] = useState({}); // Initialize an empty object
  const [selectedColorDetails, setColorDetails] = useState({}); // Initialize an empty object
  const [selectedChain, setSelectedChain] = useState(REACT_APP_SMART_CHAIN);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedColor, setSelectedColor] = useState('Red');
  const [selectedColorNew, setSelectedColorNew] = useState('Red');
  const [SelectedCondition, setSelectedCondition] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [StoreSymbol, setStoreSymbol] = useState('');
  const [showError, setShowError] = useState(false);
  const [showErrorNew, setShowErrorNew] = useState(false);
  const [showError2, setShowError2] = useState(false);
  const [showError2New, setShowError2New] = useState(false);
  const [showError3, setShowError3] = useState(false);
  const [showError3New, setShowError3New] = useState(false);
  const [showError4, setShowError4] = useState(false);
  const [showError5, setShowError5] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [features, setFeatures] = useState(['']);
  const [setColorsVal, setColors] = useState([]);
  const [setTrueFalseVal, setTrueFalse] = useState('');
  const [StoreID, setStoreID] = useState('');
  const [StoreChainId, setStoreChainId] = useState('');
  const [StoreApprover, setStoreApprover] = useState('');
  const [StoreCountry, setStoreCountry] = useState('');
  const [StoreAvailable, setStoreAvailable] = useState('');
  const initialArrayColor = [
    { value: 'Red', label: 'Red' },
    { value: 'Blue', label: 'Blue' },
  ];
  const [image1, setImage1] = useState(null);
  const [show1, setShow1] = useState(false);
  const [cropper, setCropper] = useState();
  const handleClose1 = () => setShow1(false);
  const [storeData, setStoreData] = useState(null);
  const [storeCategoryID, setStoreCategoryID] = useState(null);
  const [subCategory, setSubCategory] = useState([]);
  const [storeCategoryName, setstoreCategoryName] = useState('');
  const [selectedSubcategoryID, setSelectedSubcategoryID] = useState('');
  const [isDeliveryServiceActive, setIsDeliveryServiceActive] = useState('');
  const [isProductDocument, setProductDocument] = useState([]);
  const [arrayOfArraysNew, setArrayOfArraysNew] = useState([]);
  const [SelectedPrice, setSelectedPrice] = useState(1);
  const [SelectedPriceNew, setSelectedPriceNew] = useState(1);
  const [SelectedQuantity, setSelectedQuantity] = useState(1);
  const [SelectedQuantityNew, setSelectedQuantityNew] = useState(1);
  const [SelectedDiscount, setSelectedDiscount] = useState(0);
  const [SelectedDiscountNew, setSelectedDiscountNew] = useState(0);
  const [SelectedModel, setSelectedModel] = useState('0');
  const [SelectedModelNew, setSelectedModelNew] = useState('0');
  const [SelectedStorage, setSelectedStorage] = useState('16 GB');
  const [SelectedStorageNew, setSelectedStorageNew] = useState('16 GB');
  const [selectedTransmission, setSelectedTransmission] = useState('Manual');
  const [selectedTransmissionNew, setSelectedTransmissionNew] = useState('Manual');
  const [SelectedHorsePower, setSelectedHorsePower] = useState('600 CC');
  const [SelectedHorsePowerNew, setSelectedHorsePowerNew] = useState('600 CC');
  const [SelectedRegistration, setSelectedRegistration] = useState('United States');
  const [SelectedRegistrationNew, setSelectedRegistrationNew] = useState('United States');
  const [SelectedRegion, setSelectedRegion] = useState('USA');
  const [SelectedRegionNew, setSelectedRegionNew] = useState('USA');
  const [SelectedCity, setSelectedCity] = useState('America');
  const [SelectedCityNew, setSelectedCityNew] = useState('America');
  const [SelectedFloor, setSelectedFloor] = useState(1);
  const [SelectedFloorNew, setSelectedFloorNew] = useState(1);
  const [SelectedSquareSize, setSelectedSquareSize] = useState('');
  const [SelectedSquareSizeNew, setSelectedSquareSizeNew] = useState('');
  const [selectedPropertyType, setSelectedPropertyType] = useState('Rental');
  const [selectedPropertyTypeNew, setSelectedPropertyTypeNew] = useState('Rental');
  const [TableData, StoreForTable] = useState('');
  const [valueCheckVal, valueCheck] = useState(1);
  const [valuePriceVal, valuePrice] = useState('');
  const [valueQuantityVal, valueQuantity] = useState('');
  const [valueDiscountVal, valueDiscount] = useState('');
  const [valueColorVal, valueColor] = useState('');
  const [selectedColorsss, setSelectedColorsss] = useState(initialArrayColor);
  const [valueSizeVal, valueSize] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [file2, setFile] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [CategoryDetail, setCategoryDetail] = useState([]);
  const [CategoryDetail2, setCategoryDetail2] = useState([]);
  const [sCategoryDetailkeys, setCategoryDetailkeys] = useState([]);
  const [getresultObject1, setresultObject1] = useState([]);
  const [getcategoryArrayDetailKeys2, setcategoryArrayDetailKeys2] = useState([]);
  const [selectedSizeNew, setSelectedSizeNew] = useState('Small');
  const [offerStatus, setOfferStatus] = useState(false);
  const [selectedType, setSelectedType] = useState("")
  const [selectedWallet, setSelectedWallet] = useState([])
  const [selectedAgencyPercentage, setSelectedAgencyPercentage] = useState("")
  const [sellerWalletOptions, setSellerWalletOptions] = useState([])


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const editDetail = (order, index) => {
    setSelectedOrder(order);
    setSelectedIndex(index);
    if (getcategoryArrayDetailKeys2.includes('discount')) {
      setSelectedDiscountNew(order.discount);
    }
    if (getcategoryArrayDetailKeys2.includes('price')) {
      setSelectedPriceNew(order.price);
    }
    if (getcategoryArrayDetailKeys2.includes('quantity')) {
      setSelectedQuantityNew(order.quantity);
    }
    if (getcategoryArrayDetailKeys2.includes('color')) {
      setSelectedColorNew(order.color);
    }
    if (getcategoryArrayDetailKeys2.includes('size')) {
      setSelectedSizeNew(order.size);
    }
    if (getcategoryArrayDetailKeys2.includes('storage')) {
      setSelectedStorageNew(order.storage);
    }
    if (getcategoryArrayDetailKeys2.includes('model')) {
      setSelectedModelNew(order.model);
    }
    if (getcategoryArrayDetailKeys2.includes('horse_power')) {
      setSelectedHorsePowerNew(order.horse_power);
    }
    if (getcategoryArrayDetailKeys2.includes('region')) {
      setSelectedRegionNew(order.region);
    }
    if (getcategoryArrayDetailKeys2.includes('registration')) {
      setSelectedRegistrationNew(order.registration);
    }
    if (getcategoryArrayDetailKeys2.includes('transmission')) {
      setSelectedTransmissionNew(order.transmission);
    }
    if (getcategoryArrayDetailKeys2.includes('city')) {
      setSelectedCityNew(order.city);
    }
    if (getcategoryArrayDetailKeys2.includes('floor')) {
      setSelectedFloorNew(order.floor);
    }
    if (getcategoryArrayDetailKeys2.includes('square_size')) {
      setSelectedSquareSizeNew(order.square_size);
    }
    if (getcategoryArrayDetailKeys2.includes('property_type')) {
      setSelectedPropertyTypeNew(order.property_type);
    }
    // console.log('Edit Detail: ', order);
    // console.log('Index: ', index);
    // let totalPrice = 0;
    // // Iterate through the array and sum up the prices
    // const orderData = order.product_details;
    // totalPrice = orderData.reduce((total, product) => total + product.quantity, 0);
    // setSelectedOrderQty(totalPrice);
    setShow(true);
  };

  const productCondition = [
    { value: 'New', label: 'New' },
    { value: 'Used', label: 'Used' },
  ];

  const handleConditionChange = (event) => {
    const selectedCondition = event.value;
    setSelectedCondition(selectedCondition);
  };

  // console.log('arrayOfArraysNew Before', arrayOfArraysNew);

  function Removeeee(indexToRemove) {
    const updatedArray = [...arrayOfArraysNew];
    updatedArray.splice(indexToRemove, 1); // Remove the element at indexToRemove
    setArrayOfArraysNew(updatedArray); // Update the state
    toast.success('Detail Removed', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  // console.log('arrayOfArraysNew After', arrayOfArraysNew);

  const [fields2, setFields2] = useState([
    {
      id: 1, value: '', color: '', size: '', price: '', quantity: '', discount: '', discount_percentage: '',
    }, // Initial field
  ]);

  const [selectedSize, setSelectedSize] = useState('Small');

  const handleSizeChange = (value) => {
    setSelectedSize(value.value);
  };

  const handleSizeChangeNew = (value) => {
    setSelectedSizeNew(value.value);
  };

  const handleModelChange = (e) => {
    const newValue = e.target.value;
    setSelectedModel(newValue);
  };

  const handleModelChangeNew = (e) => {
    const newValue = e.target.value;
    setSelectedModelNew(newValue);
  };

  const handleStorageChange = (value) => {
    setSelectedStorage(value.value);
  };

  const handleStorageChangeNew = (value) => {
    setSelectedStorageNew(value.value);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value.value);
  };

  const handleCityChangeNew = (value) => {
    setSelectedCityNew(value.value);
  };

  const handlePowerChange = (value) => {
    setSelectedHorsePower(value.value);
  };

  const handlePowerChangeNew = (value) => {
    setSelectedHorsePowerNew(value.value);
  };

  const handleRegionChange = (value) => {
    setSelectedRegion(value.value);
  };
  const handleOfferStatus = (event) => {
    const selectedStatus = event.value;
    setOfferStatus(selectedStatus);
  };

  const handleRegionChangeNew = (value) => {
    setSelectedRegionNew(value.value);
  };

  const handleRegistrationChange = (value) => {
    setSelectedRegistration(value.value);
  };

  const handleRegistrationChangeNew = (value) => {
    setSelectedRegistrationNew(value.value);
  };

  const handleTransmissionChange = (value) => {
    setSelectedTransmission(value.value);
  };

  const handleTransmissionChangeNew = (value) => {
    setSelectedTransmissionNew(value.value);
  };

  const handlePropertyTypeChange = (value) => {
    setSelectedPropertyType(value.value);
  };

  const handlePropertyTypeChangeNew = (value) => {
    setSelectedPropertyTypeNew(value.value);
  };

  const optionSizes = [
    { value: 'Small', label: 'Small' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Large', label: 'Large' },
  ];

  const CityArray = [
    { value: 'America', label: 'America' },
    { value: 'London', label: 'London' },
  ];

  const optionHoursePower = [
    { value: '600 CC', label: '600 CC' },
    { value: '1000 CC', label: '1000 CC' },
  ];

  const RegionArray = [
    { value: 'UK', label: 'UK' },
    { value: 'USA', label: 'USA' },
  ];
  const customOfferStatus = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ];

  const RegistrationArray = [
    { value: 'United States', label: 'United States' },
    { value: 'United Kingdom', label: 'United Kingdom' },
  ];

  const optionTransmission = [
    { value: 'Manual', label: 'Manual' },
    { value: 'Auto', label: 'Auto' },
  ];

  const PropertyTypes = [
    { value: 'Rental', label: 'Rental' },
    { value: 'Plot', label: 'Plot' },
    { value: 'For Sale', label: 'For Sale' },
    { value: 'Commercial', label: 'Commercial' },
  ];

  const handlePriceChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0) {
      setShowError3(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError3(false);
      }, 5000);
    } else {
      setShowError3(false);
      setSelectedPrice(newValue);
    }
  };

  const handlePriceChangeNew = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0) {
      setShowError3New(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError3New(false);
      }, 5000);
    } else {
      setShowError3New(false);
      setSelectedPriceNew(newValue);
    }
  };

  const handleSquareSizeChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0 || parsedValue > 100) {
      setShowError(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } else {
      setShowError(false);
      setSelectedSquareSize(newValue);
    }
  };

  const handleSquareSizeChangeNew = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0 || parsedValue > 100) {
      setShowError(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } else {
      setShowError(false);
      setSelectedSquareSizeNew(newValue);
    }
  };

  const handleQuantityChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0) {
      setShowError2(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError2(false);
      }, 5000);
    } else {
      setShowError2(false);
      setSelectedQuantity(newValue);
    }
  };

  const handleQuantityChangeNew = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0) {
      setShowError2New(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError2New(false);
      }, 5000);
    } else {
      setShowError2New(false);
      setSelectedQuantityNew(newValue);
    }
  };

  const handleButtonClickClick = () => {
    fileInputRef.current.click();
  };

  const handleButtonClickClick1 = () => {
    fileInputRef1.current.click();
  };

  const handleFloorChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0 || parsedValue > 100) {
      setShowError(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } else {
      setShowError(false);
      setSelectedFloor(newValue);
    }
  };

  const handleFloorChangeNew = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0 || parsedValue > 100) {
      setShowError(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } else {
      setShowError(false);
      setSelectedFloorNew(newValue);
    }
  };

  const handleDiscountChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0 || parsedValue > 100) {
      setShowError(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } else {
      setShowError(false);
      setSelectedDiscount(newValue);
    }
  };

  const handleDiscountChangeNew = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0 || parsedValue > 100) {
      setShowErrorNew(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowErrorNew(false);
      }, 5000);
    } else {
      setShowErrorNew(false);
      setSelectedDiscountNew(newValue);
    }
  };

  function handleButtonClick() {
    // console.log('arrayOfArraysNew 2 ========== ', arrayOfArraysNew);
    // console.log('storeCategoryName ========== ', storeCategoryName);

    const arrayOfArrays = [];
    fieldValuesArray = fields2.map(() => ({
      color: selectedColor,
      size: selectedSize,
      price: SelectedPrice,
      quantity: SelectedQuantity,
      discount_percentage: SelectedDiscount,
      sold_quantity: 0,
    }));

    const DataArray = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const carName of getresultObject1) {
      // console.log('ObjectObjectObjectObject', Object.keys(carName)[0]);
      //  if (storeCategoryName === 'vehicle') {
      DataArray[Object.keys(carName)[0]] = Object.keys(carName)[0] === 'model' ? SelectedModel
        : Object.keys(carName)[0] === 'color' ? selectedColor
          : Object.keys(carName)[0] === 'horse_power' ? SelectedHorsePower
            : Object.keys(carName)[0] === 'transmission' ? selectedTransmission
              : Object.keys(carName)[0] === 'engine_type' ? SelectedEngineType
                : Object.keys(carName)[0] === 'quantity' ? SelectedQuantity
                  : Object.keys(carName)[0] === 'registration' ? SelectedRegistration
                    : Object.keys(carName)[0] === 'region' ? SelectedRegion
                      : Object.keys(carName)[0] === 'city' ? SelectedCity
                        : Object.keys(carName)[0] === 'discount' ? SelectedDiscount
                          : Object.keys(carName)[0] === 'storage' ? SelectedStorage
                            : Object.keys(carName)[0] === 'size' ? selectedSize
                              : Object.keys(carName)[0] === 'property_type' ? selectedPropertyType
                                : Object.keys(carName)[0] === 'square_size' ? SelectedSquareSize
                                  : Object.keys(carName)[0] === 'floor' ? SelectedFloor
                                    : Object.keys(carName)[0] === 'price' ? SelectedPrice : 0;
      //  }
      //   if (storeCategoryName === 'Mobile') {
      //   DataArray[Object.keys(carName)[0]] = Object.keys(carName)[0] === 'model' ? SelectedModel
      //   : Object.keys(carName)[0] === 'color' ? selectedColor
      //   : Object.keys(carName)[0] === 'price' ? SelectedPrice
      //   : Object.keys(carName)[0] === 'quantity' ? SelectedQuantity 
      //   : Object.keys(carName)[0] === 'storage' ? SelectedStorage : 0;
      //  }
    }

    // console.log('fieldValuesArray >>>>>>>>> ', fieldValuesArray);

    const DataArray1 = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const carName of getresultObject1) {
      //  if (storeCategoryName === 'vehicle') {
      DataArray1[Object.keys(carName)[0]] = Object.keys(carName)[0] === 'model' ? SelectedModel
        : Object.keys(carName)[0] === 'color' ? selectedColor
          : Object.keys(carName)[0] === 'horse_power' ? SelectedHorsePower
            : Object.keys(carName)[0] === 'transmission' ? selectedTransmission
              : Object.keys(carName)[0] === 'engine_type' ? SelectedEngineType
                : Object.keys(carName)[0] === 'quantity' ? parseInt(SelectedQuantity, 10)
                  : Object.keys(carName)[0] === 'registration' ? SelectedRegistration
                    : Object.keys(carName)[0] === 'region' ? SelectedRegion
                      : Object.keys(carName)[0] === 'city' ? SelectedCity
                        : Object.keys(carName)[0] === 'discount' ? parseInt(SelectedDiscount, 10)
                          : Object.keys(carName)[0] === 'storage' ? SelectedStorage
                            : Object.keys(carName)[0] === 'size' ? selectedSize
                              : Object.keys(carName)[0] === 'property_type' ? selectedPropertyType
                                : Object.keys(carName)[0] === 'square_size' ? SelectedSquareSize
                                  : Object.keys(carName)[0] === 'floor' ? parseInt(SelectedFloor, 10)
                                    : Object.keys(carName)[0] === 'price' ? parseInt(SelectedPrice, 10) : 0;
      //   }
      //    if (storeCategoryName === 'Mobile') {
      //    DataArray1[Object.keys(carName)[0]] = Object.keys(carName)[0] === 'model' ? SelectedModel
      //   : Object.keys(carName)[0] === 'color' ? selectedColor
      //    : Object.keys(carName)[0] === 'price' ? parseInt(SelectedPrice, 10)
      //    : Object.keys(carName)[0] === 'quantity' ? parseInt(SelectedQuantity, 10)
      //     : Object.keys(carName)[0] === 'storage' ? SelectedStorage : 0;
      //  }
    }

    const fieldValuesArrayNew = ({
      color: selectedColor,
      size: selectedSize,
      price: parseInt(SelectedPrice, 10),
      quantity: parseInt(SelectedQuantity, 10),
      discount_percentage: parseInt(SelectedDiscount, 10),
      sold_quantity: 0,
    });

    // console.log('DataArray >>>>>>>>> ', DataArray);
    // console.log('DataArray1 >>>>>>>>> ', DataArray1);
    // console.log('fieldValuesArrayNew >>>>>>>>> ', fieldValuesArrayNew);

    setArrayOfArraysNew([...arrayOfArraysNew, DataArray1]);
    // console.log('arrayOfArraysNew <><><><><><><><><><>', arrayOfArraysNew);


    arrayOfArrays.push(DataArray);
    // console.log('arrayOfArrays JSON >>', JSON.stringify(arrayOfArrays[0])); 
    // console.log('arrayOfArrays >>', arrayOfArrays); 
    setfieldValuesArray(JSON.stringify(arrayOfArrays[0]));
    // console.log('fieldValuesArray >>>>>>> ', fieldValuesArray[0]);
    StoreForTable(DataArray);
    if (fieldValuesArray?.length > 0) {
      valueCheck(0);
      valuePrice(fieldValuesArray[0].price);
      valueQuantity(fieldValuesArray[0].quantity);
      valueDiscount(fieldValuesArray[0].discount_percentage);
      valueColor(fieldValuesArray[0].color);
      valueSize(fieldValuesArray[0].size);
      setSelectedSize('Small');
      setSelectedColor('Red');
      setSelectedQuantity(1);
      setSelectedPrice(1);
      setSelectedDiscount(0);
      setSelectedTransmission('Manual');
      setSelectedStorage('1 GB');
      setSelectedModel('0');
      setSelectedHorsePower('600 CC');
      setSelectedRegistration('United States');
      setSelectedRegion('USA');
      setSelectedCity('America');
      setSelectedFloor(1);
      setSelectedSquareSize('');
      // console.log('arrayOfArraysNew ========== ', arrayOfArraysNew);
    } else {
      console.log('Else');
    }
    // Call your desired function here
    // Example: yourFunctionToCall();
  }

  // function handleButtonClick() {
  //   // Your code to be executed when the button is clicked
  //   // console.log('Button clicked');
  //   const arrayOfArrays = [];
  //   fieldValuesArray = fields2.map(() => ({
  //     color: selectedColor,
  //     size: selectedSize,
  //     price: SelectedPrice,
  //     quantity: SelectedQuantity,
  //     discount_percentage: SelectedDiscount,
  //     sold_quantity: 0,
  //   }));

  //   const fieldValuesArrayNew = ({
  //     color: selectedColor,
  //     size: selectedSize,
  //     price: parseInt(SelectedPrice, 10),
  //     quantity: parseInt(SelectedQuantity, 10),
  //     discount_percentage: parseInt(SelectedDiscount, 10),
  //     sold_quantity: 0,
  //   });

  //   setArrayOfArraysNew([...arrayOfArraysNew, fieldValuesArrayNew]);


  //   arrayOfArrays.push(fieldValuesArray);
  //   // console.log('arrayOfArrays JSON >>', JSON.stringify(arrayOfArrays[0])); 
  //   // console.log('arrayOfArrays >>', arrayOfArrays); 
  //   setfieldValuesArray(JSON.stringify(arrayOfArrays[0]));
  //   // console.log('fieldValuesArray >>>>>>> ', fieldValuesArray[0]);
  //   StoreForTable(fieldValuesArray[0]);
  //   if (fieldValuesArray.length > 0) {
  //     valueCheck(0);
  //     valuePrice(fieldValuesArray[0].price);
  //     valueQuantity(fieldValuesArray[0].quantity);
  //     valueDiscount(fieldValuesArray[0].discount_percentage);
  //     valueColor(fieldValuesArray[0].color);
  //     valueSize(fieldValuesArray[0].size);
  //     setSelectedSize('Small');
  //     setSelectedColor('Red');
  //     setSelectedQuantity(1);
  //     setSelectedPrice(1);
  //     setSelectedDiscount(0);
  //   } else {
  //     console.log('Else');
  //   }
  //   // Call your desired function here
  //   // Example: yourFunctionToCall();
  // }

  function handleButtonClickNew(index) {
    setShow(false);
    const updatedArray = [...arrayOfArraysNew];
    if (getcategoryArrayDetailKeys2.includes('discount')) {
      updatedArray[index].discount = parseInt(SelectedDiscountNew, 10);
    }
    if (getcategoryArrayDetailKeys2.includes('price')) {
      updatedArray[index].price = parseInt(SelectedPriceNew, 10);
    }
    if (getcategoryArrayDetailKeys2.includes('quantity')) {
      updatedArray[index].quantity = parseInt(SelectedQuantityNew, 10);
    }
    if (getcategoryArrayDetailKeys2.includes('color')) {
      updatedArray[index].color = selectedColorNew;
    }
    if (getcategoryArrayDetailKeys2.includes('size')) {
      updatedArray[index].size = selectedSizeNew;
    }
    if (getcategoryArrayDetailKeys2.includes('storage')) {
      updatedArray[index].storage = SelectedStorageNew;
    }
    if (getcategoryArrayDetailKeys2.includes('model')) {
      updatedArray[index].model = SelectedModelNew;
    }
    if (getcategoryArrayDetailKeys2.includes('horse_power')) {
      updatedArray[index].horse_power = SelectedHorsePowerNew;
    }
    if (getcategoryArrayDetailKeys2.includes('region')) {
      updatedArray[index].region = SelectedRegionNew;
    }
    if (getcategoryArrayDetailKeys2.includes('registration')) {
      updatedArray[index].registration = SelectedRegistrationNew;
    }
    if (getcategoryArrayDetailKeys2.includes('transmission')) {
      updatedArray[index].transmission = selectedTransmissionNew;
    }
    if (getcategoryArrayDetailKeys2.includes('city')) {
      updatedArray[index].city = SelectedCityNew;
    }
    if (getcategoryArrayDetailKeys2.includes('floor')) {
      updatedArray[index].floor = SelectedFloorNew;
    }
    if (getcategoryArrayDetailKeys2.includes('square_size')) {
      updatedArray[index].square_size = SelectedSquareSizeNew;
    }
    if (getcategoryArrayDetailKeys2.includes('property_type')) {
      updatedArray[index].property_type = selectedPropertyTypeNew;
    }
    // console.log('Updated New Array', updatedArray);
    setArrayOfArraysNew(updatedArray);
  }

  const productListData = [
    {
      productId: id,
    },
  ];


  useEffect(() => {
    const fetchStoreData1 = async () => {
      fetch('https://restcountries.com/v3.1/all')
        .then(response => response.json())
        .then((data) => {
          // Extract specific data and store it in the countriesArray
          // eslint-disable-next-line react-hooks/exhaustive-deps
          countriesArray = data.map(country => ({
            label: country.name.common,
            value: country.name.common,
          }));

          // Now you have the data in the countriesArray
          setCountries(countriesArray);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

      try {
        const response = await getProductData(productListData[0]);

        setIsLoading(true);
        if (response.data && response.data) {
          const AllData = response.data.productDetails[0];
          const sellerWallets = response.data.productDetails.map(product => ({
            value: product.seller_wallet ? product.seller_wallet.address : '',
            label: product.seller_wallet ? product.seller_wallet.name : '',
          }));
          setProducts(response.data.productDetails[0]);
          setIsDeliveryServiceActive(AllData.isDeliveryServiceActive);
          setSelectedSubcategoryID(response.data.productDetails[0].subcategory_id);
          setSelectedCondition(response.data.productDetails[0].product_condition);
          setArrayOfArraysNew(response.data.productDetails[0].product_sizes_quantity);
          setSelectedWarranty(response.data.productDetails[0].warrantyInMonths);
          setDeliveryChargesNew(response.data.productDetails[0].deliveryCharges);
          setSelectedWallet(response.data.productDetails[0].seller_wallet);
          setSelectedAgencyPercentage(response.data.productDetails[0].agency_percentage);
          setStoreSymbol(response.data.productDetails[0].symbol);
          setProductDocument(response.data.productDetails[0].product_document);
          setOfferStatus(AllData?.custom_offer_status)
          setSellerWalletOptions(sellerWallets)
          const colorArr = response.data.productDetails[0].features;
          const picturesArr = response.data.productDetails[0].pictures;
          // console.log('Feature API : ', colorArr);
          // console.log('Feature Pictures : ', picturesArr);
          // decodedArray = JSON.parse(colorArr[0]);
          setFeatures(colorArr);
          // console.log('Feature API Decoded : ', colorArr);
          // console.log('length A ', selectedImages1.length);
          setSelectedImages1(picturesArr);
          // console.log('length B ', selectedImages1.length);
          // const colorArray = colorArr[0].split(',');

          const colorArr1 = response.data.productDetails[0].color;
          const allowedCountries1 = response.data.productDetails[0].allowedCountries;
          // const decodedArray111111 = JSON.parse(colorArr1[0]);
          // const decodedArray111111Country = JSON.parse(allowedCountries1[0]);
          setCountriesNew1(allowedCountries1);
          setColorsNew1(colorArr1);

          const colorArray1 = colorArr1[0].split(',');
          // eslint-disable-next-line react-hooks/exhaustive-deps
          transformedColorArray = colorArr1.map(value => ({
            value,
            label: value,
          }));

          // eslint-disable-next-line react-hooks/exhaustive-deps
          transformedCountryArray = allowedCountries1.map(value => ({
            value,
            label: value,
          }));

          setColors(transformedColorArray);
          setCountriesNew(transformedCountryArray);
          // console.log('Product Data : ', response.data.productDetails[0]);
        } else {
          setIsLoading(false);
          console.error('Not Found');
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching store data:', error);
      }
      setIsLoading(false);
    };

    fetchStoreData1();
  }, []);

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await getMyStore(); // Replace with your API call

        if (response.data && response.data.result?.length > 0) {
          const storeInfo = response.data.result[0]; // Extract the first store
          setStoreData(storeInfo);
          // console.log('storeInfo >>>>>>>>>>>>> ', storeInfo);
          // eslint-disable-next-line no-underscore-dangle
          setStoreID(storeInfo._id);
          setStoreChainId(storeInfo.chainId);
          setSelectedType(storeInfo.store_type);
          setStoreSymbol(storeInfo.symbol);
          setStoreApprover(storeInfo.approver_wallet_address);
          setStoreCountry(storeInfo.delivery_available_countries);
          setStoreAvailable(storeInfo.online_delivery_available);
          // console.log('StoreID', StoreID);
          // console.log('storeInfo', storeInfo);
          // eslint-disable-next-line no-underscore-dangle
          const categoryID = storeInfo.categories[0]._id;
          setStoreCategoryID(categoryID);
          const categoryName = storeInfo.categories[0].category_name;
          setstoreCategoryName(categoryName);
          const subcategoryID = storeInfo.categories[0].sub_categories;
          const categoryDetail = storeInfo.categories[0].custom_detail;

          categoryDetail.sort((a, b) => a.value - b.value);
          // console.log('categoryDetai sort', categoryDetail);


          // maphere
          // eslint-disable-next-line react-hooks/exhaustive-deps
          subCategoryArray = subcategoryID.map(data => ({
            label: data.sub_category_name,
            // eslint-disable-next-line no-underscore-dangle
            value: data._id,
          }));
          // eslint-disable-next-line react-hooks/exhaustive-deps

          // eslint-disable-next-line no-inner-declarations
          function formatLabel(key) {
            return key
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
          }


          const categoryArrayDetailKeys = categoryDetail.map(data => Object.keys(data)[0]);
          const categoryArrayDetailKeys1 = categoryDetail.map(data => Object.keys(data)[0]);
          const categoryArrayDetailKeys2 = categoryDetail.map(data => Object.keys(data)[0]);
          categoryArrayDetailKeys.push('id', 'value');
          // console.log('categoryArrayDetailKeys <><><><><><><><><><><><><>', categoryArrayDetailKeys);
          categoryArrayDetailKeys2.sort();
          // console.log('categoryArrayDetailKeys2 sort =====', categoryArrayDetailKeys2);


          // eslint-disable-next-line react-hooks/exhaustive-deps
          categoryArrayDetail = categoryArrayDetailKeys2.map(data => ({
            label: formatLabel(data), // Transform the key
            // eslint-disable-next-line no-underscore-dangle
            value: data,
            // eslint-disable-next-line no-nested-ternary
            inputType: data === 'color' ? 'dropdown' : data === 'size' ? 'dropdown' : data === 'engine_type' ? 'dropdown' : data === 'transmission' ? 'dropdown' : data === 'horse_power' ? 'dropdown' : data === 'storage' ? 'dropdown' : data === 'registration' ? 'dropdown' : data === 'region' ? 'dropdown' : data === 'city' ? 'dropdown' : data === 'property_type' ? 'dropdown' : 'input',
          }));

          // eslint-disable-next-line react-hooks/exhaustive-deps
          categoryArrayDetail2 = categoryArrayDetailKeys2.map(data => ({
            label: formatLabel(data), // Transform the key
          }));


          const resultObject = categoryArrayDetailKeys.reduce((object, key) => {
            // eslint-disable-next-line no-param-reassign
            object[key] = key === 'id' ? 1 : '';
            return object;
          }, {});

          const resultObject1 = categoryArrayDetailKeys1.reduce((object, key) => {
            // eslint-disable-next-line no-param-reassign
            object[key] = ' ';
            return object;
          }, {});

          // console.log('resultObject <><><><><><><><><><><><><>', resultObject);
          // console.log('resultObject1 <><><><><><><><><><><><><>', resultObject1);
          // console.log('categoryArrayDetail <><><><><><><><><><><><><>', categoryArrayDetail);

          setSubCategory(subCategoryArray);
          setCategoryDetail(categoryArrayDetail);
          setCategoryDetail2(categoryArrayDetail2);
          setCategoryDetailkeys(resultObject);
          setresultObject1(categoryDetail);
          setcategoryArrayDetailKeys2(categoryArrayDetailKeys2);
        } else {
          console.error('Error fetching store data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    };
    fetchStoreData();
  }, []);

  const handleSubcategoryChange = (event) => {
    const selectedID = event.value;
    setSelectedSubcategoryID(selectedID);
  };

  const handleAddFeature = () => {
    setFeatures([...features, '']); // Add a new empty feature
  };
  const handleDeleteFeature = (index) => {
    const newFeatures = [...features];
    newFeatures.splice(index, 1); // Remove the feature at the specified index
    setFeatures(newFeatures);
  };
  const handleFeatureChange = (index, newValue) => {
    const newFeatures = [...features];
    newFeatures[index] = newValue; // Update the feature at the specified index
    setFeatures(newFeatures);
  };

  // Handle change in selected wallet
  const handleWalletChange = (value) => {
    setSelectedWallet(value);
  };

  // Handle change in agency percentage
  const handleAgencyPercentageChange = (e) => {
    setSelectedAgencyPercentage(e.target.value);
  };

  const optionStorages = [
    { value: '16 GB', label: '16 GB' },
    { value: '32 GB', label: '32 GB' },
    { value: '64 GB', label: '64 GB' },
    { value: '128 GB ', label: '128 GB ' },
    { value: '512 GB', label: '512 GB' },
    { value: '1 TB', label: '1 TB' },
  ];

  const optionColors = [
    { value: 'Red', label: 'Red' },
    { value: 'Blue', label: 'Blue' },
    { value: 'Green', label: 'Green' },
    { value: 'Yellow', label: 'Yellow' },
    { value: 'Purple', label: 'Purple' },
    { value: 'Orange', label: 'Orange' },
    { value: 'Pink', label: 'Pink' },
    { value: 'Brown', label: 'Brown' },
    { value: 'Cyan', label: 'Cyan' },
    { value: 'Magenta', label: 'Magenta' },
    { value: 'Teal', label: 'Teal' },
    { value: 'Indigo', label: 'Indigo' },
    { value: 'Black', label: 'Black' },
    { value: 'White', label: 'White' },
    { value: 'Gold', label: 'Gold' },
    { value: 'Space Gray', label: 'Space Gray' },
    { value: 'Silver', label: 'Silver' },
  ];

  const optionWarranty = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
  ];

  const handleCountryChange = (value) => {
    setSelectedCountry(value.value);
    const countryDetails = value.value;
    const labelArray1 = value.map(option => option.value);
    // console.log('setCountriesNew1', labelArray1);
    setCountriesNew1(labelArray1);
    setCountryChainDetails(countryDetails);
    setCountriesNew(countryDetails);
  };

  const handleColorChange = (value) => {
    // console.log('handleColorChange >>>> ', value.value);
    setSelectedColor(value.value);
  };

  const handleColorChangeNew = (value) => {
    // console.log('handleColorChange >>>> ', value.value);
    setSelectedColorNew(value.value);
  };

  const handleWarrantyChange = (value) => {
    // console.log('handleWarrantyChange', value.value);
    setSelectedWarranty(value.value);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files?.length;
    const file = e.target.files;
    for (let i = 0; i < selectedFiles; ++i) {
      setFile(prevFiles => [...prevFiles, file[i]]);
    }
  };

  const handleFileRemove = (index) => {
    setFile(prevFiles => prevFiles.filter((_, i) => i !== index));
    toast.success('Document Removed', {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleFileRemove1 = async (indexToRemove, image) => {
    const productList = [{
      product_id: id,
      doc_url: indexToRemove,
    }];
    setIsLoading(true);
    try {
      const response = await deleteProductDoc(productList[0]);
      setIsLoading(true);
      if (response.data) {
        setIsLoading(false);
        // console.log('delProductImg', response.data);
        if (response.data.status === 200) {
          toast.success('Document Removed', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setIsLoading(false);
        console.error('Not Found', response.status);
        toast.error(response.status, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching store data:', error);
      toast.error('Error fetching store data:', error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (error.response) {
        setIsLoading(false);
        if (error.response.data.status === 400) {
          console.error('Response Error: ', error.response.data.Error);
          setErrorMessage(error.response.data.Error);
        } else {
          setIsLoading(false);
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      }
    }
    const updatedImages = [...file2];
    updatedImages.splice(image, 1);
    setFile(updatedImages);
  };

  const handleChangeCheckbox = (e) => {
    setIsDeliveryServiceActive(prevValue => !prevValue);
  };

  const removeImage = async (indexToRemove, image) => {
    // console.log('indexToRemove', indexToRemove);
    // console.log('Product', id);
    const productList = [{
      product_id: id,
      image_url: indexToRemove,
    }];
    // console.log('productList', productList);
    try {
      const response = await delProductImg(productList[0]);
      setIsLoading(true);
      if (response.data) {
        setIsLoading(false);
        // console.log('delProductImg', response.data);
        if (response.data.status === 200) {
          toast.success('Image Removed', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        // toast.success('Product Updated Successfully', {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        // history.push('/homeproducts');
        // history.push('/storedetail');          
        // console.log(response.data);
      } else {
        setIsLoading(false);
        console.error('Not Found', response.status);
        toast.error(response.status, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching store data:', error);
      toast.error('Error fetching store data:', error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (error.response) {
        setIsLoading(false);
        if (error.response.data.status === 400) {
          console.error('Response Error: ', error.response.data.Error);
          setErrorMessage(error.response.data.Error);
        } else {
          setIsLoading(false);
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      }
    }
    const updatedImages = [...selectedImages1];
    updatedImages.splice(image, 1);
    setSelectedImages1(updatedImages);
  };

  function removeImage2(indexToRemove) {
    const updatedImages = [...selectedImages3];
    updatedImages.splice(indexToRemove, 1);
    setSelectedImages3(updatedImages);

    const updatedImages2 = [...selectedImages2];
    updatedImages2.splice(indexToRemove, 1);
    setSelectedImages2(updatedImages2);
    toast.success('Image Removed', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  const handleDeliveryChargesChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0) {
      setShowError4(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError4(false);
      }, 5000);
    } else {
      setShowError4(false);
      setDeliveryChargesNew(newValue);
    }
  };

  const handleImageUpload = (e) => {
    const selectedImagesArray = e.target.files;
    // setSelectedImages2(prevImages => [...prevImages, ...selectedImagesArray]);
    const selectedImage = e.target.files[0];
    setImage1(URL.createObjectURL(selectedImage));
    setShow1(true);
  };

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw?.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  };

  const handleCrop = () => {
    setShow1(false);
    setIsLoading(true);
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      const dataUrl = croppedCanvas.toDataURL();

      // Create a Blob from the data URL
      const blob = dataURLtoBlob(dataUrl);
      const croppedFile = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });

      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);

      // Check if the Blob URL is not already in the array before pushing
      if (!selectedImages1.includes(blobUrl)) {
        // Update the state with the new Blob URL
        // setSelectedImages(Array.from(croppedFile));
        setSelectedImages2(prevImages => [...prevImages, croppedFile]);
        setSelectedImages3(prevImages => [...prevImages, blobUrl]);
      }
      setIsLoading(false);
    }
  };

  const handleCreateProduct = async (values) => {
    const countryDetailsVal = selectedCountry;
    const colorDetailsVal = selectedColor;
    const featuresVal = JSON.stringify(features);

    //   const productList = [{
    //     updateObject: {
    //       pickupAddress: values.pickupAddress,
    //       warrantyInMonths: values.warrantyInMonths,
    //       title: values.title,
    //       brand: values.brand,
    //       deliveryCharges: values.deliveryCharges,
    //       product_sizes_quantity: arrayOfArraysNew,  
    //       store_id: StoreID,
    //       description: values.description,
    //       features: featuresVal,
    //       subcategory_id: selectedSubcategoryID,
    //       product_condition: SelectedCondition,
    //     },
    //     productId: id,
    //   },
    // ];


    const formData = new FormData();
    formData.append('pickupAddress', values.pickupAddress);
    formData.append('warrantyInMonths', SelectedWarranty);
    formData.append('title', values.title);
    formData.append('brand', values.brand);
    formData.append('deliveryCharges', setDeliveryChargesvalNew);
    formData.append('product_sizes_quantity', JSON.stringify(arrayOfArraysNew));
    formData.append('description', values.description);
    formData.append('features', featuresVal);
    formData.append('subcategory_id', selectedSubcategoryID);
    formData.append('product_condition', SelectedCondition);
    formData.append('custom_offer_status', offerStatus);
    formData.append('productId', id);
    formData.append('seller_wallet', selectedWallet.address);
    formData.append('agency_percentage', selectedAgencyPercentage);
    formData.append('store_type', selectedType);
    // formData.append('File2', file2);

    // eslint-disable-next-line no-restricted-syntax
    // console.log('selectedImages1', selectedImages1);
    // console.log('selectedImages2', selectedImages2);
    const concatenatedArray = selectedImages1.concat(selectedImages2);
    // console.log('concatenatedArray', concatenatedArray);

    // eslint-disable-next-line no-restricted-syntax
    for (const file1 of file2) {
      formData.append('File2', file1);
    }
    for (const file of selectedImages2) {
      formData.append('File', file);
    }

    // eslint-disable-next-line no-restricted-syntax
    // for (const file2 of selectedImages2) {
    //   formData.append('File', file2);
    // }

    // console.log('Update Form Data', formData);
    setIsLoading(true);
    try {
      const response = await updateProduct(formData);
      setIsLoading(true);
      if (response.data) {
        setIsLoading(false);
        toast.success('Product Updated Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        // history.push('/homeproducts');
        history.push('/storedetail');
        // console.log(response.data);
      } else {
        setIsLoading(false);
        console.error('Not Found', response.status);
        toast.error(response.status, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching store data:', error);
      toast.error('Error fetching store data:', error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (error.response) {
        setIsLoading(false);
        if (error.response.data.status === 400) {
          console.error('Response Error: ', error.response.data.Error);
          setErrorMessage(error.response.data.Error);
        } else {
          setIsLoading(false);
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      }
    }
  };
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel lg={12} title="Edit Product" closeBtn minusBtn refreshLoad>
        <Formik
          enableReinitialize
          initialValues={{
            title: products.title,
            brand: products.brand,
            description: products.description,
            features: products.features,
            pickupAddress: products.pickupAddress,
            warrantyInMonths: products.warrantyInMonths,
            deliveryCharges: products.deliveryCharges,
            allowedCountries: CountriesNew,
            File: '',
            file2: '',
            product_sizes_quantity: '',
            isDeliveryServiceActive,
            availability_status: products.availability_status,
            discount: products.discount,
          }}
          onSubmit={(values) => {
            handleCreateProduct(values);
            // console.log('values'); 
            // console.log(values); 
          }}
          validate={(values) => {
            const errors = {};
            // console.log('values second'); 
            // console.log(values);
            // console.log(selectedImages2.length); 
            if (!values.title) {
              errors.title = 'Title is Required!';
            }
            if (!values.brand) {
              errors.brand = 'Brand is Required!';
            }
            if (!values.description) {
              errors.description = 'Description is Required!';
            }
            if (features?.length === 0) {
              errors.features = 'Features is Required!';
            }
            if (!values.pickupAddress) {
              errors.pickupAddress = 'Pickup Address is Required!';
            }
            if (arrayOfArraysNew?.length === 0) {
              errors.product_sizes_quantity = 'You Need to add Product Specifications';
            }
            if (selectedImages2?.length === 0 && selectedImages1?.length === 0) {
              errors.File = 'Attachment is Required!';
            }
            if (file2 === null && isProductDocument === null) {
              // console.log('Fileeeeeeeeeeeee Error');
              errors.file2 = 'Product Document is Required!';
            }
            // if (!values.warrantyInMonths) {
            //   errors.warrantyInMonths = 'Warranty is Required!';
            // }
            return errors;
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleSubmit, setFieldValue,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="col-md-12">
                    {showAlert && (
                      <div className="alert alert-danger text-center" role="alert">
                        {errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  {selectedImages1.map((index, image) => (
                    <div className="col-lg-2 col-md-4 col-sm-6 text-center my-2">
                      <button
                        className="btn btn-danger btn-sm py-0 px-1 mb-3 shadow-none text-capitalize"
                        type="button"
                        onClick={() => removeImage(index, image)}
                      >
                        Remove
                      </button>
                      <img
                        src={index}
                        alt={`Uploaded ${index + 1}`}
                        className="uploaded-image createImg mx-2"
                      />
                    </div>
                  ))}
                  {selectedImages3.map((index, image) => (
                    <div className="col-lg-2 col-md-4 col-sm-6 text-center my-2">
                      <span>
                        <button
                          className="btn btn-danger btn-sm py-0 px-1 mb-3 shadow-none text-capitalize"
                          type="button"
                          onClick={() => removeImage2(image)}
                        > Remove
                        </button>
                        <img
                          key={image.id}
                          // src={URL.createObjectURL(index)}
                          src={index}
                          alt={`Uploaded ${index + 1}`}
                          className="uploaded-image createImg mx-2"
                        />
                      </span>
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="title" style={{ fontSize: 'small' }} className="form-label text-white"> Title</label>
                      <div>
                        <ChatSearchInput
                          type="text"
                          value={values.title}
                          name="title"
                          id="title"
                          placeholder="Enter Title"
                          onChange={handleChange}
                          className={`form-control mt-2 ${errors.title && touched.title && 'error'}`}
                        />
                        {errors.title && touched.title && (
                          <Error className="input feedback">{errors.title}</Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-6 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="brand" style={{ fontSize: 'small' }} className="form-label text-white">Brand </label>
                      <div>
                        <ChatSearchInput
                          type="text"
                          value={values.brand}
                          name="brand"
                          id="brand"
                          placeholder="Enter Brand"
                          onChange={handleChange}
                          className={`form-control mt-2 ${errors.brand && touched.brand && 'error'}`}
                        />
                        {errors.brand && touched.brand && (
                          <Error className="input feedback">{errors.brand}</Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <DropdownDiv className="">
                        <label htmlFor="subcategory_id" style={{ fontSize: 'small' }} className="form-label text-white">Sub Category *</label>
                        <CustomSelect
                          name="subcategory_id"
                          id="subcategory_id"
                          className="w-25"
                          value={selectedSubcategoryID}
                          options={subCategory}
                          onChange={handleSubcategoryChange}
                        />
                        {errors.subcategory_id && touched.subcategory_id && (
                          <Error className="input feedback">{errors.subcategory_id}</Error>
                        )}
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-8 col-sm-12">
                    <InputDiv>
                      <label htmlFor="description" style={{ fontSize: 'small' }} className="form-label text-white">Description </label>
                      <div>
                        <ChatSearchInput
                          type="text"
                          value={values.description}
                          name="description"
                          id="description"
                          placeholder="Enter Description"
                          onChange={handleChange}
                          className={`form-control ${errors.description && touched.description && 'error'}`}
                        />
                        {errors.description && touched.description && (
                          <Error className="input feedback">{errors.description}</Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="pickupAddress" style={{ fontSize: 'small' }} className="form-label text-white">Pickup Address </label>
                      <div>
                        <ChatSearchInput
                          type="text"
                          value={values.pickupAddress}
                          name="pickupAddress"
                          id="pickupAddress"
                          placeholder="Enter Pickup Address"
                          onChange={handleChange}
                          className={`form-control ${errors.pickupAddress && touched.pickupAddress && 'error'}`}
                        />
                        {errors.pickupAddress && touched.pickupAddress && (
                          <Error className="input feedback">{errors.pickupAddress}</Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <DropdownDiv className="">
                        <label htmlFor="product_condition" style={{ fontSize: 'small' }} className="form-label text-white">Condition</label>
                        <CustomSelect
                          name="product_condition"
                          id="product_condition"
                          className="w-25"
                          value={SelectedCondition}
                          options={productCondition}
                          onChange={handleConditionChange}
                        />
                        {errors.product_condition && touched.product_condition && (
                          <Error className="input feedback">{errors.product_condition}</Error>
                        )}
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                  {true && (
                    <div className="col-md-12 col-lg-4 col-sm-12">
                      <InputDiv>
                        <label htmlFor="deliveryCharges" style={{ fontSize: 'small' }} className="form-label text-white">
                          Delivery Charges
                        </label>
                        <div>
                          <ChatSearchInput
                            type="number"
                            value={setDeliveryChargesvalNew}
                            name="deliveryCharges"
                            id="deliveryCharges"
                            placeholder="Enter Delivery Charges"
                            onChange={handleDeliveryChargesChange}
                            min={0}
                            className={`form-control mt-2 ${errors.deliveryCharges && touched.deliveryCharges && 'error'
                              }`}
                          />
                          {showError4 && <Error className="input feedback">Delivery Charges must be 1 or greater</Error>}
                        </div>
                      </InputDiv>
                    </div>
                  )}
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <DropdownDiv className="">
                        <label htmlFor="warrantyInMonths" style={{ fontSize: 'small' }} className="form-label text-white">Warranty In Months</label>
                        <CustomSelect
                          name="warrantyInMonths"
                          id="warrantyInMonths"
                          className="w-25"
                          value={SelectedWarranty}
                          options={optionWarranty}
                          onChange={handleWarrantyChange}
                        />
                        {errors.warrantyInMonths && touched.warrantyInMonths && (
                          <Error className="input feedback">{errors.warrantyInMonths}</Error>
                        )}
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12 mt-2">
                    <InputDiv>
                      <DropdownDiv className="">
                        <label htmlFor="custom_offer_status" style={{ fontSize: 'small' }} className="form-label text-white mt-0"> Custom Offer Status</label>
                        <CustomSelectCustom
                          name="custom_offer_status"
                          id="custom_offer_status"
                          className="w-25"
                          value={offerStatus}
                          options={customOfferStatus}
                          onChange={handleOfferStatus}
                        />

                      </DropdownDiv>
                    </InputDiv>
                  </div>

                  <>
                    {selectedType === 'agency' && (
                      <>
                        <div className="col-md-12 col-lg-4 col-sm-12 mt-2">
                          <div>
                            <label htmlFor="sellerWallet" className="form-label text-white">
                              Seller Wallet
                            </label>
                            <DropdownDiv className="mb-2">
                              <CustomSelect
                                name="sellerWallet"
                                className="w-25"
                                value={selectedWallet.name}
                                onChange={handleWalletChange} // <-- Handle change in selected wallet
                                placeholder={selectedWallet.name}
                                isDisabled
                                // options={sellerWalletOptions}
                              />
                            </DropdownDiv>
                          </div>
                        </div>

                        <div className="col-md-12 col-lg-4 col-sm-12">
                          <InputDiv>
                            <label htmlFor="agencypercentage" style={{ fontSize: 'small' }} className="form-label text-white">
                              Agency Percentage *
                            </label>
                            <div>
                              <ChatSearchInput
                                type="number"
                                value={selectedAgencyPercentage}
                                onChange={handleAgencyPercentageChange} // <-- Handle change in agency percentage
                                name="agencypercentage"
                                id="agencypercentage"
                                placeholder="Enter Agency Percentage"
                              />
                              {showError4 && <Error className="input feedback">Delivery Charges must be 1 or greater</Error>}
                            </div>
                          </InputDiv>
                        </div>
                      </>
                    )}
                  </>

                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="File" style={{ fontSize: 'small' }} className="form-label text-white mt-2">Product Images *</label>
                      <input
                        hidden
                        type="file"
                        ref={fileInputRef1}
                        value={values.File}
                        name="File[]"
                        id="File"
                        // multiple
                        onChange={handleImageUpload}
                        className={`form-control ${errors.File && touched.File && 'error'}`}
                      />
                      <Button size="md" onClick={handleButtonClickClick1} className="py-2 btn-block mb-0" variant="outline-success">Upload Images</Button>
                      {errors.File && touched.File && (
                        <Error className="input feedback">{errors.File}</Error>
                      )}
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="File2" style={{ fontSize: 'small' }} className="form-label text-white mt-2">
                        Product Document {isProductDocument?.length} {file2?.length}
                      </label>
                      <input
                        hidden
                        type="file"
                        ref={fileInputRef}
                        className="form-control"
                        onChange={handleFileChange}
                        id="File2"
                        name="File2"
                        accept=".pdf"
                        multiple
                      />
                      <Button size="md" onClick={handleButtonClickClick} className="py-2 btn-block mb-0" variant="outline-success">Upload Document</Button>
                      {/* <ErrorMessage name="file2" component="div" className="invalid-feedback" /> */}
                      {errors.file2 && touched.file2 && (
                        <Error className="input feedback">{errors.file2}</Error>
                      )}
                    </InputDiv>
                  </div>
                </div>
                <div className="row mb-4">
                  {isProductDocument?.length > 0 && (
                    <div className="col-md-12 col-lg-4 col-sm-12">
                      <label htmlFor="features" style={{ fontSize: 'small' }} className="form-label text-white mt-2">Documents </label>
                      <br />
                      {isProductDocument.map((file, index) => (
                        <div className="py-0 my-1" style={{ border: '1px solid white', borderRadius: '4px' }}>
                          <label htmlFor="features" style={{ color: '#bababa' }} className="form-label mt-1 mx-2">Uploaded Document {index + 1}</label>
                          <i className="fa fa-trash-o float-right mr-2 mt-2" style={{ fontSize: '22px', color: 'rgb(255, 72, 97)', cursor: 'pointer' }} />
                        </div>
                      ))}
                      {file2.map((file1, index) => (
                        <div className="py-0 my-1" style={{ border: '1px solid white', borderRadius: '4px' }}>
                          <label htmlFor="features" style={{ color: '#bababa' }} className="form-label mt-1 mx-2">{file1.name && file1.name.length > 20 ? `${file1.name.slice(0, 20)}...` : file1.name}</label>
                          <i className="fa fa-trash-o float-right mr-2 mt-2" onClick={() => handleFileRemove(index)} style={{ fontSize: '22px', color: 'rgb(255, 72, 97)', cursor: 'pointer' }} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="row mb-4">
                  <div className="col-md-12 col-lg-8 col-sm-12 mt-3">
                    <label htmlFor="features" className="form-label text-white">Features </label>
                    {/* <button type="button" className="btn btn-success float-right" onClick={handleAddFeature}><i className="fa fa-plus" /> </button> */}
                    <button type="button" style={{ background: '#4bdfb3' }} className="btn btn-success btn-sm float-right shadow-none text-capitalize" onClick={handleAddFeature}><i className="fa fa-plus" /> </button>
                    {/* <hr style={{ borderTop: '2px solid white' }} /> */}
                    {features.map((value, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index} className="d-flex my-1">
                        <ChatSearchInput
                          type="text"
                          value={value}
                          name="features[]"
                          className="form-control"
                          onChange={e => handleFeatureChange(index, e.target.value)}
                        />
                        {/* <button type="button" className={`btn btn-danger ml-1 ${index === 0 ? 'd-none' : ''}`} onClick={() => handleDeleteFeature(index)}><i className="fa fa-minus" /> </button> */}
                        <button type="button" className="btn btn-danger btn-sm shadow-none text-capitalize ml-2" onClick={() => handleDeleteFeature(index)}><i className="fa fa-minus" /> </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="row mb-4">
                  {CategoryDetail.map((field, index) => (
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      {
                        field.inputType === 'dropdown' ? (
                          <InputDiv>
                            <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                              <label htmlFor="color" style={{ fontSize: 'small' }} className="form-label text-white mb-2">{field.label}</label>
                              <CustomSelectCustom
                                className="w-25 px-0"
                                value={
                                  // eslint-disable-next-line no-nested-ternary
                                  field.value === 'engine_type'
                                    ? SelectedEngineType
                                    // eslint-disable-next-line no-nested-ternary
                                    : field.value === 'color'
                                      ? selectedColor
                                      : field.value === 'transmission'
                                        ? selectedTransmission
                                        : field.value === 'storage'
                                          ? SelectedStorage
                                          : field.value === 'registration'
                                            ? SelectedRegistration
                                            : field.value === 'region'
                                              ? SelectedRegion
                                              : field.value === 'city'
                                                ? SelectedCity
                                                : field.value === 'property_type'
                                                  ? selectedPropertyType
                                                  : field.value === 'horse_power'
                                                    ? SelectedHorsePower
                                                    : selectedSize
                                }
                                onChange={
                                  // eslint-disable-next-line no-nested-ternary
                                  field.value === 'engine_type'
                                    ? handleEngineTypeChange
                                    : field.value === 'color'
                                      ? handleColorChange
                                      : field.value === 'transmission'
                                        ? handleTransmissionChange
                                        : field.value === 'storage'
                                          ? handleStorageChange
                                          : field.value === 'registration'
                                            ? handleRegistrationChange
                                            : field.value === 'region'
                                              ? handleRegionChange
                                              : field.value === 'city'
                                                ? handleCityChange
                                                : field.value === 'property_type'
                                                  ? handlePropertyTypeChange
                                                  : field.value === 'horse_power'
                                                    ? handlePowerChange
                                                    : handleSizeChange
                                }
                                options={
                                  // eslint-disable-next-line no-nested-ternary
                                  field.value === 'engine_type'
                                    ? engineTypes
                                    : field.value === 'color'
                                      ? optionColors
                                      : field.value === 'transmission'
                                        ? optionTransmission
                                        : field.value === 'storage'
                                          ? optionStorages
                                          : field.value === 'registration'
                                            ? RegistrationArray
                                            : field.value === 'region'
                                              ? RegionArray
                                              : field.value === 'city'
                                                ? CityArray
                                                : field.value === 'property_type'
                                                  ? PropertyTypes
                                                  : field.value === 'horse_power'
                                                    ? optionHoursePower
                                                    : optionSizes
                                }
                              />
                            </DropdownDiv>
                          </InputDiv>
                        ) : (
                          <InputDiv>
                            <label htmlFor="title" style={{ fontSize: 'small' }} className="form-label text-white">{field.label}</label>
                            <div>
                              <ChatSearchInput
                                type={
                                  // eslint-disable-next-line no-nested-ternary
                                  field.value === 'model' ? 'text' : field.value === 'square_size' ? 'text' : field.value === 'horse_power' ? 'text' : 'number'
                                }
                                value={
                                  // eslint-disable-next-line no-nested-ternary
                                  field.value === 'model'
                                    ? SelectedModel
                                    : field.value === 'quantity'
                                      ? SelectedQuantity
                                      : field.value === 'discount'
                                        ? SelectedDiscount
                                        : field.value === 'square_size'
                                          ? SelectedSquareSize
                                          : field.value === 'floor'
                                            ? SelectedFloor
                                            : SelectedPrice
                                }
                                name="price[]"
                                id={`features_${field.id}`}
                                placeholder={
                                  // eslint-disable-next-line no-nested-ternary
                                  field.value === 'model'
                                    ? 'Enter Model'
                                    : field.value === 'quantity'
                                      ? 'Enter Quantity'
                                      : field.value === 'discount'
                                        ? 'Enter Discount'
                                        : field.value === 'square_size'
                                          ? 'Enter Sqaure Size'
                                          : field.value === 'floor'
                                            ? 'Enter Floor'
                                            : 'Enter Price'
                                }
                                onChange={
                                  // eslint-disable-next-line no-nested-ternary
                                  field.value === 'model'
                                    ? handleModelChange
                                    : field.value === 'quantity'
                                      ? handleQuantityChange
                                      : field.value === 'discount'
                                        ? handleDiscountChange
                                        : field.value === 'square_size'
                                          ? handleSquareSizeChange
                                          : field.value === 'floor'
                                            ? handleFloorChange
                                            : handlePriceChange
                                }
                                className={`form-control mt-2 ${errors.title && touched.title && 'error'}`}
                                min={0}
                              />
                            </div>
                          </InputDiv>
                        )
                      }
                    </div>
                  ))}
                  <div className="col-lg-2 col-md-3 col-sm-6">
                    {/* <button 
                    type="button" 
                    style={{ marginTop: '30px' }} 
                    className="btn btn-success bg-block shadow-none text-capitalize w-100"
                    onClick={handleButtonClick}
                  >Add
                  </button> */}
                    <Button onClick={handleButtonClick} style={{ marginTop: '30px' }} size="md" className="py-2 btn-block mb-0" variant="outline-success">Add</Button>
                    {errors.product_sizes_quantity && touched.product_sizes_quantity && (
                      <Error className="input feedback">{errors.product_sizes_quantity}</Error>
                    )}
                  </div>
                </div>

                <div className="row mb-4" hidden>
                  <div className="col">
                    {fields2.map(field => (
                      <div className="row" key={field.id}>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                          <label htmlFor="color" style={{ fontSize: 'small' }} className="form-label text-white">Size</label>
                          <InputDiv>
                            <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                              <CustomSelect
                                name="size"
                                id="size"
                                className="w-25 px-0"
                                value={selectedSize}
                                onChange={handleSizeChange}
                                options={optionSizes}
                              />
                            </DropdownDiv>
                          </InputDiv>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                          <label htmlFor="color" style={{ fontSize: 'small' }} className="form-label text-white">Color</label>
                          <InputDiv>
                            <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                              <CustomSelect
                                name="size"
                                id="size"
                                className="w-25 px-0"
                                value={selectedColor}
                                onChange={handleColorChange}
                                options={optionColors}
                              />
                            </DropdownDiv>
                          </InputDiv>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                          <label htmlFor="color" style={{ fontSize: 'small' }} className="form-label text-white">Price ({StoreSymbol})</label>
                          <input
                            type="number"
                            value={SelectedPrice}
                            name="price[]"
                            id={`features_${field.id}`}
                            placeholder="Price"
                            className="form-control mr-1"
                            onChange={handlePriceChange}
                            style={{ marginTop: '5px' }}
                            min={0}
                          />
                          {showError3 && <Error className="input feedback">Price must be 1 or greater</Error>}
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                          <label htmlFor="color" style={{ fontSize: 'small' }} className="form-label text-white">Quantity</label>
                          <input
                            type="number"
                            value={SelectedQuantity}
                            name="quantity[]"
                            id={`features_${field.id}`}
                            placeholder="Quantity"
                            onChange={handleQuantityChange}
                            className="form-control mr-1"
                            style={{ marginTop: '5px' }}
                            min={1}
                          />
                          {showError2 && <Error className="input feedback">Quantity must be 1 or greater</Error>}
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                          <label htmlFor="color" style={{ fontSize: 'small' }} className="form-label text-white">Discount %</label>
                          <input
                            type="number"
                            value={SelectedDiscount}
                            name="discount[]"
                            id={`features_${field.id}`}
                            placeholder="Discount %"
                            onChange={handleDiscountChange}
                            className="form-control mr-1"
                            style={{ marginTop: '5px' }}
                            min={0}
                          />
                          {showError && <Error className="input feedback">Discount must be 100 or less</Error>}
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                          <button
                            type="button"
                            style={{ marginTop: '37px' }}
                            className="btn btn-success bg-block shadow-none text-capitalize w-100"
                          >Add
                          </button>
                        </div>
                      </div>
                    ))}
                    {errors.product_sizes_quantity && touched.product_sizes_quantity && (
                      <Error className="input feedback">{errors.product_sizes_quantity}</Error>
                    )}
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div style={{ overflowY: 'auto' }}>
                      <table className="table text-center table-hover">
                        <thead className="thead-light">
                          <tr>
                            {CategoryDetail2.map((field, index) => (
                              <th style={{ fontWeight: 'bold' }}>{field.label}</th>
                            ))}
                            <th style={{ fontWeight: 'bold' }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {arrayOfArraysNew.map((array, index) => (
                            <tr>
                              {getcategoryArrayDetailKeys2.includes('city') && (
                                <td>{array.city}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('color') && (
                                <td>{array.color}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('discount') && (
                                <td>{array.discount} <b>%</b></td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('floor') && (
                                <td>{array.floor}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('horse_power') && (
                                <td>{array.horse_power}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('model') && (
                                <td>{array.model}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('price') && (
                                <td>{array.price} <b>{StoreSymbol}</b></td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('property_type') && (
                                <td>{array.property_type}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('quantity') && (
                                <td>{array.quantity}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('size') && (
                                <td>{array.size}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('square_size') && (
                                <td>{array.square_size}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('region') && (
                                <td>{array.region}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('registration') && (
                                <td>{array.registration}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('storage') && (
                                <td>{array.storage}</td>
                              )}
                              {getcategoryArrayDetailKeys2.includes('transmission') && (
                                <td>{array.transmission}</td>
                              )}
                              <td style={{ verticalAlign: 'middle' }}>
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm mt-0 shadow-none mx-1 text-capitalize rounded-circle"
                                  onClick={() => editDetail(array, index)}
                                >
                                  <i className="fa fa-edit" />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm mt-0 shadow-none text-capitalize rounded-circle"
                                  onClick={() => Removeeee(index)}
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tbody hidden>
                          {arrayOfArraysNew.map((array, index) => (
                            <tr>
                              <td>{array.size}</td>
                              <td>{array.color}</td>
                              <td>{array.price} <b>{StoreSymbol}</b></td>
                              <td>{array.quantity}</td>
                              <td>{array.discount_percentage} %</td>
                              <td style={{ verticalAlign: 'middle' }}>
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm mt-0 mx-1 shadow-none text-capitalize rounded-circle"
                                  onClick={() => Removeeee(order)}
                                >
                                  <i className="fa fa-edit" />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm mt-0 shadow-none text-capitalize rounded-circle"
                                  onClick={() => Removeeee(index)}
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12 col-lg-4 col-sm-12" />
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <div style={{
                      display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}
                    >
                      {/* <FormButton
                      type="submit"
                      variant="primary"
                      className="w-100"
                    >
                      Update 
                    </FormButton> */}
                      <Button type="submit" size="md" className="py-2 btn-block" variant="outline-success">Update</Button>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12" />
                </div>
              </form>
            );
          }}
        </Formik>
      </Panel>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Edit Product Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && (
            <div className="container padding-bottom-3x mb-1 p-0">
              <div className="card mb-3 mt-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
                <div className="card-body">
                  <div className="row mt-0 mb-2">
                    {getcategoryArrayDetailKeys2.includes('city') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="color" style={{ color: '#7b7b7b' }} className="form-label text-dark mt-2 mb-0">City </label>
                        <InputDiv>
                          <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={SelectedCityNew}
                              onChange={handleCityChangeNew}
                              options={CityArray}
                            />
                          </DropdownDiv>
                        </InputDiv>
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('color') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="color" style={{ color: '#7b7b7b' }} className="form-label text-dark mt-2 mb-0">Color</label>
                        <InputDiv>
                          <DropdownDiv className="mb-6" style={{ textTransform: 'Capitalize' }}>
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={selectedColorNew}
                              onChange={handleColorChangeNew}
                              options={optionColors}
                            />
                          </DropdownDiv>
                        </InputDiv>
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('size') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="color" style={{ color: '#7b7b7b' }} className="form-label text-dark mt-2 mb-0">Size</label>
                        <InputDiv>
                          <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={selectedSizeNew}
                              onChange={handleSizeChangeNew}
                              options={optionSizes}
                            />
                          </DropdownDiv>
                        </InputDiv>
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('storage') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="color" style={{ color: '#7b7b7b' }} className="form-label text-dark mt-2 mb-0">Storage </label>
                        <InputDiv>
                          <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={SelectedStorageNew}
                              onChange={handleStorageChangeNew}
                              options={optionStorages}
                            />
                          </DropdownDiv>
                        </InputDiv>
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('horse_power') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="color" style={{ color: '#7b7b7b' }} className="form-label text-dark mt-2 mb-0">Horse Power </label>
                        <InputDiv>
                          <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={SelectedHorsePowerNew}
                              onChange={handlePowerChangeNew}
                              options={optionHoursePower}
                            />
                          </DropdownDiv>
                        </InputDiv>
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('region') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="color" style={{ color: '#7b7b7b' }} className="form-label text-dark mt-2 mb-0">Region </label>
                        <InputDiv>
                          <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={SelectedRegionNew}
                              onChange={handleRegionChangeNew}
                              options={RegionArray}
                            />
                          </DropdownDiv>
                        </InputDiv>
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('registration') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="color" style={{ color: '#7b7b7b' }} className="form-label text-dark mt-2 mb-0">Registration </label>
                        <InputDiv>
                          <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={SelectedRegistrationNew}
                              onChange={handleRegistrationChangeNew}
                              options={RegistrationArray}
                            />
                          </DropdownDiv>
                        </InputDiv>
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('transmission') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="color" style={{ color: '#7b7b7b' }} className="form-label text-dark mt-2 mb-0">Transmission </label>
                        <InputDiv>
                          <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={selectedTransmissionNew}
                              onChange={handleTransmissionChangeNew}
                              options={optionTransmission}
                            />
                          </DropdownDiv>
                        </InputDiv>
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('property_type') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="color" style={{ color: '#7b7b7b' }} className="form-label text-dark mt-2 mb-0">Proprty Type </label>
                        <InputDiv>
                          <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={selectedPropertyTypeNew}
                              onChange={handlePropertyTypeChangeNew}
                              options={PropertyTypes}
                            />
                          </DropdownDiv>
                        </InputDiv>
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('price') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label
                          htmlFor="description"
                          style={{ color: '#7b7b7b' }}
                          className="form-label mb-1 mt-2 text-dark"
                        > Price <small>({StoreSymbol})</small>
                        </label>
                        <input
                          type="number"
                          className="form-control mt-0"
                          style={{ border: '1px solid #808080' }}
                          value={SelectedPriceNew}
                          onChange={handlePriceChangeNew}
                          id="price"
                          name="price"
                        />
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('discount') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label
                          htmlFor="description"
                          style={{ color: '#7b7b7b' }}
                          className="form-label mb-1 mt-2 text-dark"
                        > Discount <small>%</small>
                        </label>
                        <input
                          type="number"
                          className="form-control mt-0"
                          style={{ border: '1px solid #808080' }}
                          value={SelectedDiscountNew}
                          onChange={handleDiscountChangeNew}
                          id="discount"
                          name="discount"
                        />
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('quantity') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label
                          htmlFor="description"
                          style={{ color: '#7b7b7b' }}
                          className="form-label mb-1 mt-2 text-dark"
                        > Quantity
                        </label>
                        <input
                          type="number"
                          className="form-control mt-0"
                          style={{ border: '1px solid #808080' }}
                          value={SelectedQuantityNew}
                          onChange={handleQuantityChangeNew}
                          id="quantity"
                          name="quantity"
                        />
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('model') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label
                          htmlFor="description"
                          style={{ color: '#7b7b7b' }}
                          className="form-label mb-1 mt-2 text-dark"
                        > Model
                        </label>
                        <input
                          type="text"
                          className="form-control mt-0"
                          style={{ border: '1px solid #808080' }}
                          value={SelectedModelNew}
                          onChange={handleModelChangeNew}
                          id="model"
                          name="model"
                        />
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('square_size') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label
                          htmlFor="description"
                          style={{ color: '#7b7b7b' }}
                          className="form-label mb-1 mt-2 text-dark"
                        > Square Size
                        </label>
                        <input
                          type="text"
                          className="form-control mt-0"
                          style={{ border: '1px solid #808080' }}
                          value={SelectedSquareSizeNew}
                          onChange={handleSquareSizeChangeNew}
                          id="price"
                          name="price"
                        />
                      </div>
                    )}
                    {getcategoryArrayDetailKeys2.includes('floor') && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label
                          htmlFor="description"
                          style={{ color: '#7b7b7b' }}
                          className="form-label mb-1 mt-2 text-dark"
                        > Floor
                        </label>
                        <input
                          type="number"
                          className="form-control mt-0"
                          style={{ border: '1px solid #808080' }}
                          value={SelectedFloorNew}
                          onChange={handleFloorChangeNew}
                          id="price"
                          name="price"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            zIndex: '11111',
            position: 'relative'
          }}
        >
          <button
            type="button"
            className="btn btn-danger shadow-none text-capitalize"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-success shadow-none text-capitalize"
            onClick={() => handleButtonClickNew(selectedIndex)}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x mb-1 p-0">
            <div className="card mt-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Cropper
                      src={image1}
                      aspectRatio={12 / 12}
                      guides
                      // crop={e => console.log(e.detail)}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                    />
                    <Button onClick={handleCrop} size="md" className="py-2 btn-block mt-2" variant="success">Crop Image</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateProduct;

const CustomSelectCustom = ({
  isLoading,
  onChange,
  options,
  value,
  name,
  onClick,
  handleBlur,
  isDisabled,
  isSearchable,
  onInputChange,
  placeholder,
}) => {
  const style = {
    option: (provided, state) => {
      const styles = {
        ...provided,
        cursor: 'pointer',
      };

      if (state.isSelected) {
        styles.color = '#fff';
        styles.backgroundColor = '#4ce1b6';
      } else if (state.isDisabled) {
        styles.color = '#A9A9A9';
        styles.backgroundColor = '#857f7f';
      } else {
        styles.color = 'black';
        styles.backgroundColor = '#fff';
      }

      styles['&:hover'] = {
        backgroundColor: '#4ce1b6',
      };

      return styles;
    },
    control: base => ({
      ...base,
      width: '100%',
      border: '1px solid #b1b1b1 !important',
      backgroundColor: 'transparent !important',
      '&:hover': {
        border: '1px solid #4ce1b6 !important',
        backgroundColor: 'transparent !important',
      },
      '.isDisabled': {
        border: '1px solid #4ce1b6 !important',
        backgroundColor: 'transparent !important',
      },
    }),
    singleValue: (provided, state) => {
      const styles = {
        ...provided,
        color: '#b1b1b1', // Change this to the color you want
      };

      return styles;
    },

  };
  // eslint-disable-next-line max-len
  const defaultValue = (selectOptions, selectValue) => (selectOptions ? selectOptions?.find(option => option.value === selectValue) : null);
  return (
    <Select
      key={`my_unique_select_key__${value}`}
      isLoading={isLoading}
      value={defaultValue(options, value) || ''}
      // eslint-disable-next-line no-shadow
      onChange={onChange}
      name={name}
      options={options}
      className="selectDropdpenReact"
      onClick={onClick}
      width="100%"
      styles={style}
      minMenuHeight={100}
      maxMenuHeight={150}
      menuPlacement="bottom"
      onBlur={handleBlur}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      onInputChange={onInputChange}
      placeholder={placeholder}
    />
  );
};

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const FormButton = styled(Button)`
 color:"white";
 mergin-top:1rem;
 `;
const Error = styled.div`
 
 color: #e66e6e;
 padding: 2px 0px;
 font-size: 12px;
 cursor:none;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
