/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Ring } from '@uiball/loaders';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import makeAnimated from 'react-select/animated';
import { Field, Formik } from 'formik';
import Panel from '@/shared/components/Panel';
import { Button } from '../../../shared/components/Button';
import { CustomSelect, CustomMultiSelect } from '../../../shared/components/CustomSelect';
import {
  getMyStore, editMyStore, getCategory, getApprovers,
} from '../../../utils/services/user.service'; // Import API functions
import '../style/editstore.css';
import getCountry from '../createstore/country.json';
import WebCam from '../../../shared/components/WebCam/WebCam';

const EditStore = () => {
  const { storeId } = useParams();
  const [storeData, setStoreData] = useState({});
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedType, setSelectedType] = useState("")
  const [selectedSubcategoryID, setSelectedSubcategoryID] = useState('');
  const [getApproverID, setApproverID] = useState('');
  const [Approvers, setApprovers] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [deliveryAvailableCountries, setDeliveryAvailableCountries] = useState([]);
  const [getFieldCountries, setFieldCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getFile, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [formDataa, setFormDataa] = useState('');

  const [showWebCam, setShowWebCap] = useState(false);

  const [capturedImage, setCapturedImage] = useState(null);



  const [imageSelfie, setImageSelfie] = useState(null);

  const fileInputRef2 = useRef(null);
  const fileSelfieUpload = useRef(null);




  function base64ToFile(base64String, filename) {
    if (
      filename && base64String
    ) {
      // Split the base64 string to get the content type and the data
      const parts = base64String.split(';base64,');
      const contentType = parts[0]?.split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw?.length;
      const uint8Array = new Uint8Array(rawLength);

      // Convert the raw binary data to Uint8Array
      for (let i = 0; i < rawLength; ++i) {
        uint8Array[i] = raw?.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: contentType });

      // Create a File object from the Blob
      return new File([blob], filename, { type: contentType });
    }
  }
  const capturedImageFile = base64ToFile(capturedImage, "captured_image.png");


  const handleCaptureImage = (imageSrc) => {
    // Handle the captured image here (e.g., upload it to a server)
    setCapturedImage(imageSrc);
    setShowWebCap(false); // Hide the webcam modal after capturing the image
  };
  const handleButtonSelfieClick = () => {
    fileSelfieUpload.current.click();
  };
  const handleSelfieUpload = (e) => {
    const selectedImage = e.target.files[0];
    setImageSelfie(selectedImage);
  };
  const customStyles = {
    option: provided => ({
      ...provided,
      textTransform: 'capitalize', // Apply text-transform: capitalize;
    }),
  };
  let subCategoryArray = [];
  let subCategoryArray2 = [];
  // const [formData, setFormData] = useState({
  //   storeName: '',
  //   storeAddress: '',
  //   storeDescription: '',
  // });

  const animatedComponents = makeAnimated();

  const handleSubcategoryChange = (event) => {
    const selectedID = event.value;
    setSelectedSubcategoryID(selectedID);
  };

  const handleApproverChange = (event) => {
    const selectedID = event.value;
    setApproverID(selectedID);
  };

  const handleButtonClickClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleRemoveClick = () => {
    setFile(null);
    const fileInput = document.getElementById('File2');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const history = useHistory();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategory();
        if (response.data.status) {
          const categoryData = response.data.category;
          // console.log('categoryData >>>>>>>>>>>>>>>>> ', categoryData);
          setCategories(categoryData);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          subCategoryArray = categoryData.map(data => ({
            label: data.category_name,
            // eslint-disable-next-line no-underscore-dangle
            value: data._id,
          }));
          subCategoryArray.sort((a, b) => a.label.localeCompare(b.label));
          // console.log('subCategoryArray >>>>>>>>>>>>>>>>> ', subCategoryArray);
          setSubCategory(subCategoryArray);
        } else {
          console.error('Error fetching categories:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchApproverData = async () => {
      try {
        const response = await getApprovers(); // Replace with your API call for approvers
        if (response.data && response.data.user.length > 0) {
          const approverInfo = response.data.user; // Extract the first approver
          // console.log('approverInfo', approverInfo);
          approverInfo.sort((a, b) => a.name.localeCompare(b.name));
          // console.log('approverInfo a', approverInfo);
          setApprovers(approverInfo);
        } else {
          console.error('Error fetching approver data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching approver data:', error);
      }
    };

    const fetchStoreAndFillForm = async () => {
      try {
        const response = await getMyStore(storeId);
        // console.log('Response from getMyStore:', response);
        if (response.data) {
          setStoreData(response.data);
          setFormDataa(response.data.result[0]);
          setSelectedSubcategoryID(response.data.result[0].store_category_id);
          setApproverID(response.data.result[0].approver_wallet_address);
          setSelectedType(response.data.result[0].store_type);
          setFile(response.data.result[0].store_document);
          const responseData = response.data.result[0];
          const deliveryCountries = responseData.delivery_available_countries.map(country => country);
          // console.log('deliveryCountriesssssssssssssssssssss', deliveryCountries);    
          // console.log('Get data ', response.data.result[0]);  
          // setDeliveryAvailableCountries(responseData.delivery_available_countries[0]);

          // console.log('getCountry', getCountry);    
          const targetValues = responseData.delivery_available_countries;
          setFieldCountries(targetValues);
          // console.log('targetValues 1', targetValues);  

          // eslint-disable-next-line react-hooks/exhaustive-deps
          subCategoryArray2 = targetValues.map(data => ({
            label: data,
            // eslint-disable-next-line no-underscore-dangle
            value: data,
          }));

          // console.log('targetValues 2', subCategoryArray2);  
          setSelectedCountries(subCategoryArray2);
          const indices = getCountry.reduce((accumulator, currentValue, currentIndex) => {
            if (targetValues.includes(currentValue)) {
              accumulator.push(currentIndex);
            }
            return accumulator;
          }, []);

          // console.log('indicesindicesindicesindicesindicesindicesindices', indices);

          // console.log('response.data.result[0]', response.data.result[0]);
        } else {
          console.error('Error fetching store details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching store details:', error);
      }
    };

    fetchApproverData();
    fetchCategories();
    fetchStoreAndFillForm();
  }, [storeId]);

  const approverOptions = Approvers.map(approver => ({
    value: approver.address,
    label: approver.name,
  }));

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   // setFormData(prevFormData => ({
  //   //   ...prevFormData,
  //   //   [name]: value,
  //   // }));
  // };
  const handleEditStore = async (values) => {
    // console.log('handleEditStore');
    // event.preventDefault();
    // Create the payload object from formData
    // const payload = {
    //   storeName: formData.storeName,
    //   store_category_id: selectedSubcategoryID,
    //   File2: null,
    //   approver_wallet_address: getApproverID,
    //   storeAddress: formData.storeAddress,
    //   storeDescription: formData.storeDescription,
    //   delivery_available_countries: JSON.stringify(getFieldCountries),
    //   storeId,
    //   // Add more fields as needed
    // };
    const formData = new FormData();
    formData.append('storeName', document.getElementById('storeName').value);
    formData.append('store_category_id', selectedSubcategoryID);
    formData.append('approver_wallet_address', getApproverID);
    formData.append('storeAddress', document.getElementById('storeAddress').value);
    formData.append('storeDescription', document.getElementById('storeDescription').value);
    formData.append('delivery_available_countries', JSON.stringify(getFieldCountries));
    formData.append('storeId', storeId);
    formData.append('File', getFile);
    // formData.append('File', capturedImageFile || imageSelfie);

    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await editMyStore(formData);
      // console.log('Response after edit:', response);

      // Check if the response is successful before redirecting
      if (response.status === 200) {
        setIsLoading(false);
        // console.log('Store details updated successfully.');
        toast.success('Store details updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        // Redirect to the detail page
        history.push(`/storedetail/${storeId}`);
      } else if (response.status === 404) {
        // console.log('Store not found or update failed.');
        toast.error('Store not found or update failed', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        // console.log('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error editing store details:', error);
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel lg={12} title="" closeBtn minusBtn refreshLoad>
        <Formik
          enableReinitialize
          initialValues={{
            storeName: formDataa.storeName,
            store_category_id: formDataa.store_category_id,
            approver_wallet_address: formDataa.description,
            storeAddress: formDataa.storeAddress,
            storeDescription: formDataa.storeDescription,
            delivery_available_countries: selectedCountries,
            file: getFile,
          }}
          onSubmit={(values) => {
            handleEditStore(values);
            // console.log('handleEditStore handleEditStore'); 
            // console.log(values);           
          }}
          validate={(values) => {
            const errors = {};
            // console.log('handleEditStore handleEditStore values'); 
            // console.log(values); 
            // console.log('values');           
            // console.log(getFile);   
            // console.log('values'); 
            if (!values.storeName) {
              errors.storeName = 'Store Name is Required!';
            }
            if (!values.storeAddress) {
              errors.storeAddress = 'Address is Required!';
            }
            // if (getApproverID.length === 0) {
            //   errors.approver_wallet_address = 'Approver is Required!';
            // }
            if (getFieldCountries.length === 0) {
              errors.delivery_available_countries = 'Countries is Required!';
            }
            if (getFile === null) {
              errors.file = 'Store Document is Required!';
            }
            if (selectedSubcategoryID.length === 0) {
              errors.subcategory_id = 'Category is Required!';
            }
            if (!values.storeDescription) {
              errors.storeDescription = 'Description is Required!';
            }
            return errors;
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleInputChange,
              handleSubmit, setFieldValue,
            } = props;

            return (
              <div className="container">
                <div className="row text-center">
                  <h2>Edit Store</h2>
                </div>

                <div className='d-flex flex-column mt-2  '>

                  {imageSelfie && !capturedImage && (
                    <div className="col-lg-6 col-md-12 col-sm-12 text-center">
                      <span className='d-flex flex-column justify-content-center '>
                        <button
                          className="btn btn-danger w-25 btn-sm py-1 px-1 mb-3 shadow-none text-capitalize"
                          type="button"
                          onClick={() => setImageSelfie(null)}
                        >
                          {" "}
                          Remove
                        </button>
                        <img
                          src={URL.createObjectURL(imageSelfie)}
                          className="uploaded-image h-25 w-25  createImg"
                        />
                      </span>
                    </div>
                  )}
                  {capturedImage && (
                    <div className="col-lg-6 col-md-12 col-sm-12 text-center">
                      <span className='d-flex flex-column justify-content-center '>
                        <button
                          className="btn btn-danger w-25 btn-sm py-1 px-1 mb-3 shadow-none text-capitalize"
                          type="button"
                          onClick={() => setCapturedImage(null)}
                        >
                          {" "}
                          Remove
                        </button>
                        <img
                          key={capturedImage}
                          src={capturedImage}
                          className="uploaded-image h-25 w-25  createImg"
                        />
                      </span>
                    </div>
                  )}
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row mt-5">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="storeName" className="form-label text-white">Name</label>
                        <ChatSearchInput
                          type="text"
                          className={`form-control ${errors.storeName && touched.storeName && 'error'}`}
                          id="storeName"
                          name="storeName"
                          value={values.storeName}
                          onChange={handleChange}
                        />
                        {errors.storeName && touched.storeName && (
                          <Error className="input feedback">{errors.storeName}</Error>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="storeAddress" className="form-label text-white">Address</label>
                        <ChatSearchInput
                          type="text"
                          className={`form-control ${errors.storeAddress && touched.storeAddress && 'error'}`}
                          id="storeAddress"
                          name="storeAddress"
                          value={values.storeAddress}
                          onChange={handleChange}
                        />
                        {errors.storeAddress && touched.storeAddress && (
                          <Error className="input feedback">{errors.storeAddress}</Error>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 col-sm-12">
                      <InputDiv>
                        <DropdownDiv className="">
                          <label htmlFor="subcategory_id" className="form-label text-white">Category</label>
                          <CustomSelect
                            name="subcategory_id"
                            id="subcategory_id"
                            // eslint-disable-next-line max-len
                            className={`form-control w-25 ${errors.subcategory_id && touched.subcategory_id && 'error'}`}
                            styles={{
                              menu: provided => ({
                                ...provided,
                                textTransform: 'capitalize',
                              }),
                            }}
                            value={selectedSubcategoryID}
                            options={subCategory}
                            onChange={handleSubcategoryChange}
                          />
                          {errors.subcategory_id && touched.subcategory_id && (
                            <Error className="input feedback">{errors.subcategory_id}</Error>
                          )}
                        </DropdownDiv>
                      </InputDiv>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12">
                      <div className="my-3">
                        <label htmlFor="storeDescription" className="form-label text-white">Description</label>
                        <ChatSearchInput
                          type="text"
                          className={`form-control ${errors.storeDescription && touched.storeDescription && 'error'}`}
                          id="storeDescription"
                          name="storeDescription"
                          value={values.storeDescription}
                          onChange={handleChange}
                        />
                        {errors.storeDescription && touched.storeDescription && (
                          <Error className="input feedback">{errors.storeDescription}</Error>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <InputDiv>
                        <DropdownDiv className="">
                          <label htmlFor="subcategory_id" className="form-label text-white mt-3">Approver</label>
                          <CustomSelect
                            name="subcategory_id"
                            id="subcategory_id"
                            // eslint-disable-next-line max-len
                            className={`form-control w-25`}
                            styles={{
                              menu: provided => ({
                                ...provided,
                                textTransform: 'capitalize',
                              }),
                            }}
                            value={getApproverID}
                            options={approverOptions}
                            onChange={handleApproverChange}
                          />
                          {/* {errors.approver_wallet_address && touched.approver_wallet_address && (
                            <Error className="input feedback">{errors.approver_wallet_address}</Error>
                          )} */}
                        </DropdownDiv>
                      </InputDiv>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12">
                      <label htmlFor="delivery_available_countries" className="form-label text-white mt-3">
                        Delivery Available Countries
                      </label>
                      <Select
                        isMulti
                        closeMenuOnSelect
                        menuPlacement="top"
                        components={animatedComponents}
                        styles={{
                          menu: provided => ({
                            ...provided,
                            maxHeight: '150px',
                            overflow: 'hidden',
                          }),
                        }}
                        value={selectedCountries} // Wrap the object in an array
                        options={getCountry.map(country => ({
                          value: country.value,
                          label: country.label,
                        }))}
                        onChange={(selectedOptions) => {
                          // console.log('selectedOptionsssssssssssss', selectedOptions);
                          setSelectedCountries(selectedOptions);
                          setFieldCountries(selectedOptions.map(option => option.value));
                        }}
                      />
                      {errors.delivery_available_countries && touched.delivery_available_countries && (
                        <Error className="input feedback">{errors.delivery_available_countries}</Error>
                      )}
                    </div>
                    <div className="col-md-12 col-lg-4 col-sm-12 mt-3">
                      <>
                        <label
                          htmlFor="storetype"
                          className="form-label text-white"
                        >
                          Store Type
                        </label>
                        <DropdownDiv className="mb-2">
                          <CustomSelect
                            name="storetype"
                            className="w-25"
                            placeholder={selectedType}
                            value={selectedType}
                            isDisabled
                          />
                        </DropdownDiv>
                      </>
                    </div>
                    <div className=" d-flex flex-column flex-sm-row gap-2 col-lg-4 col-md-6 col-sm-12">
                      <div className=" ">

                        <Button
                          onClick={() => setShowWebCap(true)}
                          size="md"
                          className="py-2 btn-block align-self-center mt-2 "
                          variant="outline-success"
                          disabled={imageSelfie}
                        >
                          Verify Selfie
                        </Button>

                        <div className="d-flex w-100 justify-content-center  align-items-center ">
                          <input
                            hidden
                            type="file"
                            ref={fileSelfieUpload}
                            // value={values.File}
                            name="File[]"
                            id="File"
                            // multiple
                            onChange={handleSelfieUpload}

                            accept="image/*"
                          />
                          <br />
                          <Button
                            onClick={handleButtonSelfieClick}
                            size="md"
                            className="py-2 btn-block mb-0 w-100 align-self-center  "
                            variant="outline-success"
                            disabled={capturedImage}

                          >
                            Upload Selfie
                          </Button>
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="File2" className="form-label text-white mt-3">
                        Store Document
                      </label>
                      <input
                        hidden
                        type="file"
                        ref={fileInputRef}
                        className={`form-control ${errors.file && touched.file && 'error'}`}
                        onChange={handleFileChange}
                        id="File2"
                        name="File2"
                        accept=".pdf"
                      />
                      <Button size="md" onClick={handleButtonClickClick} className="py-2 btn-block mb-0" variant="outline-success">Upload Document</Button>
                      {getFile && (
                        <div className="mt-2">
                          <p className="py-2">1 PDF Uploaded
                            <button
                              className="btn btn-danger float-right py-1 px-2 rounded-circle"
                              onClick={handleRemoveClick}
                              style={{ marginTop: '-2px' }}
                            ><i className="fa fa-trash" />
                            </button>
                          </p>
                        </div>
                      )}
                      {errors.file && touched.file && (
                        <Error className="input feedback">{errors.file}</Error>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                      {/* <button type="submit" className="btn btn-primary w-100">Save Changes</button> */}
                      <Button type="submit" size="md" className="py-2 btn-block" variant="outline-success">Save Changes</Button>
                    </div>
                  </div>
                </form>
              </div>
            );
          }}
        </Formik>

        {showWebCam && (
          <WebCam
            show={showWebCam}
            hide={() => setShowWebCap(false)}
            onCapture={handleCaptureImage}
          />
        )}
      </Panel>
    </>
  );
};

export default EditStore;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 2px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const Error = styled.div`  
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor:none;
  `;

const CustomSelectCustom = ({
  isLoading,
  onChange,
  options,
  value,
  name,
  onClick,
  handleBlur,
  isDisabled,
  isSearchable,
  onInputChange,
  placeholder,
}) => {
  const style = {
    option: (provided, state) => {
      const styles = {
        ...provided,
        cursor: 'pointer',
      };

      if (state.isSelected) {
        styles.color = '#fff';
        styles.backgroundColor = '#4ce1b6';
      } else if (state.isDisabled) {
        styles.color = '#A9A9A9';
        styles.backgroundColor = '#857f7f';
      } else {
        styles.color = 'black';
        styles.backgroundColor = '#fff';
      }

      styles['&:hover'] = {
        backgroundColor: '#4ce1b6',
      };

      return styles;
    },
    control: base => ({
      ...base,
      width: '100%',
      border: '1px solid #b1b1b1 !important',
      backgroundColor: 'transparent !important',
      '&:hover': {
        border: '1px solid #4ce1b6 !important',
        backgroundColor: 'transparent !important',
      },
      '.isDisabled': {
        border: '1px solid #4ce1b6 !important',
        backgroundColor: 'transparent !important',
      },
    }),
    singleValue: (provided, state) => {
      const styles = {
        ...provided,
        color: '#b1b1b1', // Change this to the color you want
      };

      return styles;
    },

  };
  // eslint-disable-next-line max-len
  const defaultValue = (selectOptions, selectValue) => (selectOptions ? selectOptions?.find(option => option.value === selectValue) : null);
  return (
    <Select
      key={`my_unique_select_key__${value}`}
      isLoading={isLoading}
      value={defaultValue(options, value) || ''}
      // eslint-disable-next-line no-shadow
      onChange={onChange}
      name={name}
      options={options}
      className="selectDropdpenReact"
      onClick={onClick}
      width="100%"
      styles={style}
      minMenuHeight={100}
      maxMenuHeight={150}
      menuPlacement="bottom"
      onBlur={handleBlur}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      onInputChange={onInputChange}
      placeholder={placeholder}
    />
  );
};

const ChatSearchInput = styled.input`
    width: 100%;
    height: 100%;
    font-size: 12px;
    transition: all 0.3s;
    background: transparent;
    border: none;
    padding-left: 2px;
    border-bottom: 1px solid #b1b1b1;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    color: #b1b1b1;
  
    &:focus, &:active {
      outline: none;
      box-shadow: none;
      color: #b1b1b1;
      background: transparent;
      border-bottom: 2px solid #47c9a4;
    }
  
    &::-webkit-input-placeholder {
      color: #b1b1b1;
    }
    &::-moz-placeholder {
      color: #b1b1b1;
    }
    &:-moz-placeholder {
      color: #b1b1b1;
    }
    &:-ms-input-placeholder {
      color: #b1b1b1;
    }
  `;
