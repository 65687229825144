/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { Alert } from "react-bootstrap";
import { Formik } from "formik";
import { Ring } from "@uiball/loaders";
import * as Yup from "yup";
import styled from "styled-components";
import Panel from "@/shared/components/Panel";

import { Button } from "../../../../shared/components/Button";
import { CustomMultiSelect } from "../../../../shared/components/CustomSelect";
import Tfaaa from "../../../Account/MyAccount/Tfaaa";
import { API_URL, getApprover, verifyTFAEmail } from "../../../../utils/services/user.service";
import authHeader from "../../../../utils/services/auth-header";
import { getTotalBalance } from "../../../../redux/actions/sidebarActions";
import SignaturePad from "../../../../store/Layout/orders/SignaturePad";
import PdfSignature from "../../../../store/Layout/orders/PdfSignature";

const user = JSON.parse(localStorage.getItem("user"));

const SafeBoxForm = () => {
  const [approverData, setapproverData] = useState([]);
  const [otp, setOtp] = useState(false);
  const [msg, setMsg] = useState("");
  const [name1, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [owner, setOwner] = useState(
    `${user.data.user.firstName} ${user.data.user.lastName}`
  );
  const [note1, setNote] = useState("");
  const [file, setFile] = useState("");
  const [otpVal, setOtpVal] = useState("");
  const [approver, setApprover] = useState("");
  const [tfaUrl, setTfaUrl] = useState(false);
  const [tfaEmail, setTfaEmail] = useState("");
  const [otpbtn, setOtpbtn] = useState(true);
  const [dataFromChild, setDataFromChild] = useState(false);
  const fileInput = React.createRef();
  const [loading, setloading] = useState(false);
  const [statusCode, setstatusCode] = useState(400);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [signatureImage1, setSignatureImage1] = useState(null);
  const [getSubmit, setSubmit] = useState(false);
  const [approveFile2, setFile2] = useState(null);

  const [searchLoad, setsearchLoad] = useState(false);
  const [isTfaVarified, setIsTfaVarified] = useState(false);
  const userSessionData = JSON.parse(localStorage.getItem('user'));
  const [tfaCode, setTfaCode] = useState('');
  
  const handleTfaCode = async () => {
    const requestData = {
      token: tfaCode,
      email: userSessionData.data.user.email,
    };

    try {
      setloading(true);
      const res = await verifyTFAEmail(requestData);
      if (res) {
        setloading(false);
        if (res.data.verified === false) {
          setTfaCode('');
          toast.error('Incorrect TFA Code', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (res.data.verified === true) {
          setIsTfaVarified(true)
          toast.success('Verify TFA Successfully', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setloading(false);
          setTfaCode('');
          toast.error('Verify TFA failed', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {

        setloading(false);
        console.error('verifyTFA failed:', res);
      }
    } catch (error) {
      console.log("🚀 ~ handleTfaCode ~ error:", error)

      setloading(false);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    // getApprover()
    //   .then((res) => {
    //     const arr = res.data.user.map((i) => ({
    //       id: i._id,
    //       address: i.address,
    //       value: i.name,
    //       label: i.name,
    //     }));
    //     setapproverData(arr);
    //   })
    //   .catch((error) => {
    //     setstatusCode(400);
    //     if (error.message === "Network Error") {
    //       setMsg(
    //         `Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`
    //       );
    //     } else {
    //       setMsg(`CatchError:${error}${error?.message}`);
    //     }
    //   });
  }, []);

  const OtpSend = async () =>
    axios
      .post(`${API_URL}safe-box-otp`, "", { headers: authHeader() })
      .then((response) => {
        setloading(false);
        setstatusCode(200);
        setMsg(response.data.message);
        setTimeout(() => {
          setMsg("");
        }, 3000);
        return response;
      })
      .catch((error) => {
        setloading(false);
        setstatusCode(400);
        if (error.message === "Network Error") {
          setMsg(
            `Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`
          );
        } else {
          setMsg(`CatchError:${error}${error?.message}`);
        }
      });

  const SendOtp = () => {
    setloading(true);
    setOtp(true);
    OtpSend();
  };

  function dataURLtoBlob1(dataUrl) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const handleSignatureEnd = (dataUrl) => {
    const blob = dataURLtoBlob1(dataUrl);
    const fileName = "signature.png"; // You can set the desired file name
    const fileType = "image/png"; // Adjust the file type accordingly
    const file11 = new File([blob], fileName, { type: fileType });
    setSignatureImage(dataUrl);
    setSignatureImage1(file11);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleButtonClickClick = () => {
    fileInputRef.current.click();
  };

  const handleFileRemove = () => {
    setFile(null);
    toast.success("File Removed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getPDf1 = (value) => {
    setSubmit(false);
  };

  const getPDf = (value) => {

    setSubmit(true);
    setFile2(value);
    // console.log('setFile2', value);
  };

  const AddSafebox = async () => {
    // console.log('Creating AddSafebox');
    setloading(true);
    setIsLoading(true);
    const formData = new FormData();
    const types = approver.map((o) => o.address);
    const approverscollection = [].concat(...types);
    formData.append("approvers", JSON.stringify(approverscollection));
    formData.append("name", name1);
    formData.append("note", note1);
    formData.append("File", approveFile2);
    formData.append("otp", otpVal);
    

    const requestData = {
      approvers: JSON.stringify(approverscollection),
      name: name1,
      note: note1,
      File: approveFile2,
      otp: otpVal,
    };

    axios
      .post(`${API_URL}safebox`, formData, {
        headers: authHeader(),
      })
      .then(
        (response) => {
          if (
            response.data.status === 200 ||
            response.data.status === "success"
          ) {
            setloading(false);
            setIsLoading(false);
            setstatusCode(200);
            setMsg("Safebox Created Successfully!");
            dispatch(getTotalBalance());
            setTimeout(() => {
              setMsg("");
            }, 4000);
            setOtp(false);
            // resetForm({ values: '' });
            setName("");
            setNote("");
            setFile("");
            setOtpVal("");
            setApprover("");
            setTfaUrl(false);
            setOtpbtn(true);
            return response;
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response.data.status === 400) {
            setloading(false);
            setMsg(error.response.data.Message);
            setTimeout(() => {
              setMsg("");
            }, 5000);
            setstatusCode(400);
            setOtp(false);
            setIsLoading(false);
            // resetForm({ values: '' });
            setName("");
            setNote("");
            setFile("");
            setOtpVal("");
            setApprover("");
            setTfaUrl(false);
            setOtpbtn(true);
          } else {
            setloading(false);
            setMsg(message);
            setIsLoading(false);
            setTimeout(() => {
              setMsg("");
            }, 5000);
            setstatusCode(400);
            setOtp(false);
            // resetForm({ values: '' });
            setName("");
            setNote("");
            setFile("");
            setOtpVal("");
            setApprover("");
            setTfaUrl(false);
            setOtpbtn(true);
          }
        }
      )
      .catch((error) => {
        setloading(false);
        setstatusCode(400);
        setIsLoading(false);
        if (error.message === "Network Error") {
          setMsg(
            `Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`
          );
        } else {
          setMsg(`CatchError:${error}${error?.message}`);
        }
      });
  };

  const handleDataFromChild = (data) => {
    // console.log('Child to Parent');
    if (data === true) {
      AddSafebox();
      setDataFromChild(data);
    }
  };

  const handleInputChange = (name) => {
    // console.log("input is changed");

    setsearchLoad(true);

    if (name.length >= 2) {
      getApprover({ name })
        .then((res) => {
          setsearchLoad(false);

          const arr = res.data.user.map((i) => ({
            id: i._id,
            address: i.address,
            value: i.name,
            label: i.name,
          }));

          setsearchLoad(false);

          // Case-insensitive comparison
          const matchingOptions = arr.filter(
            (option) => option.value.toLowerCase() === name.toLowerCase()
          );

          setapproverData(matchingOptions);
        })
        .catch((error) => {
          setsearchLoad(false);
          setstatusCode(400);

          if (error.message === "Network Error") {
            setMsg(
              `Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`
            );
            // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
          } else {
            setMsg(`CatchError:${error}${error?.message}`);
            toast.error(`CatchError:${error}${error?.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setTimeout(() => {
            setMsg("");
          }, 5000);
        });
      // } else {
      //   setbnNames([]); // Clear options when input length is less than 2
    }

    setsearchLoad(false);
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel lg={12} title="Create Safebox" closeBtn minusBtn refreshLoad>
        <Formik
          initialValues={{
            name: "",
            owner: `${user.data.user.firstName} ${user.data.user.lastName}`,
            approvers: "",
            note: "",
            uploadDoc: "",
            otp: "",
          }}
          onSubmit={(values, { resetForm }) => {
            if (otpVal === "") {
              // console.log('If');
              SendOtp();
            } else if (dataFromChild === false) {
              // console.log('Else If');
              // console.log(user);
              if (user.data.user.TFA === true) {
                // console.log('Else If 1');
                setOtpbtn(false);
                setTfaUrl(true);
                setTfaEmail(user.data.user.email);
              } else {
                // console.log('Else 1');
                AddSafebox();
              }
            } else {
              console.log("Else");
              // AddSafebox(values, { resetForm });
            }
          }}
          validate={(values) => {
            const errors = {};
            const regex = /^[0-9\b]+$/;
            if (!name1) {
              errors.name = "Name is Required!!";
            }
            if (!approver) {
              errors.approvers = "Approvers is Required!!";
            }
            if (!file) {
              errors.uploadDoc = "UploadDoc is Required!!";
            }
            if (file) {
              // console.log('file', file);
              // console.log('approveFile2', approveFile2);
              if (approveFile2 === null) {
                // console.log('approveFile2 yes', approveFile2);
                errors.siganture = "Sigantured Doc is Required!!";
              }
            }
            if (!note1) {
              errors.note = "Note is Required!!";
            }
            if (otp) {
              const otpRegex = /^[0-9\b]+$/;
              if (!otpVal) {
                errors.otp = "Otp is Required!!";
              } else if (!otpRegex.test(otpVal)) {
                errors.otp = "Invalid Otp!! must be in digits";
              }
            }
            return errors;
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <StyledAlert
                  variant={statusCode === 200 ? "success" : "danger"}
                  show={msg !== ""}
                  style={{
                    marginTop: "1rem",
                    width: "100%",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                  onClose={() => {
                    setMsg("");
                  }}
                  dismissible
                >
                  {msg}
                </StyledAlert>
                <div className="row mb-2">
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <LabelDiv>
                        <label htmlFor="email">Safebox Name</label>
                      </LabelDiv>
                      <div style={{ width: "100%" }}>
                        <input
                          style={{ width: "100%" }}
                          name="name"
                          type="text"
                          placeholder="Enter safebox name"
                          value={name1}
                          // onChange={handleChange}
                          onChange={(e) => setName(e.target.value)}
                          onBlur={handleBlur}
                          className={errors.name && touched.name && "error"}
                        />
                        {errors.name && touched.name && (
                          <Error className="input feedback">
                            {errors.name}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <LabelDiv>
                        <label htmlFor="email">Owner</label>
                      </LabelDiv>
                      <div style={{ width: "100%" }}>
                        <input
                          style={{ width: "100%", textTransform: "capitalize" }}
                          name="owner"
                          type="text"
                          disabled
                          placeholder="Enter your owner"
                          value={owner}
                          // onChange={handleChange}
                          onChange={(e) => setOwner(e.target.value)}
                          onBlur={handleBlur}
                          className={errors.owner && touched.owner && "error"}
                        />
                        {errors.owner && touched.owner && (
                          <Error className="input feedback">
                            {errors.owner}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <LabelDiv>
                        {" "}
                        <label htmlFor="email">Note</label>
                      </LabelDiv>
                      <div style={{ width: "100%" }}>
                        <input
                          style={{ width: "100%" }}
                          name="note"
                          type="textarea"
                          placeholder="Enter note"
                          value={note1}
                          // onChange={handleChange}
                          onChange={(e) => setNote(e.target.value)}
                          onBlur={handleBlur}
                          className={errors.note && touched.note && "error"}
                        />

                        {errors.note && touched.note && (
                          <Error className="input feedback">
                            {errors.note}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <SelectDiv>
                      <LabelDiv>
                        <label style={{ color: "white" }} htmlFor="email">
                          Approvers *
                        </label>
                      </LabelDiv>
                      <div style={{ width: "100%" }}>
                        <CustomMultiSelect
                          isMulti
                          name="approvers"
                          value={approver}
                          onChange={(value) => {
                            setApprover(value);
                          }}
                          options={approverData}
                          onInputChange={handleInputChange}
                        />
                        {errors.approvers && touched.approvers && (
                          <Error className="input feedback mt-1">
                            {errors.approvers}
                          </Error>
                        )}
                      </div>
                    </SelectDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <label
                      htmlFor="File2"
                      style={{ fontSize: "small" }}
                      className="form-label text-white"
                    >
                      File
                    </label>
                    <input
                      hidden
                      type="file"
                      ref={fileInputRef}
                      className="form-control"
                      onChange={handleFileChange}
                      id="File2"
                      name="File2"
                      accept=".pdf"
                    />
                    <br />
                    <Button
                      size="md"
                      onClick={handleButtonClickClick}
                      className="py-2 btn-block mb-0 mt-1"
                      variant="outline-success"
                    >
                      Upload File
                    </Button>
                    {/* <ErrorMessage name="File" component="div" className="invalid-feedback" /> */}
                    {errors.File2 && touched.File2 && (
                      <Error className="input feedback">{errors.File2}</Error>
                    )}
                  </div>
                  { userSessionData.data.user.TFA && (
                      <div className=" d-flex col-lg-3 col-md-12 col-sm-12 mt-1 gap-2  justify-content-between ">
                        <InputDiv>
                          <label htmlFor="email" className="text-white mb-3">
                            Verify TFA *
                          </label>
                          <input
                            name="tfa"
                            type="text"
                            placeholder="Enter Code"
                            value={tfaCode}
                            onChange={(e) => {
                              setTfaCode(e.target.value);
                            }}
                          />

                        </InputDiv>

                        <InputDiv>
                          <FormButton
                            variant="outline-white"
                            className="py-2 mt-4 w-75 btn-sm"
                            onClick={handleTfaCode}
                            disabled={isTfaVarified}
                          >
                            {isTfaVarified ? "Verified" : "Verify"}
                          </FormButton>

                        </InputDiv>

                      </div>
                    )}
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    {file && (
                      <>
                        <label
                          htmlFor="features"
                          style={{ fontSize: "small" }}
                          className="form-label text-white"
                        >
                          Uploaded File{" "}
                        </label>
                        <br />
                        <div
                          className="py-0"
                          style={{
                            border: "1px solid white",
                            borderRadius: "4px",
                          }}
                        >
                          <label
                            htmlFor="features"
                            className="form-label text-white mt-1 mx-2"
                          >
                            {file.name}{" "}
                          </label>
                          <button
                            type="button"
                            style={{ marginTop: "2%" }}
                            className="btn btn-danger py-0 float-right mr-1 mb-0"
                            onClick={handleFileRemove}
                          >
                            Remove <i className="fa fa-file-pdf-o" />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <div className="col-md-4 col-lg-4 col-sm-12">
                  <InputDiv>
                    <LabelDiv>  <label htmlFor="email">Upload File</label></LabelDiv>
                    <div style={{ width: '100%' }}>
                      <div style={{ width: '80%' }}>
                        <input
                          style={{ display: 'none' }}
                          type="file"
                          onChange={(event) => {
                          setFile(
                          event.currentTarget.files[0],
                        );
                      }}
                          ref={fileInput}
                          className={errors.uploadDoc && touched.uploadDoc && 'error'}
                        />
                        <FormButton
                          type="button"
                          className="my-0"
                          onClick={() => fileInput.current.click()}
                        >
                          <span
                            className="lnr lnr-plus-circle"
                            style={{ marginRight: '10px' }}
                          />
                          Add Media
                        </FormButton>
                        <small>
                          {file
                        ? file.name
                        : 'No file chosen'}
                        </small>
                      </div>
                  
                      {errors.uploadDoc && touched.uploadDoc && (
                        <Error className="input feedback">{errors.uploadDoc}</Error>
                )}
                    </div>
               
                  </InputDiv>
                </div> */}
                  {file && (
                    <div className="col-md-12 col-lg-4 col-sm-12">
                      <LabelDiv>
                        <label
                          className="mt-3"
                          style={{ color: "white" }}
                          htmlFor="email"
                        >
                          Signature here
                        </label>
                      </LabelDiv>
                      <div
                        style={{
                          border: "2px solid white",
                          borderRadius: "6px",
                          background: "white",
                        }}
                      >
                        <SignaturePad
                          getPDf1={getPDf1}
                          onEnd={handleSignatureEnd}
                        />
                        {signatureImage1 && (
                          <>
                            {" "}
                            <PdfSignature
                              pdfFile={file}
                              signatureImage={signatureImage1}
                              getPDf={getPDf}
                            />
                          </>
                        )}
                      </div>
                      {errors.siganture && (
                        <Error className="input feedback">
                          {errors.siganture}
                        </Error>
                      )}
                    </div>
                  )}
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    {otp && (
                      <InputDiv>
                        <label htmlFor="email" className="mt-3">
                          OTP *
                        </label>
                        <div>
                          <input
                            name="otp"
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Enter your otp"
                            value={otpVal}
                            // onChange={handleChange}
                            onChange={(e) => setOtpVal(e.target.value)}
                            onBlur={handleBlur}
                            className={errors.otp && touched.otp && "error"}
                          />
                          {errors.otp && touched.otp && (
                            <Error className="input feedback">
                              {errors.otp}
                            </Error>
                          )}
                        </div>
                      </InputDiv>
                    )}
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <Tfaaa
                      tfaEmail={tfaEmail}
                      tfaUrl={tfaUrl}
                      onDataFromChild={handleDataFromChild}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  {otpbtn && (
                    <div className="col-md-4 col-lg-4 col-sm-12 mx-auto">
                      {loading ? (
                        <FormButton
                          type="submit"
                          className="w-100 mt-4"
                          variant="outline-success"
                          disabled={otp && otpVal === ""}
                        >
                          Loading....
                        </FormButton>
                      ) : (
                        <FormButton
                          type="submit"
                          className="w-100 mt-4"
                          variant="outline-success"
                          disabled={otp && otpVal === "" || (userSessionData.data.user.TFA && !isTfaVarified)}
                        >
                          Add Safebox
                        </FormButton>
                      )}
                    </div>
                  )}
                </div>

            
              </form>
            );
          }}
        </Formik>
      </Panel>
    </>
  );
};

export default SafeBoxForm;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color: white;
    border-bottom: 1px solid #555555;
  }
  input.disabled {
    cursor: not-allowed !important;
    color: #d9d9d9;
  }
`;
const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;
const SelectDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  font-size: 14px;
`;
const LabelDiv = styled.div`
  width: 100%;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 876px) {
    flex-direction: column;
  }
`;
const FormButton = styled(Button)`
  margin-top: 1rem;
`;
const getMainColor = (color) => {
  switch (color) {
    case "success":
      return "#c1f9c1";
    case "danger":
      return "#f1beb0";
    default:
      return "#f1beb0";
  }
};

const getLightColor = (color) => {
  switch (color) {
    case "success":
      return "#22bb33";
    case "danger":
      return "#ff4861";
    default:
      return "#f88e7d";
  }
};

export const StyledAlert = styled(Alert).withConfig({
  shouldForwardProp: (prop) =>
    !["bordered", "neutral", "colored"].includes(prop),
})`
  background-color: ${(props) => getMainColor(props.variant)};
  border-color: ${(props) => getMainColor(props.variant)};
  color: ${(props) => getLightColor(props.variant)};
`;
