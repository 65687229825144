import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Web3 from "web3";
import { Col, Spinner, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Switch, Stack } from "@mui/material";
import { Legend, Pie, Tooltip } from "recharts";
import { fetchAllCurrencies1 } from "@/redux/actions/FetchCoinLists";
import Panel from "@/shared/components/Panel";
import getTooltipStyles from "@/shared/helpers";
import useMatchMedia from "@/shared/hooks/useMatchMedia";
import { Button } from "@/shared/components/Button";

import {
    colorAdditional,
    colorDarkText,
    scrollbarStyles,
    colorAccent,
} from "@/utils/palette";
import {
    Bullet,
    DashboardChartLegendTable,
    DashboardPieChartContainer,
    DashboardPieChartFlex,
} from "../../BasicDashboardComponents";
import { createSubWallet, getAllWallets, getUserInfo } from "../../../../utils/services/user.service";
import { toast } from "react-toastify";
import { useChainContext } from "../../../context/context";
import numeral from "numeral";


const DashboardCoins = ({ coinsData, title, optionColors, totalBalance }) => {
    const { setSelectedDashboardCoin, selectedDashboardCoin } = useChainContext();


    const [coinsTable, setCoinsTable] = useState(coinsData);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const themeName = useSelector((state) => state.theme.className);
    const isMobile = useMatchMedia(375); // Change the breakpoint value if needed

    const [coordinates, setCoordinates] = useState({ x: 10, y: 10 });
    const tooltipFormatter = (value, name, entry) => {
        return [
            <span
                key="custom-label"
                style={{
                    fontSize: '10px',
                    zIndex: '1111111111',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word'
                }}
            >
                {`${entry?.payload?.symbol} : ${numeral(entry.payload.graphVal).format("0,0.00")}`}
            </span>

        ];
    };
    const onMouseMove = (e) => {
        if (e.tooltipPosition) {
            setCoordinates({
                x: 32,
                y: e.tooltipPosition.y,
            });
        }
    };

    useEffect(() => {
        setCoinsTable(coinsData);
    }, [coinsData]);

    useEffect(() => {
        // Check if all balances are zero
        const allBalancesZero = coinsTable?.every(item => Number(item?.balance) === 0);

        let res;

        if (allBalancesZero) {
            // If all balances are zero, create a single entry with 0.01 value and one color
            res = [{
                fill: optionColors[0]?.value || "#ffff",
                graphVal: 0.01,
                symbol: coinsTable[0]?.symbol || "N/A"
            }];
        } else {
            // Otherwise, map the original data
            res = coinsTable?.map((item, index) => {
                const newVal = Number(item?.balance);
                const priceValue = newVal.toFixed(2) || 0;
                const graphVal = parseFloat(priceValue);

                const fill = optionColors[index]?.value || "#ffff";
                const symbol = item.symbol;
                return { fill, graphVal, symbol };
            });
        }

        setData(res);
    }, [coinsTable]);



    const coinTypeMap = {
        "Onchain Wallet": "onChain_wallet",
        "Virtual Wallet": "internal",
        "OTC Wallet": "otc_wallet",
        "Bank Wallet": "bank_wallet"
    };

    const handleShowDetail = (title) => {

        const selectedCoin = coinTypeMap[title];

        if (selectedCoin) {
            setSelectedDashboardCoin(selectedCoin);
        }
    };

    const [cx, setCx] = useState(window.innerWidth / 2);

    const updateCx = () => {
        setCx(window.innerWidth / 2);
    };

    const [userInfo, setuserInfo] = useState([]);

    const getUsers = () => {
        setIsLoading(true);

        getUserInfo()
            .then((res) => {
                if (res.status === 200) {
                    setuserInfo(res.data.User);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        window.addEventListener('resize', updateCx);
        updateCx(); // initial call to set cx

        return () => {
            window.removeEventListener('resize', updateCx);
        };

    }, []);

    useEffect(() => {
        getUsers()
    }, [])

    const createSubWalletUser = async () => {
        try {
            setIsLoading(true)
            const res = await createSubWallet()
            toast.success(res?.data?.message || res?.data?.Message)
             getUsers()
            setIsLoading(false)

        } catch (error) {
            toast.error(error?.response?.data.Message);
            setIsLoading(false)
        }
    }
    return (
        <Panel
            sm={12}
            xl={3}
            xxl={3}
            title={title}
            isLoading={isLoading}
        // refreshRequest={handleRefresh}
        >
            {isLoading1 ? (
                <Spinner style={{ color: colorAccent }} />
            ) : (
                <Stack display={'flex'} flexDirection={'column'} minHeight={'600px'} justifyContent={'space-between'}>
                    <BalanceTitle>
                        Balance : {numeral(totalBalance || 0).format("0,0.00")} $
                    </BalanceTitle>
                    <div className="d-flex w-100 align-items-center justify-content-center  ">
                        <DashboardCoinsChartContainer width="90%">
                            <DashboardPieChartFlex>
                                <Tooltip
                                    position={coordinates}
                                    {...getTooltipStyles(themeName)}
                                    formatter={tooltipFormatter}
                                />
                                <Pie
                                    data={data}
                                    dataKey="graphVal"
                                    cy={110}
                                    // cx={60}
                                    innerRadius={45}
                                    outerRadius={60}
                                    label
                                    onMouseMove={onMouseMove}
                                    minAngle={30}
                                    width={"50%"}
                                />
                            </DashboardPieChartFlex>
                        </DashboardCoinsChartContainer>
                    </div>

                    <div dir="ltr">
                        <DashboardChartLegendTable>
                            <LegendCol>
                                <LegendColTitle>Coin List</LegendColTitle>
                                <LegendColContent>
                                    {coinsTable.map((entry, index) => (
                                        <li
                                            key={index}
                                            style={{
                                                width: "max-content",
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Bullet color={optionColors[index]?.value} />

                                            {entry?.symbol}
                                        </li>
                                        //  )
                                    ))}
                                </LegendColContent>
                            </LegendCol>

                            <LegendCol>
                                <LegendColTitle>Balance</LegendColTitle>
                                <LegendColContent>
                                    {coinsTable?.map((entry,idx) => (
                                        <li
                                            key={idx}
                                            style={{
                                                width: "max-content",
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                alignItems: "center",
                                            }}
                                        >
                                            {" "}
                                            {/* Assuming entry has an id */}
                                            {numeral(entry.balance).format("0,0.00")}
                                        </li>
                                    ))}
                                </LegendColContent>
                            </LegendCol>
                        </DashboardChartLegendTable>
                    </div>
                    <Button
                        onClick={() => { !userInfo?.secondary_wallet_address && title == "Onchain Wallet" ? createSubWalletUser() : handleShowDetail(title) }}
                        size="md"
                        className="py-2 mt-2 transform-lowercase"
                        variant="outline-primary"

                    >
                        {
                            !userInfo?.secondary_wallet_address && title == "Onchain Wallet" ? "Create Onchain Wallet" : "  Show Details"
                        }

                    </Button>
                </Stack>
            )}
        </Panel>
    );
};
export default DashboardCoins;


const BalanceTitle = styled.h4`
  text-align:left;
  opacity: 0.7;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color:#787985;
  margin-top: 3px;
  margin-left:7px;
  text-transform: uppercase;
`;
const LegendCol = styled("div")`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled("div")`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled("ul")`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;

const LegendSubtext = styled("span")`
  color: ${colorAdditional};
`;
const DashboardCoinsChartContainer = styled(DashboardPieChartContainer)`
  min-height: 260px !important;
  height: 100% !important;

  svg {
    min-height: 260px;
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: 1640px) {
      max-width: 90%;
    }

    @media (max-width: 1199px) {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      height: 80% !important;
    }

    @media (max-width: 400px) {
      height: 60% !important;
    }

    .recharts-pie {
      transform: translateX(-60px);

      @media (max-width: 1919px) {
        transform: translateX(0);
      }
    }
  }

  .recharts-legend-wrapper {
    position: relative !important;
    max-width: 55%;
    width: 100% !important;
    z-index: 5;

    @media (max-width: 1919px) {
      max-width: 50%;
    }
    @media (max-width: 1199px) {
      max-width: 100%;
    }
    @media (max-width: 768px) {
      overflow-x: auto;

      ${scrollbarStyles};
    }
  }
`;
