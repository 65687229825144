/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Panel from '@/shared/components/Panel';
import { Table } from '@/shared/components/TableElements';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Tooltip from '@/shared/components/Tooltip';
import ReactTableBase from '../../../../shared/components/table/ReactTableBase';
import { SidebarLinkIcon } from '../../../Layout/components/sidebar/SidebarLink';
import { CustomSelect, CustomMultiSelect } from '../../../../shared/components/CustomSelect';
import { getStrigaWalletAddress } from '../../../../utils/services/user.service';
const user = JSON.parse(localStorage.getItem('user'));

const header = [
  { accessor: 'id', Header: 'QR Code', disableGlobalFilter: true },
  { accessor: 'address', Header: 'Address' },
  { accessor: 'network', Header: 'Network' },
  // { accessor: 'copy', Header: '' , disableGlobalFilter: true},
  { accessor: 'type', Header: 'Type' },
  { accessor: 'code', Header: 'Code' },

];
const ReceiveTable = () => {
  const optionSymbol12 = [
    { value: 'BTC', label: 'BTC' },
  ];
  const optionSymbolTron = [
    { value: 'ALL', label: 'ALL' },
    { value: 'TRON', label: 'TRON' },
    { value: 'USDT (TRC 20)', label: 'USDT (TRC 20)' },
  ];
  const [colorCopy, setColorCopy] = React.useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState('BTC');
  const [selectedSymbol1, setSelectedSymbol1] = useState('Bitcoin');
  const [getSelectedCoins, setSelectedCoins] = useState(optionSymbol12);
  const [strigaWAlletAddress, setStrigaWalletAddress] = useState(null)

  const [selectedName, setSelectedName] = useState('');
  const getStrigaWallet = async () => {
    // setIsLoading(true)
    const payload = {
      "striga_user_id": user.data.user.striga_user_id
    }
    const res = await getStrigaWalletAddress(payload)
    setStrigaWalletAddress(res?.data)
    // setIsLoading(false)
  }
  useEffect(() => {
    getStrigaWallet()
  }, [selectedSymbol, selectedSymbol1])
  const rows = [
    {
      id: 1,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'USDC',
      code: 'BEP20',
    },
    {
      id: 14,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'EUROC',
      code: 'BEP20',
    },
    {
      id: 15,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'USDT',
      code: 'BEP20',
    },
    {
      id: 16,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'BUSD',
      code: 'BEP20',
    },
    {
      id: 17,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'GBPT',
      code: 'BEP20',
    },
    {
      id: 18,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'OPY',
      code: 'BEP20',
    },
    {
      id: 19,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'FTY',
      code: 'BEP20',
    },
    {
      id: 20,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'JPYC',
      code: 'BEP20',
    },
    {
      id: 21,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'PAX',
      code: 'BEP20',
    },
    {
      id: 2,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'BNB',
      type: 'USDT',
      code: 'BEP20',
    },
    {
      id: 3,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'BNB',
      type: 'PAX',
      code: 'BEP20',
    },
    {
      id: 4,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Ethereum',
      type: 'ETH',
      code: 'Currency',
    },
    {
      id: 13,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Ethereum',
      type: 'USDT',
      code: 'ERC20',
    },
    {
      id: 5,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'BNB',
      code: 'Currency',
    },
    {
      id: 6,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'CTY',
      code: 'BEP20',
    },
    {
      id: 7,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'BNB',
      type: 'GBPT',
      code: 'BEP20',
    },
    {
      id: 8,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'BNB',
      type: 'JYPC',
      code: 'BEP20',
    },
    {
      id: 9,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'BNB',
      type: 'EUROC',
      code: 'ERC20',
    },
    {
      id: 10,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Polygon',
      type: 'MATIC',
      code: 'Currency',
    },
    {
      id: 11,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'BNB',
      type: 'BUSD',
      code: 'BEP20',
    },
    {
      id: 12,
      address: (user && user.data && user.data.user && user.data.user.btcAddress) ? user.data.user.btcAddress : '',
      network: 'Bitcoin',
      type: 'BTC',
      code: 'Currency',
    },
    {
      id: 21,
      address: (user && user.data && user.data.user && user.data.user.address) ? user.data.user.address : '',
      network: 'Smart Chain',
      type: 'MET$',
      code: 'BEP20',
    },
    {
      id: 21,
      address: user?.data?.user?.sol_address
        || '',
      network: 'Solana',
      type: 'SOL',
      code: '--',
    },
    {
      id: 21,
      address: user?.data?.user?.sol_address
        || '', network: 'Solana',
      type: 'WEN',
      code: '--',
    },
    {
      id: 21,
      address: user?.data?.user?.sol_address
        || '', network: 'Solana',
      type: 'LTX',
      code: '--',
    },
    {
      id: 22,
      address: user?.data?.user?.sol_address
        || '', network: 'Solana',
      type: 'SoLpy',
      code: '--',
    },



    {
      id: 28,
      address: (strigaWAlletAddress?.IBAN || ''),
      network: 'Bank Deposit',
      type: 'EUR',
      code: 'BEP20',
    },
    {
      id: 29,
      address: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'USDT',
      code: 'BEP20',
    },
    {
      id: 30,
      address: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'USDC',
      code: 'BEP20',
    },
    {
      id: 31,
      address: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'BNB',
      code: 'BEP20',
    },
    {
      id: 32,
      address: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'ETH',
      code: 'BEP20',
    },
    {
      id: 33,
      address: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'BTC',
      code: 'BEP20',
    },
    {
      id: 34,
      address: user?.data?.user?.trc_address || '',
      network: 'Tron Network',
      type: 'TRON',
      code: 'trc',
    },
    {
      id: 35,
      address: user?.data?.user?.trc_address || '',
      network: 'Tron Network',
      type: 'USDT (TRC 20)',
      code: 'trc',
    },

  ];

  const [getTransactions, setTransactions] = useState(rows);

  const [getTransactions1, setTransactions1] = useState(rows);
  const optionSymbol = [
    { value: 'ALL', label: 'ALL' },
    { value: 'USDC', label: 'USDC' },
    { value: 'USDT', label: 'USDT' },
    { value: 'PAX', label: 'PAX' },
    { value: 'BNB', label: 'BNB' },
    { value: 'CTY', label: 'CTY' },
    { value: 'GBPT', label: 'GBPT' },
    { value: 'JPYC', label: 'JPYC' },
    { value: 'EUROC', label: 'EUROC' },
    { value: 'BUSD', label: 'BUSD' },
    { value: 'OPY', label: 'OPY' },
    { value: 'FTY', label: 'FTY' },
    { value: 'MET$', label: 'MET$' },
  ];
  const optionSymbol11 = [
    { value: 'ETH', label: 'ETH' },
  ];
  const optionSymbolSolana = [
    { value: 'ALL', label: 'ALL' },
    { value: 'SOL', label: 'SOL' },
    { value: 'WEN', label: 'WEN' },
    { value: 'LTX', label: 'LTX' },
    { value: 'SoLpy', label: 'SoLpy' },
  ];
  const optionSymbol13 = [
    { value: 'MATIC', label: 'MATIC' },
  ];
  const optionSymbolStriga = [
    { value: 'All', label: 'All' },
    { value: 'EUR', label: 'EUR' },
    { value: 'USDC', label: 'USDC' },
    { value: 'BTC', label: 'BTC' },
    { value: 'BNB', label: 'BNB' },
    { value: 'ETH', label: 'ETH' },
    { value: 'USDT', label: 'USDT' },
  ];
  const optionSymbol1 = [
    { value: 'Ethereum', label: 'Ethereum' },
    { value: 'Bitcoin', label: 'Bitcoin' },
    { value: 'Polygon', label: 'Polygon' },
    { value: 'Smart Chain', label: 'Smart Chain' },
    { value: 'Solana', label: 'Solana' },
    { value: 'Bank Deposit', label: 'Bank Deposit' },
    { value: 'Tron Network', label: 'Tron Network' },
  ];
  const handleSymbolChange = (value) => {
    // console.log(value.value);
    let completedTransactions;
    if (value.value === 'ALL') {
      completedTransactions = rows;
    } else {
      completedTransactions = rows.filter(transaction => transaction.type === value.value);
    }
    if (selectedName !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.address.toLowerCase().includes(selectedName.toLowerCase()));
    }
    if (selectedSymbol1 !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.network === selectedSymbol1);
    }
    setTransactions(completedTransactions);
    setSelectedSymbol(value.value);
  }

  const handleSymbolChange1 = (value) => {
    let completedTransactions;
    completedTransactions = rows.filter(transaction => transaction.network === value.value);
    if (value.value === 'Smart Chain') {
      setSelectedCoins(optionSymbol);
      setSelectedSymbol('ALL');
      const validValues = ['USDC', 'EUROC', 'USDT', 'BUSD', 'CTY', 'GBPT', 'OPY', 'FTY', 'JPYC', 'PAX', 'BNB', 'OPY', 'FTY', 'MET$'];
      // const validValues = ['USDC'];
      completedTransactions = completedTransactions.filter(transaction => validValues.includes(transaction.type));
    }
    if (value.value === 'Tron Network') {
      setSelectedCoins(optionSymbolTron);
      setSelectedSymbol('ALL');
      const validValues = ["TRON", "USDT (TRC 20)"];
      // const validValues = ['USDC'];
      completedTransactions = completedTransactions.filter(transaction => validValues.includes(transaction.type));
    }
    if (value.value === 'Bitcoin') {
      setSelectedCoins(optionSymbol12);
      setSelectedSymbol('BTC');
      completedTransactions = completedTransactions.filter(transaction => transaction.type === 'BTC');
    }
    if (value.value === 'Bank Deposit') {
      setSelectedCoins(optionSymbolStriga);
      setSelectedSymbol('All');
      const validValues = ["All", 'USDC', 'USDT', 'BNB', "ETH", "EUR", "BTC"];
      // const validValues = ['USDC'];
      completedTransactions = completedTransactions.filter(transaction => validValues.includes(transaction.type));
    }
    if (value.value === 'Polygon') {
      setSelectedCoins(optionSymbol13);
      setSelectedSymbol('MATIC');
      completedTransactions = completedTransactions.filter(transaction => transaction.type === 'MATIC');
    }
    if (value.value === 'Ethereum') {
      setSelectedCoins(optionSymbol11);
      setSelectedSymbol('ETH');
      completedTransactions = completedTransactions.filter(transaction => transaction.type === 'ETH');
    }
    if (value.value === 'Solana') {
      setSelectedCoins(optionSymbolSolana);
      setSelectedSymbol('ALL');
      const validValues = ['SOL', 'WEN', 'LTX', "SoLpy"];

      completedTransactions = completedTransactions.filter(transaction => validValues.includes(transaction.type));
    }
    if (selectedName !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.address.toLowerCase().includes(selectedName.toLowerCase()));
    }
    setTransactions(completedTransactions);
    setSelectedSymbol1(value.value);
  }

  const handleNameChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.address.toLowerCase().includes(partialValue.toLowerCase()));
    if (selectedSymbol !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.type === selectedSymbol);
    }
    if (selectedSymbol1 !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.network === selectedSymbol1);
    }
    setTransactions(completedTransactions);
    setSelectedName(value.target.value);
  }

  const resetData = (order) => {
    setSelectedSymbol('BTC');
    setSelectedSymbol1('Bitcoin');
    setSelectedCoins(optionSymbol12);
    setSelectedName('');
    setTransactions(filteredData);
  };

  const handleCopy = (params, index) => {
    navigator.clipboard.writeText(params.address);
    setColorCopy(index);
    // eslint-disable-next-line no-unused-expressions
    colorCopy === index
      ? setColorCopy(null)
      : setColorCopy(index);
  };
  const data = [];
  const onCopyWalletAddress = (item) => {
    navigator.clipboard.writeText(item);
  };

  useEffect(() => {
    const filteredTransactions = getTransactions.filter(item => item.network === 'Bitcoin' && item.type === 'BTC');
    setFilteredData(filteredTransactions);
  }, []);

  if (selectedSymbol1 === 'Bitcoin' && selectedSymbol === 'BTC') {
    getTransactions.map((item, index) => {
      if (item.network === 'Bitcoin' && item.type === 'BTC') {
        data.push({
          id: (
            <div className='d-flex w-100 bg-white p-2 justify-content-center  align-items-center  py-3'>
              <QRCode size="90" level="H" value={item.address} renderAs="canvas" />
            </div>
          ),
          address: (
            <>
              <span>{item.address} </span>
              <Tooltip text="Copy Address" placement="top" style={{ cursor: 'pointer' }} dir="ltr">
                <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(item.address)} />
              </Tooltip>
            </>
          ),
          network: item.network,
          code: item.code,
          type: item.type
        });
      }
    });
  } else {
    getTransactions.map((item, index) => {
      data.push({
        id: <QRCode size="70" level="H" value={item.address} renderAs="canvas" />,
        address: (
          <>
            <span>{item.address} </span>
            <Tooltip text="Copy Address" placement="top" style={{ cursor: 'pointer' }} dir="ltr">
              <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(item.address)} />
            </Tooltip>
          </>
        ),
        network: item.network,
        code: item.code,
        type: item.type
      });
    });
  }

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [5, 10, 15, 20],
    placeholder: 'Search...',
    isPagination: true,
  };
  return (
    <Panel lg={12} title="Deposit" closeBtn minusBtn refreshLoad>
      <Row className="mb-5">
        <Col xs={12} md={4} lg={3}>
          <InputDiv>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mt-2 mb-0">Address</label>
            <ChatSearchInput
              type="text"
              value={selectedName}
              className="form-control mt-2"
              placeholder="Search by Address"
              onChange={handleNameChange}
              id="File2"
              name="File2"
            />
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Network</label>
          <InputDiv className="px-0">
            <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
              <CustomSelect
                name="size"
                id="size"
                className="w-25 px-0"
                value={selectedSymbol1}
                onChange={handleSymbolChange1}
                options={optionSymbol1}
              />
            </DropdownDiv>
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Type</label>
          <InputDiv className="px-0">
            <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
              <CustomSelect
                name="size"
                id="size"
                className="w-25 px-0"
                value={selectedSymbol}
                onChange={handleSymbolChange}
                options={getSelectedCoins}
              />
            </DropdownDiv>
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <label htmlFor="color" style={{ fontSize: 'small', color: 'transparent' }} className="form-label mb-1">.</label>
          <Button
            onClick={() => resetData(1)}
            style={{
              background: '#4ce1b6',
            }}
            size="md"
            className="py-1 btn-block mt-2"
            variant="success"
          >Reset  <i className="fa fa-refresh" />
          </Button>
        </Col>
      </Row>
      <ReactTableBase
        columns={header}
        data={data}
        tableConfig={tableConfig}
      />
    </Panel>
  );
};

export default ReceiveTable;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;  
  }
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
