/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable radix */
import React, { useState, useEffect } from "react";
import { validate, Network } from "bitcoin-address-validation";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import { Ring } from "@uiball/loaders";
import styled from "styled-components";
import { Alert } from "react-bootstrap";

import { Button } from "../../../../shared/components/Button";
import { CustomSelect } from "../../../../shared/components/CustomSelect";
import {
    bankTransferFromVirtualWallet,
    confirmOTP,
    getApprover,
    getFeeEstimateStriga,
    getStrigaWalletAddress,
    getUserInfo,
    initiateIntraledgerTransaction,
    initiateOnChainTransaction,
    initiateSepaTransaction,
} from "../../../../utils/services/user.service";
import { clearMsg } from "../../../../redux/actions/authActions";
import { colorAccent } from "../../../../utils/palette";
// import { sendToken } from "../../../../utils/services/auth.services";
import { Switch, FormControlLabel, Tooltip } from "@mui/material";
import {
    getAllWallets
} from "../../../../utils/services/user.service";
import { toast } from "react-toastify";
import { SidebarLinkIcon } from "../../../Layout/components/sidebar/SidebarLink";


const user = JSON.parse(localStorage.getItem("user"));

const BankWalletSendForm = ({ selectedValue, errorMsg }) => {
    const [otp, setOtp] = useState(false);
    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState(200);
    const [approverData, setapproverData] = useState([]);


    const [loading, setloading] = useState(false);
    const [isWallet, setisWallet] = useState(false);
    const [externalCoin, setExternalCoin] = useState(null);
    const [options, setOptions] = useState([]);

    const [btcAddress, setBtcAddress] = useState("");
    const [validBtcAddress, setValidBtcAddress] = useState(null);
    const [walletError, setWalletError] = useState(null);
    const [walletSuccess, setWalletSuccess] = useState(null);
    const [tfaUrl, setTfaUrl] = useState(false);
    const [tfaEmail, setTfaEmail] = useState("");
    const [otpbtn, setOtpbtn] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [amount1, setAmount] = useState("");
    const [receiver1, setReceiver] = useState("");

    const [coin1, setCoin] = useState("");
    const [selectedCoins, setSelectedCoins] = useState("");
    const [otp1, setOtp1] = useState("");
    const [statusCode, setstatusCode] = useState(400);
    const [toggle, setToggle] = useState(false);
    const [userInfoData, setUserData] = useState(null);
    const userSessionData = JSON.parse(localStorage.getItem("user"));
    const [tfaCode, setTfaCode] = useState("");
    const [walletOption, setWalletOption] = useState("main_wallet");
    const [withdrawFee, setWithdrawFee] = useState(null);
    const [challengeId, setChallangeId] = useState('');
    const [bic, setBic] = useState('');
    const [iban, setIban] = useState('');
    const [onChainWalletAddress, setOnchainWalletAddress] = useState('');

    const [useronChainWalletAddress, setuserOnchainWalletAddress] = useState('');

    const [onChainFeeEstimation, setOnchainFeeEstimation] = useState(null);

    const [recieverSolAddress, setRecieverSolAddress] = useState("");
    const [strigaWalletDetail, setStrigaWalletDetail] = useState(null)

    const [strigaWAlletAddress, setStrigaWalletAddress] = useState(null)

    const [defaultBenificiary, setDefaultBenificiary] = useState(null);
    const [selectedBankWalletFrom, setSelectedBankWalletFrom] = useState("onChain_Transection")
    const [selectedBankWalletTo, setSelectedBankWalletTo] = useState("virtual")

    const getStrigaWallet = async (id) => {
        setIsLoading(true)
        const payload = {
            "striga_user_id": id
        }
        const res = await getStrigaWalletAddress(payload)
        setStrigaWalletAddress(res?.data)
        setIsLoading(false)
    }
    useEffect(() => {

        getStrigaWallet(defaultBenificiary)

    }, [selectedBankWalletFrom])
    const dispatch = useDispatch();
    let isMounted = true;

    const SmallLabel = ({ text, smallText }) => (
        <span className="text-black">
            {text}{" "}
            <small>
                <b>{smallText}</b>
            </small>
        </span>
    );

    useEffect(() => {
        if (strigaWalletDetail && strigaWalletDetail.wallets) {
            const firstWallet = strigaWalletDetail.wallets[0]; // Accessing the first wallet object
            if (firstWallet && firstWallet.accounts) {
                const accounts = Object.values(firstWallet.accounts);

                // Define the mapping between currencies and their chain IDs
                const chainIdMapping = {
                    BNB: '97',
                    USDT: '11155111',
                    USDC: '11155111',
                    ETH: '11155111'
                };

                const options = accounts
                    .filter(account => {
                        if (selectedBankWalletFrom === "sepa_payment") {
                            return account.currency === "EUR";
                        }
                        if (selectedBankWalletTo === "bank") {
                            return account.currency !== "EUR" && account.currency !== "BTC";
                        }
                        if (selectedBankWalletFrom == "onChain_Transection") {
                            return account.currency !== "EUR";
                        }
                        return true; // Add other cases if needed
                    })
                    .map(account => ({
                        value: account.currency,
                        label: (
                            <SmallLabel
                                text={account.currency}
                                smallText={account.availableBalance?.hAmount}
                            />
                        ),
                        accountId: account.accountId,
                        availableBalance: account.availableBalance?.hAmount,
                        chainId: chainIdMapping[account.currency] // Assigning static chain ID
                    }));

                setOptions(options);
            }
        }
    }, [strigaWalletDetail, selectedBankWalletFrom, selectedValue]);


    useEffect(() => {
        if (btcAddress === "") {
            setValidBtcAddress(null); // Reset validation status when the input is empty
        } else {
            setValidBtcAddress(validate(btcAddress, Network.testnet));
        }
    }, [btcAddress]);

    const [searchLoad, setsearchLoad] = useState(false);

    const handleInputChange = (name) => {
        // debugger;
        setsearchLoad(true);

        if (name.length >= 2) {
            getApprover({ name })
                .then((res) => {
                    setsearchLoad(false);

                    const arr = res.data.user.map((i) => ({
                        id: i._id,
                        address: i.address,
                        value: i.name,
                        label: i.name,
                        sol_address: i?.sol_address,
                        btcAddress: i?.btcAddress,
                        destinationUserId: i?.striga_user_id,
                        onChainAddress: i?.secondary_wallet_address,
                        onChainSolAddress: i?.secondary_wallet_sol_address,
                        striga_user_id: i?.striga_user_id

                    }));

                    setsearchLoad(false);
                    // Case-insensitive comparison
                    const matchingOptions = arr.filter(
                        (option) => option.value.toLowerCase() === name.toLowerCase()
                    );
                    setDefaultBenificiary(matchingOptions[0]?.destinationUserId)
                    setRecieverSolAddress(matchingOptions[0]?.sol_address)
                    // setOnchainWalletAddress(matchingOptions[0])
                    setapproverData(matchingOptions);
                })
                .catch((error) => {
                    setsearchLoad(false);
                    setstatusCode(400);

                    if (error.message === "Network Error") {
                        // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
                    } else {
                        setMsg(`CatchError:${error}${error?.message}`);
                        toast.error(`CatchError:${error}${error?.message}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }

                    setTimeout(() => {
                        setMsg("");
                    }, 5000);
                });
        } else {
            setapproverData([]); // Clear options when input length is less than 2
        }

        setsearchLoad(false);
    };
    const userData = async () => {
        try {
            const res = await getUserInfo();
            setUserData(res?.data?.User);
        } catch (error) {
            console.log("🚀 ~ userInfo ~ error:", error);
        }
    };

    useEffect(() => {
        userData();
    }, []);


    useEffect(() => {
        getWalletDetail()
    }, []);

    const handleIntraledger = async () => {
        setIsLoading(true)
        try {
            const payload = {
                "sourceAccountId": coin1?.accountId,
                "amount": amount1,
                "destinationSymbol": selectedCoins,
                "destinationUserId": receiver1?.destinationUserId
            }
            const res = await initiateIntraledgerTransaction(payload)
            setChallangeId(res?.data?.challengeId)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            toast.error(error?.response?.data?.message || error?.response?.data?.Message)
            console.log("🚀 ~ handleIntraledger ~ error:", error)

        }
    }
    const handleSepaPayent = async () => {
        setIsLoading(true)
        try {
            const payload = {
                "iban": iban,
                "bic": bic,
                "sourceAccountId": "4e71554977474013ff0c034ea3b22c3e",
                "amount": amount1
            }
            const res = await initiateSepaTransaction(payload)

            setChallangeId(res?.data?.challengeId)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            console.log("🚀 ~ handleIntraledger ~ error:", error)

        }
    }
    const handleIntraledgerOTP = async () => {
        setIsLoading(true)
        try {
            const payload = {
                "challengeId": challengeId,
                "verificationCode": otp1,
                "amount": amount1,
                "symbol": selectedCoins,
                "reciever": selectedBankWalletFrom === "intraledger_payment" ? receiver1?.destinationUserId : selectedBankWalletFrom == "sepa_payment" ? strigaWAlletAddress?.IBAN : strigaWAlletAddress?.blockchainDepositAddress
            }
            const res = await confirmOTP(payload)
            setIsLoading(false)
            if (res) {
                toast.success('Transection Successfull')
                setCoin(null)
                setReceiver("")
                setChallangeId('')
            }

        } catch (error) {
            setIsLoading(false)
            toast.error(error?.response?.data?.message)
            console.log("🚀 ~ handleIntraledger ~ error:", error)

        }
    }

    const getWalletDetail = async () => {
        setIsLoading(true)
        try {
            const res = await getAllWallets()
            setStrigaWalletDetail(res?.data)
            setIsLoading(false)
        } catch (error) {
            console.log("🚀 ~ getWalletDetail ~ error:", error)
            setIsLoading(false)
        }
    }
    const getOnchainFeeEstimate = async () => {
        try {
            setIsLoading(true)
            const payload = {
                "sourceAccountId": coin1?.accountId,
                "walletAddress": onChainWalletAddress || defaultBenificiary,
                "amount": amount1,
                "symbol": selectedCoins
            }

            const res = await getFeeEstimateStriga(payload)
            setOnchainFeeEstimation(res?.data)

            if (res?.data?.HAmountTotalFee && res.data.HAmountTotalFee + amount1 < coin1.availableBalance) {
                const res = await initiateOnChainTransaction(payload)

            } else {
                toast.error("You don't have enough available balance ")
                return false
            }
            setIsLoading(false)

        } catch (error) {
            console.log("🚀 ~ getOnchainFeeEstimate ~ error:", error)
            toast.error(error?.response?.data.message || error?.response?.data.Message)
            setIsLoading(false)

        }
    }
    const hanldeBankTransfer = async () => {
        try {
            setIsLoading(true)
            const payload = {
                "symbol": selectedCoins,
                "chainId": coin1.chainId,
                "amount": amount1
            }
            const res = await bankTransferFromVirtualWallet(payload)
            toast.success(res?.data?.message)

            setIsLoading(false)

        } catch (error) {

            toast.error(error?.response?.data.message || error?.response?.data.Message)
            setIsLoading(false)

        }
    }

    const userDataInfo = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (userDataInfo) {
            const defaultUser = `${userDataInfo?.data?.user?.firstName} ${userDataInfo?.data?.user?.lastName}`
            handleInputChange(defaultUser)
        }
    }, [selectedBankWalletTo])

    const onCopyWalletAddress = (item) => {
        navigator.clipboard.writeText(item);
    };


    const BankWalletFromOptions = [
        // { label: 'From Bank Wallet', value: 'bank_transfer' },
        { label: 'Intraledger Payment ', value: 'intraledger_payment' },
        { label: 'Sepa Payment (Only EUR)', value: 'sepa_payment' },
        { label: 'Crypto Asset', value: 'onChain_Transection' }
    ];
    const BankWalletToOptions = [
        { label: 'Onchain Wallet', value: 'onChain' },
        { label: 'Virtual Wallet', value: 'virtual' },
        { label: 'External', value: 'external_wallet' },
        { label: 'Internal CUstodiy ', value: 'internal_custodiy' },

    ];
    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <div className="col-12 w-100">
                {walletError && (
                    <WalletError className="input feedback">{walletError}</WalletError>
                )}
                {walletSuccess && (
                    <WalletSuccess className="input feedback">
                        {walletSuccess}
                    </WalletSuccess>
                )}
                <Formik
                    initialValues={{
                        receiver: "",
                        amount: "",
                        coin: selectedBankWalletFrom === "external" ? externalCoin : "",
                        otp: "",
                        isRecipientAddress: true,
                    }}
                    onSubmit={(values, { resetForm }) => {
                        if (otp1 === "") {
                            // console.log('==external', isWallet, account.address);
                            if (selectedBankWalletFrom === "external") {
                                if (isWallet) {

                                } else {
                                    setloading(true);
                                    setStatus(400);
                                    setMsg("Your Wallet is not connected");
                                    setOtp(false);
                                    setloading(false);
                                    setTimeout(() => {
                                        setMsg("");
                                    }, 4000);
                                }
                            } else {

                            }
                        } else {
                            if (user.data.user.TFA === true) {
                                setOtpbtn(false);
                                setTfaUrl(true);
                                setTfaEmail(user.data.user.email);
                            } else {
                                setloading(true);

                            }
                        }
                    }}
                    validate={(values) => {
                        const errors = {};
                        const regex = /^[0-9\b.]+$/;
                        const negRegex = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
                        if (!receiver1 && walletOption == "main_wallet") {
                            errors.receiver = "Recipeint Wallet Address is Required";
                        }
                        if (!amount1) {
                            errors.amount = "Amount is Required!!";
                        } else if (
                            ["ETH", "BNB", "MATIC"].includes(
                                coin1 === null ? externalCoin : coin1
                            )
                        ) {
                            if (!negRegex.test(amount1)) {
                                errors.amount = "Amount must be in digit e.g 10,2.3";
                            }
                        } else if (
                            !["ETH", "BNB", "MATIC"].includes(
                                coin1 === null ? externalCoin : coin1
                            )
                        ) {
                            if (!regex.test(amount1)) {
                                errors.amount = `Amount ${coin1 === "" ? "" : `in ${coin1}`
                                    } must be in digit and should be greater then 0 e.g 10`;
                            }
                        }
                        // console.log(values);
                        const coinVal = coin1 === null ? externalCoin : coin1;
                        if (!coinVal) {
                            errors.coin = "coin is Required!!";
                        }
                        if (otp) {
                            const otpRegex = /^[0-9\b]+$/;
                            if (!otp1) {
                                errors.otp = "Otp is Required!!";
                            } else if (!otpRegex.test(otp1)) {
                                errors.otp = "Invalid Otp!! must be in digits";
                            }
                        }
                        return errors;
                    }}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Alert
                                    variant={status === 200 ? "success" : "danger"}
                                    show={msg !== ""}
                                    style={{
                                        marginTop: "1rem",
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: status === 200 ? "#c1f9c1" : "#f1beb0",
                                        color: status === 200 ? "#22bb33" : "red",
                                    }}
                                    onClose={() => {
                                        setMsg("");
                                    }}
                                    dismissible
                                >
                                    {msg}
                                </Alert>
                                <Alert
                                    variant={
                                        statusCode === 200 || statusCode === "success"
                                            ? "success"
                                            : "danger"
                                    }
                                    show={errorMsg !== undefined}
                                    style={{
                                        marginTop: "1rem",
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor:
                                            statusCode === 200 || statusCode === "success"
                                                ? "#c1f9c1"
                                                : "#f1beb0",
                                        color:
                                            statusCode === 200 || statusCode === "success"
                                                ? "#22bb33"
                                                : "red",
                                    }}
                                    onClose={() => {
                                        dispatch(clearMsg());
                                    }}
                                    dismissible
                                >
                                    {errorMsg}
                                </Alert>

                                <div className="row mb-1">
                                    <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
                                        <SelectDiv style={{ display: "flex", width: "100%" }}>
                                            <label
                                                htmlFor="email"
                                                className="text-white"
                                                style={{ fontSize: "small" }}
                                            >
                                                From
                                            </label>
                                            <CustomSelect
                                                value={selectedBankWalletFrom}
                                                onChange={(value) => {

                                                    setSelectedBankWalletFrom(value.value)
                                                    setSelectedCoins("")
                                                }}
                                                options={
                                                    BankWalletFromOptions
                                                }
                                                isSearchable={false}
                                            />
                                        </SelectDiv>
                                    </div>

                                    {selectedBankWalletFrom == "onChain_Transection" &&
                                        <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
                                            <SelectDiv style={{ display: "flex", width: "100%" }}>
                                                <label
                                                    htmlFor="email"
                                                    className="text-white"
                                                    style={{ fontSize: "small" }}
                                                >
                                                    To
                                                </label>
                                                <CustomSelect
                                                    value={selectedBankWalletTo}
                                                    onChange={(value) => {

                                                        setSelectedBankWalletTo(value.value)
                                                    }}
                                                    options={
                                                        BankWalletToOptions
                                                    }

                                                    isSearchable={false}
                                                />
                                            </SelectDiv>
                                        </div>
                                    }
                                    {selectedBankWalletTo == "external_wallet" && selectedBankWalletFrom == "onChain_Transection" ? (
                                        <div className="col-md-4 col-lg-4 col-sm-12">
                                            <InputDiv>
                                                <Label htmlFor="email" className="mt-3">
                                                    Recipient Address *
                                                    <Tooltip
                                                        text="Copy Address"
                                                        placement="top"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <SidebarLinkIcon
                                                            style={{ cursor: "pointer" }}
                                                            className="ml-1 lnr lnr-book"

                                                            onClick={() =>
                                                                onCopyWalletAddress(
                                                                    receiver1
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>
                                                </Label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <ChatSearchInput
                                                        name="receiver"
                                                        type="text"
                                                        // disabled
                                                        placeholder="Enter  Destination User"
                                                        value={receiver1}
                                                        onChange={(value) => {
                                                            setReceiver(value.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={
                                                            errors.receiver && touched.receiver && "error"
                                                        }
                                                    />
                                                    {errors.receiver && touched.receiver && (
                                                        <Error className="input feedback">
                                                            {errors.receiver}
                                                        </Error>
                                                    )}
                                                </div>
                                            </InputDiv>
                                        </div>
                                    ) : (
                                        <>
                                            {selectedBankWalletFrom != "sepa_payment" && (
                                                <div className="col-md-4 col-lg-4 col-sm-12">
                                                    <InputDiv>
                                                        <Label htmlFor="email" className="mt-1">
                                                            Recipient Address *
                                                            <Tooltip
                                                                text="Copy Address"
                                                                placement="top"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <SidebarLinkIcon
                                                                    style={{ cursor: "pointer" }}
                                                                    className="ml-1 lnr lnr-book"

                                                                    onClick={() =>
                                                                        onCopyWalletAddress(
                                                                            receiver1?.destinationUserId || selectedBankWalletFrom != "onChain_Transection" && strigaWAlletAddress?.IBAN ||
                                                                            selectedBankWalletFrom == "onChain_Transection" && strigaWAlletAddress?.blockchainDepositAddress
                                                                        )
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </Label>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <CustomSelect
                                                                isLoading={searchLoad}
                                                                name="receiver"
                                                                onInputChange={handleInputChange}

                                                                placeholder={
                                                                    selectedBankWalletFrom != "onChain_Transection" && strigaWAlletAddress?.IBAN ||
                                                                    selectedBankWalletFrom == "onChain_Transection" && selectedBankWalletTo == "virtual" && selectedCoins == "BTC" && approverData[0]?.btcAddress ||
                                                                    selectedBankWalletFrom == "onChain_Transection" && selectedBankWalletTo == "virtual" && strigaWAlletAddress?.blockchainDepositAddress ||
                                                                    selectedBankWalletFrom == "onChain_Transection" && selectedBankWalletTo == "onChain" && approverData[0]?.secondary_wallet_address ||
                                                                    approverData[0]?.address
                                                                }
                                                                onChange={(value) => {

                                                                    if (selectedBankWalletFrom == "intraledger_payment" && !value?.striga_user_id) {
                                                                        toast.error("This user don't have striga_user_id")
                                                                    } else if (selectedBankWalletFrom == "onChain_Transection" && selectedBankWalletTo == "onChain" && !value?.secondary_wallet_address) {
                                                                        toast.error("This user don't have onChain Wallet")
                                                                    }
                                                                    setReceiver(value);
                                                                }}
                                                                // value={receiver1}
                                                                options={approverData}
                                                                isSearchable
                                                            />{" "}
                                                            {errors.receiver && touched.receiver && (
                                                                <Error className="input feedback">
                                                                    {errors.receiver}
                                                                </Error>
                                                            )}
                                                        </div>
                                                    </InputDiv>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                                        <SelectDiv style={{ display: "flex", width: "100%" }}>
                                            <label
                                                htmlFor="email"
                                                className="text-white"
                                                style={{ fontSize: "small" }}
                                            >
                                                Coins *
                                            </label>

                                            <CustomSelect
                                                // value={coin1?.value}
                                                placeholder={selectedCoins || 'Select Coin'}
                                                onChange={(value) => {

                                                    setCoin(value);
                                                    setSelectedCoins(value.value)
                                                }}
                                                options={options}
                                            // className="py-4"
                                            />

                                            {errors.coin && touched.coin && (
                                                <Error className="input feedback">{errors.coin}</Error>
                                            )}
                                        </SelectDiv>
                                    </div>




                                    <div className="col-lg-3 col-md-12 col-sm-12 mt-1">
                                        <InputDiv>
                                            <label htmlFor="email" className="text-white mb-3">
                                                Amount *
                                            </label>
                                            <ChatSearchInput
                                                name="amount"
                                                type="text"
                                                // placeholder={`Enter amount in ${selectedCoins === "BTC" ? "sats" :
                                                //     selectedCoins === "BNB" || selectedCoins === "ETH" ? "wei" :
                                                //         selectedCoins === "USDT" || selectedCoins === "USDC" || selectedCoins === "EUR" ? "cents" :
                                                //             ""
                                                //     }`}
                                                placeholder="Enter Amount"
                                                value={amount1}
                                                onChange={(value) => {
                                                    setAmount(value.target.value);
                                                }}
                                                onBlur={handleBlur}
                                                className={`form-control w-100 ${errors.amount && touched.amount && "error"
                                                    }`}
                                            />
                                            {errors.amount && touched.amount && (
                                                <Error className="input feedback">
                                                    {errors.amount}
                                                </Error>
                                            )}
                                        </InputDiv>
                                    </div>
                                    {selectedBankWalletFrom === "sepa_payment" && <><div className="col-lg-3 col-md-12 col-sm-12 mt-1">
                                        <InputDiv>
                                            <label htmlFor="email" className="text-white mb-3">
                                                BIC *
                                            </label>
                                            <ChatSearchInput
                                                name="amount"
                                                type="text"
                                                placeholder="Enter  BIC"
                                                value={bic || strigaWAlletAddress?.BIC}
                                                onChange={(value) => {
                                                    setBic(value.target.value);
                                                }}
                                                onBlur={handleBlur}
                                                className={`form-control w-100 `} />
                                        </InputDiv>
                                    </div>
                                        <div className="col-lg-3 col-md-12 col-sm-12 mt-1">
                                            <InputDiv>
                                                <label htmlFor="email" className="text-white mb-3">
                                                    IBAN *
                                                </label>
                                                <ChatSearchInput
                                                    name="amount"
                                                    type="text"
                                                    placeholder="Enter  IBAN"
                                                    value={iban || strigaWAlletAddress?.IBAN}
                                                    onChange={(value) => {
                                                        setIban(value.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    className={`form-control w-100 `} />

                                            </InputDiv>
                                        </div>
                                    </>
                                    }
                                </div>

                                <div v className="row mb-1">
                                    {challengeId && (
                                        <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                                            <InputDiv>
                                                <label
                                                    htmlFor="email"
                                                    className="text-white mt-2"
                                                    style={{ fontSize: "small" }}
                                                >
                                                    OTP *
                                                </label>
                                                <div>
                                                    <ChatSearchInput
                                                        name="otp"
                                                        type="text"
                                                        placeholder="Enter your otp"
                                                        value={otp1}
                                                        // onChange={handleChange}
                                                        onChange={(e) => setOtp1(e.target.value)}
                                                        onBlur={handleBlur}
                                                        className={`form-control w-100 ${errors.otp && touched.otp && "error"
                                                            }`}
                                                    />
                                                    {errors.otp && touched.otp && (
                                                        <Error className="input feedback">
                                                            {errors.otp}
                                                        </Error>
                                                    )}
                                                </div>
                                            </InputDiv>
                                        </div>
                                    )}
                                </div>
                                <div className="row mb-1">
                                    <div className="col-lg-4 col-md-12 col-sm-12 mt-1 mx-auto">
                                        {selectedBankWalletFrom === "sepa_payment" && (
                                            <FormButton
                                                onClick={challengeId ? handleIntraledgerOTP : handleSepaPayent}
                                                variant="outline-success"
                                                className="py-2 mt-5 w-100"
                                            >
                                                {challengeId ? "Verify OTP" : "Send"}
                                            </FormButton>
                                        )}

                                        {selectedBankWalletFrom === "intraledger_payment" && (
                                            <FormButton
                                                onClick={challengeId ? handleIntraledgerOTP : handleIntraledger}
                                                variant="outline-success"
                                                className="py-2 mt-5 w-100"
                                            >
                                                {challengeId ? "Verify OTP" : "Send"}
                                            </FormButton>
                                        )}

                                        {(selectedBankWalletFrom === "onChain_Transection" ||
                                            selectedBankWalletTo == "bank" ||
                                            selectedBankWalletFrom === "bank_transfer" ||
                                            selectedValue === "bank_wallet") && (
                                                <FormButton
                                                    onClick={
                                                        selectedBankWalletFrom === "bank_transfer"
                                                            ? hanldeBankTransfer
                                                            : getOnchainFeeEstimate
                                                    }
                                                    variant="outline-success"
                                                    className="py-2 mt-5 w-100"
                                                >
                                                    Send
                                                </FormButton>
                                            )}
                                    </div>

                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};


export default BankWalletSendForm;
const Label = styled.label`
        @media screen and (max-width: 876px) {
          margin - bottom: 0px;
        margin-top: 8px;
}
        `;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 15rem 20rem 20rem;
  grid-gap: 1rem;
  width: 100%;
  @media screen and (max-width: 876px) {
    grid-template: none;
  }
`;
const AmountDiv = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;
const WalletError = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 16px;
  cursor: none;
`;
const WalletSuccess = styled.div`
  color: #4ce1b6;
  padding: 2px 0px;
  font-size: 16px;
  cursor: none;
`;
const SelectDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  font-size: 14px;
`;

const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
