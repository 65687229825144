import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  colorBackground, colorHover, colorText, colorBorder,
} from '@/utils/palette';
import { marginLeft, right, left } from '@/utils/directions';
import { TopbarBack, TopbarDownIcon } from './BasicTopbarComponents';
import TopbarMenuLink, { TopbarLink } from './TopbarMenuLink';
import CreateNewPass from '../../../Account/NewPassword';
import MyAccount from '../../../Account/MyAccount';
import { logout } from '../../../../redux/actions/authActions';
import { useChainContext } from '../../../context/context';
import { getUserInfo, logout_user } from '../../../../utils/services/user.service';


const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [itemss, setItems] = useState(JSON.parse(localStorage.getItem('user')));
  const { refreshUserData } = useChainContext()
  const [selectedImage, setSelectedImage] = useState("");
  const Ava = `/img/avat.png`;
  const userdata = JSON.parse(localStorage.getItem('user'))
  const getUsers = () => {
    let isMounted = true;
    getUserInfo()
      .then((res) => {
        if (isMounted) {
          setSelectedImage(res?.data?.User.image);
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.log("🚀 ~ getUsers ~ error:", error);
        }
      });

    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    setItems(JSON.parse(localStorage.getItem('user')));
    const cleanupGetUsers = getUsers();

    return () => {
      cleanupGetUsers();
    };
  }, [refreshUserData]);
  const history = useHistory();
  const toggleProfile = () => {

    setIsCollapsed(!isCollapsed);
  };
  const toggleProfile1 = () => {
    history.push('/profile');
    setIsCollapsed(!isCollapsed);
  };
  const toggleProfile2 = () => {
    history.push('/default_pages/calendar');
    setIsCollapsed(!isCollapsed);
  };
  const toggleProfile3 = () => {
    history.push('/todo');
    setIsCollapsed(!isCollapsed);
  };
  const toggleProfile5 = () => {
    history.push('/timeline');
    setIsCollapsed(!isCollapsed);
  };
  const toggleProfile4 = () => {
    history.push('/subaccounts');
    setIsCollapsed(!isCollapsed);
  };
  const dispatch = useDispatch();
  const logoutId = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    dispatch(logout());
    logout_user({ 'user_id': user?.data?.user.user_id })
    history.push('/');
  };
  useEffect(() => {
    if (userdata?.data?.user?.firstName == undefined) {
      dispatch(logout());
      history.push('/login');
    }
  }, [userdata])

  return (

    <TopbarProfileWrap>
      <TopbarAvatarButton type="button" onClick={toggleProfile}>

        <TopbarAvatarImage  >
          <img alt='profle pic' className='d-flex rounded-4 ' src={selectedImage || Ava} style={{
            width: '26px',
            height: '26px'
          }} />
        </TopbarAvatarImage>
        <TopbarAvatarName style={{ textTransform: 'capitalize' }}>
          {`${itemss?.data?.user?.firstName} ${itemss?.data?.user?.lastName}`}
        </TopbarAvatarName>
        <TopbarDownIcon />
      </TopbarAvatarButton>
      {isCollapsed && (
        <TopbarBack
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse in={isCollapsed}>
        <TopbarMenuWrap>
          <TopbarMenu>
            {/* <MyAccount
              title="My Account"
              icon="user"
              onClick={toggleProfile}
            />
            <CreateNewPass
              title="Create New Password"
              icon="calendar-full"
              path="/default_pages/calendar"
              onClick={toggleProfile}
            /> */}
            <TopbarMenuLink
              title="My Profile"
              icon="user"
              route="/profile"
              onClick={toggleProfile1}
            />
            <TopbarMenuLink
              title="Calendar"
              icon="calendar-full"
              path="/default_pages/calendar"
              onClick={toggleProfile2}
            />
            <TopbarMenuLink
              title="Tasks"
              icon="list"
              path="/todo"
              onClick={toggleProfile3}
            />
            <TopbarMenuLink
              title="Timeline"
              icon="list"
              path="/timeline"
              onClick={toggleProfile5}
            />
            <TopbarMenuLink
              title="Sub Accounts"
              icon="inbox"
              path="/subaccounts"
              onClick={toggleProfile4}
            />
            <TopbarMenuDivider />
            <TopbarMenuLink
              title="Account Settings"
              icon="cog"
              route="/profile"
              onClick={toggleProfile1}
            />
            {/* <TopbarMenuLink
              title="Lock Screen"
              icon="lock"
              path="/lock_screen"
              onClick={toggleProfile}
            /> */}
            <TopbarMenuLink
              title="Log Out"
              icon="exit"
              path="/login"
              onClick={logoutId}
            />
          </TopbarMenu>
        </TopbarMenuWrap>
      </Collapse>
    </TopbarProfileWrap>
  );
};

export default TopbarProfile;

// region STYLES

export const TopbarProfileWrap = styled.div`
  position: relative;
  margin-bottom: 0;
  ${marginLeft}: 0;

  @media screen and (max-width: 576px) {
    margin: inherit;
  }

  @media screen and (max-width: 320px) {
    margin: auto 0;
  }
`;

const TopbarAvatarButton = styled.button`
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 15px;
  background-color: transparent;

  &:hover, &:focus, &:active, &:focus:active {
    background-color: ${colorHover};
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }  
`;

const TopbarMenuDivider = styled.div`
  margin: 15px 0;
  border-top: 1px solid ${colorBorder};
`;

const TopbarAvatarImage = styled.span`
  margin: auto 0;
  border-radius: 50%;
  // height: 36px;
  // width: 36px;
  color:white;
`;

export const TopbarAvatarName = styled.p`
  margin: auto 0;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  display: none;
  ${marginLeft}: 10px;
  color: ${colorText};

  @media screen and (min-width: 480px) {
      display: block;
  }
`;

const TopbarMenuWrap = styled.div`
  z-index: 101;
  position: absolute;
  width: 100%;
  min-width: 210px;
  ${right}: 0;

  @media screen and (max-width: 320px) {
    ${right}: -50px;
  }
`;

const TopbarMenu = styled.div`
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
  background: ${colorBackground};

  button {
    padding: 0;

    &:hover {
      background-color: ${colorHover};
    }

    &${TopbarLink} {
      background-color: transparent;
      border: none;
      padding: 9px 20px;
    }
  }

  *:focus {
    outline: none;
  }

  @media screen and (min-width: 480px) {
    width: 100%;
    ${left}: 0 !important;
  }
`;


// endregion
