/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  colorBorder, colorBackground, colorHover, colorDustyWhite,
  colorAccent,
} from '@/utils/palette';
import { left } from '@/utils/directions';
import SidebarLink, { SidebarLinkTitle } from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { getAllOtcMarketPlaceWebapp } from '../../../../utils/services/user.service';


const SidebarContent = ({
  onClick, collapse,
}) => {

  const [usersList, setUsersList] = useState([]);
  useEffect(() => {
    const getAllOtcMarketLists = async () => {
      try {
        const payload = {
          limit: 20,
          page_number: 1,
        };
        const response = await getAllOtcMarketPlaceWebapp(payload);
        if (response) {
          setUsersList(response?.data?.response);
        }
      } catch (error) {
        console.error("Error fetching OTC marketplace lists:", error);
      }
    };
    getAllOtcMarketLists();
  }, []);
  return(

  // <SidebarContentWrap collapse={collapse}>
  //   <SidebarBlock collapse={collapse}>
  //     <SidebarCategory
  //       title={<><span style={{ color: '#2584cd', fontSize: '50px', marginTop: '4px' }}>&bull;</span><span> &nbsp; Financial Dashboard</span></>}
  //       collapse={collapse}
  //     >
  //       {!collapse && <SidebarLink title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Dashboard</span></>} route="/finance" onClick={onClick} />}
  //       {!collapse && <SidebarLink title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Currency Converter</span></>} route="/convertor" onClick={onClick} />}
  //       {!collapse && <SidebarLink title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Token</span></>} route="/token" onClick={onClick} />}
  //       {!collapse && <SidebarLink title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Roadmap</span></>} route="/roadmap" onClick={onClick} />}
  //       {!collapse && <SidebarLink title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Whitepaper</span></>} route="/whitepaper" onClick={onClick} />}
  //     </SidebarCategory>
  //     <SidebarCategory
  //       title={<><span style={{ color: '#f79413', fontSize: '50px' }}>&bull;</span><span> &nbsp; Marketplace</span></>}
  //       collapse={collapse}
  //     >
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Home</span></>}
  //             route="/homeproducts"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Stores</span></>}
  //             route="/home"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; My Store</span></>}
  //             route="/storedetail"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Seller Orders</span></>}
  //             route="/getmyorders"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Approvals</span></>}
  //             route="/getmyapproval"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; My Orders</span></>}
  //             route="/getmyorderasbuyer"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Custom Offers</span></>}
  //             route="/customoffers"
  //             onClick={onClick}
  //           />
  //         )}
  //     </SidebarCategory>
  //     <SidebarCategory
  //       title={<><span style={{ color: '#70BBFD', fontSize: '50px' }}>&bull;</span><span> &nbsp; Contract</span></>}
  //       collapse={collapse}
  //     >
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; New Contract</span></>}
  //             route="/new-contract"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; New Safebox</span></>}
  //             route="/safebox"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Contracts List</span></>}
  //             route="/contractslist"
  //             onClick={onClick}
  //           />
  //         )}
  //     </SidebarCategory>

  //     <SidebarCategory
  //       title={<><span style={{ color: '#F6DA6E', fontSize: '50px' }}>&bull;</span><span> &nbsp; More</span></>}
  //       collapse={collapse}
  //     >
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Currency Converter</span></>}
  //             route="/convertor"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Whitepaper</span></>}
  //             route="/whitepaper"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Token</span></>}
  //             route="/token"
  //             onClick={onClick}
  //           />
  //         )}
  //       {!collapse
  //         && (
  //           <SidebarLink
  //             title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Roadmap</span></>}
  //             route="/roadmap"
  //             onClick={onClick}
  //           />
  //         )}
  //     </SidebarCategory>
  //     <SidebarCategory title={<><span style={{ color: '#70BBFD', fontSize: '50px' }}>&bull;</span><span> &nbsp; Chat and Support</span></>}
  //       collapse={collapse}>
  //       {!collapse && <SidebarLink title="&#x25CF; &nbsp; Customer Service" route="/support" onClick={onClick} />}
  //       {!collapse && <SidebarLink title="&#x25CF; &nbsp; Room Chat" route="/roomchat" onClick={onClick} />}
  //     </SidebarCategory>
  //   </SidebarBlock>
  // </SidebarContentWrap>
  <SidebarContentWrap collapse={collapse}>
  <SidebarBlock collapse={collapse}>
    <SidebarCategory
      title={
        <>
          <span style={{ color: "#7e45dd", fontSize: "50px" }}>&bull;</span>
          <span> &nbsp; Wallet Dashboard</span>
        </>
      }
      collapse={collapse}
    >
      {!collapse
        && (
          <SidebarLink
            title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Dashboard</span></>}
            route="/dashboard"
            onClick={onClick}
          />
        )}
      {/* {!collapse
        && (
          <SidebarLink
            title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Upcoming Balance</span></>}
            route="/balanceinfo"
            onClick={onClick}
          />
        )} */}
      {/* {!collapse
    && (
    <SidebarLink
      title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Wallet Activities</span></>}
      route="/walletActivities"
      onClick={onClick}
    />
  )} */}

      {/* {!collapse
    && (
    <SidebarLink
      title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Send</span></>}
      route="/send"
      onClick={onClick}
    />
  )}
  {!collapse
    && (
    <SidebarLink
      title={<><span style={{ color: '#ffffff', fontSize: '35px', marginTop: '2px' }}>&bull;</span><span> &nbsp; Receive</span></>}
      route="/receive"
      onClick={onClick}
    />
  )}   */}
    </SidebarCategory>
    <SidebarLink
      title={
        <>
          <span
            style={{ color: "#2584cd", fontSize: "50px", marginTop: "4px" }}
          >
            &bull;
          </span>
          <span> &nbsp; Financial Dashboard</span>
        </>
      }
      // icon="rocket"
      route="/finance" // Update the route to include the parent path
      onClick={onClick}
    />
    <SidebarCategory
      title={
        <>
          <span style={{ color: "#f79413", fontSize: "50px" }}>&bull;</span>
          <span> &nbsp; Marketplace</span>
        </>
      }
      collapse={collapse}
    >
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Home</span>
            </>
          }
          route="/homeproducts"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Stores</span>
            </>
          }
          route="/home"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; My Store</span>
            </>
          }
          route="/storedetail"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Seller Orders</span>
            </>
          }
          route="/getmyorders"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Approvals</span>
            </>
          }
          route="/getmyapproval"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; My Orders</span>
            </>
          }
          route="/getmyorderasbuyer"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Custom Offers</span>
            </>
          }
          route="/customoffers"
          onClick={onClick}
        />
      )}
    </SidebarCategory>

    {
      <SidebarCategory
        title={
          <>
            <span style={{ color: "#f74413", fontSize: "50px" }}>
              &bull;
            </span>
            <span> &nbsp; OTC Marketplace </span>
          </>
        }
        collapse={collapse}
      >
        {!collapse &&
          usersList.map((user, index) => (
            <SidebarLink
              key={index}
              title={
                <>
                  <span
                    style={{
                      color: "#ffffff",
                      fontSize: "35px",
                      marginTop: "2px",
                    }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; {user?.market_place_name}</span>
                </>
              }
              route={`/otcmarketplacedetail?id=${user?._id}`}
              onClick={onClick}
            />
          ))}
      </SidebarCategory>
    }

    <SidebarCategory
      title={
        <>
          <span style={{ color: "#70BBFD", fontSize: "50px" }}>&bull;</span>
          <span> &nbsp; Contract</span>
        </>
      }
      collapse={collapse}
    >
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; New Contract</span>
            </>
          }
          route="/new-contract"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; New Safebox</span>
            </>
          }
          route="/safebox"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Contracts List</span>
            </>
          }
          route="/contractslist"
          onClick={onClick}
        />
      )}
    </SidebarCategory>

    <SidebarCategory
      title={
        <>
          <span style={{ color: "#F6DA6E", fontSize: "50px" }}>&bull;</span>
          <span> &nbsp; More</span>
        </>
      }
      collapse={collapse}
    >
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Currency Converter</span>
            </>
          }
          route="/convertor"
          onClick={onClick}
        />
      )}
      {/* {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Whitepaper</span>
            </>
          }
          route="/whitepaper"
          onClick={onClick}
        />
      )} */}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Token</span>
            </>
          }
          route="/token"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title={
            <>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "35px",
                  marginTop: "2px",
                }}
              >
                &bull;
              </span>
              <span> &nbsp; Roadmap</span>
            </>
          }
          route="/roadmap"
          onClick={onClick}
        />
      )}
    </SidebarCategory>
    <SidebarCategory
      title={
        <>
          <span style={{ color: "#70BBFD", fontSize: "50px" }}>&bull;</span>
          <span> &nbsp; Chat and Support</span>
        </>
      }
      collapse={collapse}
    >
      {!collapse && (
        <SidebarLink
          title="&#x25CF; &nbsp; Customer Service"
          route="/support"
          onClick={onClick}
        />
      )}
      {!collapse && (
        <SidebarLink
          title="&#x25CF; &nbsp; Room Chat"
          route="/roomchat"
          onClick={onClick}
        />
      )}
    </SidebarCategory>
  </SidebarBlock>
</SidebarContentWrap>
  )
}


SidebarContent.propTypes = {
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => { },
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;
  
  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
  
  @media screen and (min-width: 576px) {
    padding-top: 15px;
    
    ${props => props.collapse && `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;
  
  &:last-child {
    border: none;
  }
  
  @media screen and (min-width: 576px) {
    
    ${props => props.collapse && `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;
// endregion

