/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { right, left, marginRight } from "@/utils/directions";
import Panel from "@/shared/components/Panel";
import { Table } from "@/shared/components/TableElements";
import ErrorAlert from "@/shared/components/ErrorAlert";
import { fetchTopTenCurrencies } from "@/redux/actions/topTenActions";
import {
  // createMarketPlace,
  getBTC,
  getETH,
  getBNB,
  getUSDT,
  getUSDC,
  getFTY,
  getOPY,
  getPAX,
  getBUSD,
  getGBPT,
  getJPYC,
  getEUROC,
  getMATIC,
  getCTY,
  getMETA,
  getSolTokenPrice
} from "../../../../utils/services/user.service";
import { Bullet } from "../../BasicDashboardComponents";
import numeral from "numeral";

const colors = [
  "#d383ff",
  "#27a17c",
  "#2775ca",
  "#5db944",
  "#fffff",
  "#f5c754",
  "#7b7b7b",
  "#14459c",
  "#16449a",
  "#21a2ff",
  "#0b1746",
  "#f7931b",
];

const TradeHistory = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [getBTCPrice, setBTCPrice] = useState("");
  const [getETHPrice, setETHPrice] = useState("");
  const [getBNBPrice, setBNBPrice] = useState("");
  const [getUSDTPrice, setUSDTPrice] = useState("");
  const [getUSDCrice, setUSDCrice] = useState("");
  const [getPAXPrice, setPAXPrice] = useState("");
  const [getBUSDPrice, setBUSDPrice] = useState("");
  const [getGBPTPrice, setGBPTPrice] = useState("");
  const [getJPYCPrice, setJPYCPrice] = useState("");
  const [getEUROCPrice, setEUROCPrice] = useState("");
  const [getMATICPrice, setMATICPrice] = useState("");
  const [getCTYPrice, setCTYPrice] = useState("");
  const [getOPYPrice, setOPYPrice] = useState("");
  const [getFTYPrice, setFTYPrice] = useState("");
  const [getMETAPrice, setMETAPrice] = useState("");
  const [getLTXPrice, setLTXPrice] = useState("");
  const [getWENPrice, setWENPrice] = useState("");
  const [getSolanaPrice, setSolanaPrice] = useState("");
  const bnbb = `${process.env.PUBLIC_URL}/img/logo/bnbb.png`;
  const CustodyCoin = `${process.env.PUBLIC_URL}/img/logo/CustodyCoin.png`;
  const downloadmodified = `${process.env.PUBLIC_URL}/img/logo/download-modified.png`;
  const ethereum = `${process.env.PUBLIC_URL}/img/logo/ethereum.png`;
  const euroc = `${process.env.PUBLIC_URL}/img/logo/euroc.png`;
  const gbpt = `${process.env.PUBLIC_URL}/img/logo/gbpt.png`;
  const jpyc = `${process.env.PUBLIC_URL}/img/logo/jpyc.png`;
  const paxos = `${process.env.PUBLIC_URL}/img/logo/paxos.png`;
  const usdc = `${process.env.PUBLIC_URL}/img/logo/usdc.png`;
  const USDCoin = `${process.env.PUBLIC_URL}/img/logo/USD-Coin.png`;
  const usdt = `${process.env.PUBLIC_URL}/img/logo/usdt.png`;
  const Bitcoin = `${process.env.PUBLIC_URL}/img/logo/Bitcoin.png`;

  const { topTen, errorTopTen, isTopTenLoading } = useSelector((state) => ({
    topTen: state.topTen.data,
    errorTopTen: state.topTen.error,
    isTopTenLoading: state.topTen.isFetching,
  }));
  const topSix = topTen?.slice(0, 6);

  const refreshData = () => {
    dispatch(fetchTopTenCurrencies());
  };


  useEffect(() => {
    const fetchOPY = async () => {
      try {
        const response = await getOPY();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setOPYPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchFTY = async () => {
      try {
        const response = await getFTY();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setFTYPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchBTC = async () => {
      try {
        const response = await getBTC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setBTCPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchETH = async () => {
      try {
        const response = await getETH();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('setETHPrice', formattedData);
          setETHPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchBNB = async () => {
      try {
        const response = await getBNB();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setBNBPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchUSDT = async () => {
      try {
        const response = await getUSDT();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setUSDTPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchUSDC = async () => {
      try {
        const response = await getUSDC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('setUSDCrice', formattedData);
          setUSDCrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchPAX = async () => {
      try {
        const response = await getPAX();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setPAXPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchBUSD = async () => {
      try {
        const response = await getBUSD();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setBUSDPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchGBPT = async () => {
      try {
        const response = await getGBPT();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setGBPTPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchJPYC = async () => {
      try {
        const response = await getJPYC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setJPYCPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchEUROC = async () => {
      try {
        const response = await getEUROC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setEUROCPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchMATIC = async () => {
      try {
        const response = await getMATIC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setMATICPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchCTY = async () => {
      try {
        const response = await getCTY();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setCTYPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchMETA = async () => {
      try {
        const response = await getMETA();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          setMETAPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };

    const SolTokenPrice = async () => {
      try {
        const responseLTX = await getSolTokenPrice({ symbol: 'LTX' });
        if (responseLTX) {
          const dataLTX = responseLTX.data.coin_price;
          const formattedDataLTX = Number(dataLTX).toFixed(6);
          setLTXPrice(formattedDataLTX);
        } else {
          console.error("Error fetching LTX price:", responseLTX.statusText);
        }
    
        const responseWEN = await getSolTokenPrice({ symbol: 'WEN' });
        if (responseWEN) {
          const dataWEN = responseWEN.data.coin_price;
          const formattedDataWEN = Number(dataWEN);
          setWENPrice(formattedDataWEN);
        } else {
          console.error("Error fetching WEN price:", responseWEN.statusText);
        }
    
        const responseSolana = await getSolTokenPrice({ symbol: 'SOLANA' });
        if (responseSolana) {
          const dataSolana = responseSolana.data.coin_price;
          const formattedDataSolana = Number(dataSolana);
          setSolanaPrice(formattedDataSolana);
        } else {
          console.error("Error fetching Solana price:", responseSolana.statusText);
        }
      } catch (error) {
        console.error("Error fetching token prices:", error);
      }
    };
    

    fetchBTC();
    fetchETH();
    fetchBNB();
    fetchUSDT();
    fetchUSDC();
    fetchPAX();
    fetchBUSD();
    fetchGBPT();
    fetchJPYC();
    fetchEUROC();
    fetchMATIC();
    fetchCTY();
    fetchFTY();
    fetchOPY();
    fetchMETA();
    SolTokenPrice();
  }, []);

  return (
    <Panel
      xl={12}
      xs={12}
      className="mt-3 pb-2"
      title="Coins"
      subhead="Current Prices"
      isLoading={isTopTenLoading}
      refreshRequest={refreshData}
    >
      {/* <ErrorAlert subTitle="Quotes" error={errorTopTen} /> */}
      <Table responsive striped>
        <thead>
          <tr>
            <TradeHistoryTh style={{ textAlign: "justify" }}>Coins</TradeHistoryTh>
            {/* <TradeHistoryTh style={{ textAlign: "justify" }}>Symbol</TradeHistoryTh> */}
            <TradeHistoryTh style={{ textAlign: "justify" }}>
              Price
            </TradeHistoryTh>
            <TradeHistoryTh style={{ textAlign: "justify" }}>
              Contract Address
            </TradeHistoryTh>
          </tr>
        </thead>
        <tbody style={{ cursor: "pointer" }}>
          <tr>
            <td style={{ textAlign: "justify" }}>
              <DashboardCoin color={colors[0]}>
                <Bullet color={"blue"} /> CTY
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              CTY
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <b>$</b> {numeral(getCTYPrice).format('0,0.00')}
            </td>

            <td style={{ textAlign: "justify" }}>
              0xBa08Da6b46E3dd153DD8b66A6E4cfd37A6359559
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[11]}>
                <Bullet color={"yellow"} /> BTC
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              BTC
            </td> */}
            <td style={{ textAlign: "justify", width: 'max-content' }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getBTCPrice).format('0,0.00')}


              </span>
            </td>

            <td style={{ textAlign: "justify" }}>BTC</td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[6]}>
                <Bullet color={"red"} /> ETH
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              ETH
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getETHPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[5]}>

                <Bullet color={"blue"} /> OPY
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              ETH
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getOPYPrice).format('0,0.00')}

                 </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0x7a656f418afc09eaF4AE8b75EAe74fE09E7A7706
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[1]}>
                <Bullet color={"yellow"} /> FTY
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              ETH
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getFTYPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0x500f8c7a9eE51C5b6f6b8D836d84340d7272D84E
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[5]}>
                <Bullet color={"blue"} /> BNB
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              BNB
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getBNBPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[1]}>
                <Bullet color={"yellow"} /> USDT (BEP20)
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              USDT
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getUSDTPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0x55d398326f99059fF775485246999027B3197955
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[2]}>
                <Bullet color={"blue"} /> USDC
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              USDC
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getUSDCrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[3]}>
                <Bullet color={"red"} /> PAX
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              PAX
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getPAXPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0x8E870D67F660D95d5be530380D0eC0bd388289E1
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[4]}>
                <Bullet color={"green"} /> BUSD
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              BUSD
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getBUSDPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[9]}>
                <Bullet color={"yellow"} /> GBPT
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              GBPT
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getGBPTPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0x86B4dBE5D203e634a12364C0e428fa242A3FbA98
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[9]}>
                <Bullet color={"black"} /> JPYC
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              JPYC
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getJPYCPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[9]}>
                <Bullet color={"blue"} /> EUROC
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              EUROC
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getEUROCPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[9]}>
                <Bullet color={"green"} /> MATIC
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              POLYGON
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getMATICPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270{" "}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[1]}>
                <Bullet color={"yellow"} /> USDT (ERC20)
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              USDT
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getUSDTPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0xdAC17F958D2ee523a2206206994597C13D831ec7
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[1]}>
                <Bullet color={"green"} /> MET$
              </DashboardCoin>
            </td>
            {/* <td style={{ textAlign: "justify" }}>
              USDT
            </td> */}
            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getMETAPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              0xDd770074aa548B66Ab54CF0f6E67F9354E3b732e
            </td>
          </tr>

          {/* commenting untill api wait */}
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[3]}>
                <Bullet color={"grey"} /> LTX
              </DashboardCoin>
            </td>

            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getLTXPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              LTXH7nCGXz5TBZ57H8oZu7YwmDSVfSqWViW4B28yg8X
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[6]}>
                <Bullet color={"red"} /> WEN
              </DashboardCoin>
            </td>

            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getWENPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
              WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[9]}>
                <Bullet color={"black"} /> SOLANA
              </DashboardCoin>
            </td>

            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b> {numeral(getSolanaPrice).format('0,0.00')}

                </span>
            </td>

            <td style={{ textAlign: "justify" }}>
            So11111111111111111111111111111111111111112
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ textAlign: "justify" }} color={colors[9]}>
                <Bullet color={"green"} /> SoLpy
              </DashboardCoin>
            </td>

            <td style={{ textAlign: "justify" }}>
              <span style={{
                display: 'flex'
              }}>
                <b>$</b>0.00006 </span>
            </td>

            <td style={{ textAlign: "justify" }}>
            HoiKHfFDXBhTjuDS1KkUoCYmWUPsaaYyHxTospPephbc
            </td>
          </tr>
        </tbody>
      </Table>
    </Panel>
  );
};

export default TradeHistory;

// region STYLES

const DashboardCoin = styled.p`
  color: ${(props) => props.color};
  font-weight: 700;
`;

const TradeHistoryTh = styled.th``;

// endregion

const ProductItemLabel = styled.img`
  ${right}: 5px;
  width: 20px;
`;
