
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/home.css';
import {
  Col, Container, Row, Nav, NavDropdown, Navbar, Table,
} from 'react-bootstrap';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Ring } from '@uiball/loaders';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import Carousel from '@/shared/components/carousel/CarouselMultiply';
// import { CardBody } from '@/shared/components/Card';
import { colorAccent, colorAdditional, colorBackground } from '@/utils/palette';
import { right, left, marginRight } from '@/utils/directions';
import StarRating from './StarRating';
import HotStarRating from './HotStarRating';
import {
  getGlobalProducts,
  searchAllStore,
  getCategory,
  getHotProducts,
  flashHotProducts,
  getRecentHistory,
} from '../../../utils/services/user.service';
import '../style/Rating.css';
import '../style/storedetail.css';
import '../style/storedetailuser.css';
import '../style/homecard.css';


export default function HomeProducts() {
  const [productData, setProductData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const user = JSON.parse(localStorage?.getItem('user'))


  const [recentData, setRecentData] = useState([]);
  const [hotproductData, sethotProductData] = useState([]);
  const [flashproductData, setFlashProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(productData.length / 10);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [matchingProductsFoundCategory, setMatchingProductsFoundCategory] = useState([]);
  const [SelectedSubcategoryName, setSelectedSubcategoryName] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [slidesToShowSize, setSlidesToShowSize] = useState(6);
  const [sessionId, setSessionId] = useState(0);
  // console.log('productData', productData);
  const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
  const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;
  const OffTag = `${process.env.PUBLIC_URL}/img/for_store/catalog/off_tag_.png`;
  const NoImg = `${process.env.PUBLIC_URL}/img/no-img.png`;
  const NoImg1 = `${process.env.PUBLIC_URL}/img/No-img1.png`;

  // Define a function to remove duplicate colors
  function removeDuplicateColors(product) {
    const seenColors = new Set();
    // eslint-disable-next-line no-param-reassign
    product.product_sizes_quantity = product.product_sizes_quantity.filter((item) => {
      if (!seenColors.has(item.color)) {
        seenColors.add(item.color);
        return true;
      }
      return false;
    });
  }

  // filter user data to avoid user own products list 
  useEffect(()=>{
    const filteredData = productData.filter(
      (item) => item.userId !== user.data.user.user_id
    );

    setFilteredProducts(filteredData);
  },[productData])

  // Iterate through each product and remove duplicate colors
  if (productData.length > 0) {
    productData.forEach((product) => {
      removeDuplicateColors(product);
    });
  }

  const fetchData = async () => {
    try {
      const requestData = {
        limit: 20,
        pageNumber: currentPage,
      };
      setIsLoading(true);
      const response = await getGlobalProducts(requestData);
      // console.log('response', response);

      if (!response.data.globalProducts) {
        throw new Error('No data received');
      }

      // console.log('API Response Data:', response.data.result);

      setProductData(response.data.globalProducts);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const fetchRecentHistory = async () => {
    try {
      const requestData = {
        session_id: localStorage.getItem('storeSessioId'),
      };
      const response = await getRecentHistory(requestData);

      if (response.data.status) {
        // console.log('getRecentHistory Response Data : ', response.data); 
        setRecentData(response.data.recentProducts);
      } else {
        console.error('No category data received');
      }
    } catch (error) {
      console.error('Error fetching getRecentHistory:', error);
    }
  };


  useEffect(() => {
    fetchData(); // Load initial data
    fetchRecentHistory(); // Load initial data
  }, [currentPage]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleArrowNavigation = (direction) => {
    if (direction === 'prev') {
      handlePageChange(Math.max(currentPage - 1, 1));
    } else if (direction === 'next') {
      handlePageChange(Math.min(currentPage + 1, totalPages));
    }
  };



  useEffect(() => {
    if (localStorage.getItem('storeSessioId') === null) {
      const sessionIdValue = Math.random().toString(36).substring(2, 15);
      setSessionId(sessionIdValue);
      localStorage.setItem('storeSessioId', sessionIdValue);
    }

    const intervalId = setInterval(() => {
      if (window.innerWidth < 600) {
        setSlidesToShowSize(3);
      } else {
        setSlidesToShowSize(6);
      }
    }, 2000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  useEffect(() => {
    // Fetch category data from your API
    const fetchCategories = async () => {
      try {
        const response = await getCategory();

        if (response.data.status) {
          // console.log('Set Categories Response Data : ', response.data); 
          setCategories(response.data.category);
        } else {
          console.error('No category data received');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchhotProducts = async () => {
      try {
        const response = await getHotProducts();
        if (response.data) {
          const hotProductsData = response.data.hotProducts;
          // console.log('getHotProducts >>>>>>>>>>>>>>>>> ', hotProductsData);
          sethotProductData(hotProductsData);
        } else {
          console.error('Error fetching getHotProducts:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching getHotProducts:', error);
      }
    };

    const fetchflashProducts = async () => {
      try {
        const response = await flashHotProducts();
        if (response.data) {
          const flashProductsData = response.data.sale;
          // console.log('setFlashProductData >>>>>>>>>>>>>>>>> ', flashProductsData);
          setFlashProductData(flashProductsData);
        } else {
          console.error('Error fetching getHotProducts:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching getHotProducts:', error);
      }
    };

    fetchCategories();
    fetchhotProducts();
    fetchflashProducts();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: slidesToShowSize,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: 'linear',
  };

  // console.log('sessionIdsessionId sessionIdsessionId sessionIdsessionId sessionIdsessionId', sessionId);

  const fetchAllData = async () => {
    const requestData = {
      limit: 10,
      pageNumber: 1,
    };
    try {
      setIsLoading(true);
      const response = await getGlobalProducts(requestData);
      if (response.data && response.data.globalProducts.length > 0) {
        // console.log('Products ? ?? ?', response.data.globalProducts);
        setProductData(response.data.globalProducts);
        console.error('Not Found54656464', setProductData);
        totalPages(response.data.totalPages); // Set total pages here
        setIsLoading(false);
      } else {
        console.error('Not Found');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching store data:', error);
    }
  };


  const handleSearch = async () => {
    try {
      const requestData = {
        name: searchTerm, // Send the search query to the API
        sub_category_id: '',
        pageNumber: 1,
        limit: 10,
        session_id: localStorage.getItem('storeSessioId'),
      };

      const response = await searchAllStore(requestData);
      // console.log('response', response);
      // console.log('response.data.result', response.data.result);

      if (response.data.result) {
        setProductData(response.data.result);
      } else if (searchTerm.trim() === '') {
        fetchAllData(1); // Call fetchAllData when searchTerm is empty
      } else {
        setMatchingProductsFoundCategory([]); // No matching products found
      }
    } catch (error) {
      fetchAllData(1);
      console.error('Error fetching search results:', error);
    }
  };
  // Trigger the search when searchQuery changes

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubcategoryClick = async (subcategoryID) => {
    try {
      const requestData = {
        name: '',
        sub_category_id: subcategoryID, // Send the selected category ID to the API
        pageNumber: 1,
        limit: 10,
      };
      setIsLoading(true);
      const response = await searchAllStore(requestData);
      if (response.data.result) {
        const data = response.data.result;
        setProductData(data);
        setSelectedSubcategoryName(subcategoryID); // Store the selected category ID
        setIsLoading(false);
      } else {
        setProductData([]); // No matching products found
      }
    } catch (error) {
      setIsLoading(false);
      // console.error('Error fetching stores >>>>>>> ', error.response.data.Message);
      if (error.response.data.Message === 'Product not found') {
        setProductData([]);
        console.error('Error setMatchingProductsFoundCategory stores >>>>>>> 11 ', matchingProductsFoundCategory);
      }
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="container">
        <div className="row px-3">
          <div className="col-lg-2 text-center mt-1">
            <Navbar className="text-center" expand="sm">
              <Navbar.Collapse className="show w-100" id="my-nav">
                <Nav className="text-center w-100" style={{ borderRadius: '5px', border: '2px solid #b1b1b1', padding: '0px' }}>
                  <NavDropdown className="text-center w-100" title={<span style={{ color: '#b1b1b1', fontSize: 'large' }}>Categories&nbsp;</span>} id="my-nav">
                    {categories.map((category, index) => (
                      <React.Fragment key={index}>
                        <NavDropdown
                          title={`\u00a0\u00a0${category.category_name}\u00a0`}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          className={isHovered ? 'hovered-dropdown' : ''}
                          menuAlign="right"
                        >
                          {category.sub_categories.length > 0 && (
                            category.sub_categories.map(subcategory => (
                              <NavDropdown.Item
                                onMouseEnter={handleMouseEnter1}
                                onMouseLeave={handleMouseLeave1}
                                className={isHovered1 ? 'hovered-dropdown1' : ''}
                                onClick={() => handleSubcategoryClick(subcategory._id)}
                                style={{ textTransform: 'capitalize' }}
                                key={subcategory.id}
                              >{subcategory.sub_category_name}
                              </NavDropdown.Item>
                            ))
                          )}
                        </NavDropdown>
                        {index < categories.length - 1 && <NavDropdown.Divider style={{ margin: '0px 0px' }} />}
                      </React.Fragment>
                    ))}
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          <div className="col-lg-3 text-center mt-1" hidden>
            <ul className="navbar-nav ms-auto">

              <li className="nav-item dropdown">
                <button
                  className="nav-link btn btn-link dropdown-toggle catbtn text-dark"
                  type="button"
                  id="navbarDropdownMenuLinkRight"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  Cateogories
                </button>
                <ul
                  className="dropdown-menu w-100"
                  aria-labelledby="navbarDropdownMenuLinkRight"
                >
                  <button type="button" className="allbtn" onClick={() => fetchAllData()}>All</button>
                  {categories.map(category => (
                    <li key={category._id}>
                      <button
                        className="dropdown-item btn btn-link"
                        type="button"
                        onClick={() => setSelectedSubcategory({
                          id: category._id,
                          name: category.category_name,
                        })}
                      >
                        {category.category_name}
                      </button>
                      {category.sub_categories.length > 0 && (
                        <ul className="dropdown-menu dropdown-submenu dropdown-submenu-right">
                          {category.sub_categories.map(subcategory => (
                            <li key={subcategory._id}>
                              <button
                                className="dropdown-item btn btn-link"
                                type="button"
                                onClick={() => handleSubcategoryClick(subcategory._id)}
                              >
                                {subcategory.sub_category_name}
                              </button>

                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-lg-10 text-center mt-2">
            <InputDiv>
              <ChatSearchInput
                className="mt-2 form-control"
                type="text"
                placeholder="&#128269; Search Products..."
                value={searchTerm}
                onChange={(e) => {
                  handleInputChange(e);
                  handleSearch(); // Call the handleSearch function here
                }}
              />
            </InputDiv>
            <input
              hidden
              className="w-100 form-control"
              type="text"
              placeholder="Search Products..."
              value={searchTerm}
              onChange={(e) => {
                handleInputChange(e);
                handleSearch(); // Call the handleSearch function here
              }}
            />
          </div>
        </div>
        {hotproductData.length > 5 && (
          <div className="row mt-4 mb-0">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="mb-2 mx-3" style={{ fontSize: '20px' }}> Hot Products </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-3">
              <div className="mx-3" style={{ background: '#232329' }}>
                <Slider {...settings}>
                  {hotproductData.map(product => (
                  <Link to={`/productdetails/?color=${product?.product_sizes_quantity[0].color}&brand=${encodeURIComponent(product.brand)}&title=${encodeURIComponent(product.title)}&id=${encodeURIComponent(product._id)}`}>
                  <div key={product.id} className="productCard p-1">
                        <div className="square-img-wrapper mt-1">
                          <img
                            src={product.pictures.length > 0 ? product.pictures[0] : NoImg1}
                            alt={`slide_${product.pictures[0]}`}
                            className="card-img-top square-img p-3 zoom-in-on-hover"
                          />
                        </div>
                        {product.title.length > 20 ? (
                          <h5
                            style={{ textTransform: 'capitalize' }}
                            className="card-title producttitle mt-0 mb-0"
                          >{product.title.substring(0, 20)}...
                          </h5>
                        ) : (
                          <h5
                            style={{ textTransform: 'capitalize' }}
                            className="card-title producttitle mt-0 mb-0"
                          >{product.title}
                          </h5>
                        )}
                        <p className="card-title productPrice mb-0" style={{ fontSize: 'smaller' }}>
                          {product.product_sizes_quantity[0].price} {product.symbol}
                        </p>
                        <div className="row text-text-center">
                          <div className="Rating mb-2" style={{ float: 'left', marginTop: '0%' }}>
                            {product.ProductRatting?.length > 0 ? (
                              <HotStarRating rating={product.ProductRatting[0]?.averageRating} />
                            ) : (
                              <HotStarRating rating={0} />
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        )}
        {flashproductData.length > 5 && (
          <div className="row mt-1 mb-0">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="mb-2 mx-3" style={{ fontSize: '20px' }}> Flash Sale </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
              <div className="mx-3" style={{ background: '#232329' }}>
                <Slider {...settings}>
                  {flashproductData.map(item => (
                    <Link to={`/productdetails/${item._id}`}>
                      <div key={item.id} className="productCard p-1">
                        <div className="square-img-wrapper mt-1">
                          <img
                            src={item.pictures.length > 0 ? item.pictures[0] : NoImg1}
                            alt={`slide_${item.pictures[0]}`}
                            className="card-img-top square-img p-3 zoom-in-on-hover"
                          />
                        </div>
                        {item.title.length > 20 ? (
                          <h5
                            style={{ textTransform: 'capitalize' }}
                            className="card-title producttitle mt-0 mb-0"
                          >{item.title.substring(0, 20)}...
                          </h5>
                        ) : (
                          <h5
                            style={{ textTransform: 'capitalize' }}
                            className="card-title producttitle mt-0 mb-0"
                          >{item.title}
                          </h5>
                        )}
                        <p className="card-title productPrice mb-0" style={{ fontSize: 'smaller' }}>
                          {item.product_sizes_quantity[0].price} {item.symbol}
                        </p>
                        <div className="row text-text-center">
                          <div className="Rating mb-2" style={{ float: 'left', marginTop: '0%' }}>
                            {item.ProductRatting?.length > 0 ? (
                              <HotStarRating rating={item.ProductRatting[0]?.averageRating} />
                            ) : (
                              <HotStarRating rating={0} />
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        )}
        <Container className="text-white">
          <Row xs={12} md={12} lg={12}>
            <Col className="py-1">
              <h2 className="mb-0 mt-0 d-none" style={{ fontSize: 'x-large' }}> Products </h2>
            </Col>
          </Row>
          <Row xs={2} md={3} lg={4} className="mt-2 mb-2">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product, index) => (
                <Col
                  style={{ textTransform: 'capitalize' }}
                  key={product._id}
                >
                  <Link to={`/productdetails/?color=${product?.product_sizes_quantity[0].color}&brand=${encodeURIComponent(product.brand)}&title=${encodeURIComponent(product.title)}&id=${encodeURIComponent(product._id)}`}>
                    <div className="card productCard mt-2" style={{ background: '#232329' }}>
                      {
                        product.product_sizes_quantity.some(item => item.discount > 0) ? (
                          <ProductItemLabel src={OffTag} alt="new" />
                        ) : (
                          <ProductItemLabel hidden src={OffTag} alt="new" />
                        )
                      }
                      <div className="square-img-wrapper mt-4">
                        <img
                          src={product.pictures.length > 0 ? product.pictures[0] : NoImg1}
                          className="card-img-top square-img p-3 border-radius zoom-in-on-hover"
                          alt={product.storeName}
                        />
                      </div>
                      <div className="card-body">
                        <Row>
                          <Col xs={12} md={7} lg={7}>
                            <h5 className="card-title producttitle mt-0 mb-0">{product.title}</h5>
                          </Col>
                          <Col xs={12} md={5} lg={5}>
                            <p className="card-title productPrice mb-0 float-right">
                              {product.product_sizes_quantity[0].price} {product.symbol}
                            </p>
                          </Col>
                        </Row>
                        <span className="d-none">
                          <span style={{ width: '100%' }}>
                            <h5 className="card-title producttitle mt-0 mb-1">{product.title}</h5>
                          </span>
                          <span style={{ float: 'right', width: '40%' }}>
                            <p
                              className="card-title productPrice mb-0 float-right"
                            >
                              {product.product_sizes_quantity[0].price} {product.symbol}
                            </p>
                          </span>
                        </span>
                        <span>
                          {product.product_sizes_quantity.slice(0, 3).map(color => (
                            <ProductItemColor
                              style={{ background: color.color }}
                            />
                          ))}
                        </span>
                        <div className="Rating mb-4" style={{ float: 'right', marginTop: '1%' }}>
                          {product.ProductRatting?.length > 0 ? (
                            <StarRating rating={product.ProductRatting[0]?.averageRating} />
                          ) : (
                            <StarRating rating={0} />
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              ))
            ) : (
              <div className="text-center">
                <p className="text-center">No Products Available.</p>
              </div>
            )}
          </Row>
        </Container>
        {recentData.length > 0 && (
          <Container className="text-white">
            <Row xs={12} md={12} lg={12}>
              <Col>
                <h2 className="mb-0 mt-0" style={{ fontSize: '20px' }}> Recent Search </h2>
              </Col>
            </Row>
            <Row xs={2} md={3} lg={6} className="mt-2 mb-4">
              {recentData.slice(0, 6).map((product, index) => (
                <Col
                  style={{ textTransform: 'capitalize' }}
                  key={product._id}
                >
                  <Link to={`/productdetails/?color=${product?.product_sizes_quantity[0].color}&brand=${encodeURIComponent(product.brand)}&title=${encodeURIComponent(product.title)}&id=${encodeURIComponent(product._id)}`}>
                    <div className="card productCard mt-1" style={{ background: '#232329' }}>
                      {product.product_sizes_quantity[0].discount
                        && product.product_sizes_quantity[0].discount > 0 && (
                          <ProductItemLabel src={OffTag} alt="new" />
                        )}
                      <div className="square-img-wrapper mt-4">
                        <img
                          src={product.pictures.length > 0 ? product.pictures[0] : NoImg1}
                          className="card-img-top square-img p-3 border-radius zoom-in-on-hover"
                          alt={product.storeName}
                        />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title producttitle mt-0 mb-1">{product.title}</h5>
                        <p className="card-title productPrice mb-0">
                          {product.product_sizes_quantity[0].price} {product.symbol}
                        </p>
                        <div className="Rating mb-4" style={{ float: 'right', marginTop: '1%' }}>
                          {product.ProductRatting?.length > 0 ? (
                            <StarRating rating={product.ProductRatting[0]?.averageRating} />
                          ) : (
                            <StarRating rating={0} />
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        )}

        <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-4" hidden>
          <Card className="customCard">
            <CardBody>
              <Carousel>
                {hotproductData.map(item => (
                  <div key={item.id} className="hotproductData p-1">
                    <div className="square-img-wrapper mt-4">
                      <img
                        src={item.pictures[0]}
                        alt={`slide_${item.pictures[0]}`}
                        className="card-img-top square-img"
                      />
                    </div>
                    {item.title.length > 20 ? (
                      <h5
                        style={{ textTransform: 'capitalize' }}
                        className="card-title producttitle mt-2 mb-0"
                      >{item.title.substring(0, 20)}...
                      </h5>
                    ) : (
                      <h5
                        style={{ textTransform: 'capitalize' }}
                        className="card-title producttitle mt-2 mb-0"
                      >{item.title}
                      </h5>
                    )}
                    <p className="card-title productPrice mb-0">
                      {item.product_sizes_quantity[0].price} {item.symbol}
                    </p>
                    <div className="row">
                      <div className="Rating mb-4" style={{ float: 'right', marginTop: '1%' }}>
                        {item.ProductRatting?.length > 0 ? (
                          <HotStarRating rating={item.ProductRatting[0]?.averageRating} />
                        ) : (
                          <HotStarRating rating={0} />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </CardBody>
          </Card>
        </div>
        {productData.length > 0 && (
          <div className="pagination-container">
            <div className="pagination mb-4">
              <button
                style={{ textDecoration: 'none' }}
                className="btn btn-link"
                type="button"
                disabled={currentPage === 1}
                onClick={() => handleArrowNavigation('prev')}
              >
                &lt; Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                <button
                  key={pageNumber}
                  type="button"
                  className={`mx-1 btn ${currentPage === pageNumber ? 'btn-primary' : 'btn-secondary'}`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <button
                style={{ textDecoration: 'none' }}
                className="btn btn-link"
                type="button"
                disabled={currentPage === totalPages}
                onClick={() => handleArrowNavigation('next')}
              >
                Next &gt;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

// region STYLES


const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 20px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 2px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
`;



const ProductItemColor = styled.span`
  height: 15px;
  width: 15px;
  ${marginRight}: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 10px;
`;

const ProductItemLabel = styled.img`
  position: absolute;
  top: -10px;
  ${right}: 5px;
  width: 65px;
`;
