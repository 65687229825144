import { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubAccountsTable from "./SubAccountsTable";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import GetMySubAccountsTable from "./GetMySubAccountsTable";

const SubAccounts = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const [selectedTab, setSelectedTab] = useState("home"); // Track selected tab


  const handleTabChange = async (selectedKey) => {
    setSelectedTab(selectedKey); // Update selected tab
    // setReFetch(!reFetch); // Trigger data refetch
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t("Sub-Accounts")}</h3>
          {loading && <Spinner style={{ color: "#4ce1b6" }} />}
        </Col>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Tabs
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="mb-3 mt-4"
              onSelect={handleTabChange} // Call handleTabChange when a tab is changed
              activeKey={selectedTab}
            >
              <Tab
                eventKey="home"
                ClassName="text-white"
                title="Accounts Requests "
              >
                <SubAccountsTable selectedTab={selectedTab} />
              </Tab>
              <Tab
                eventKey="Profile"
                ClassName="text-white"
                title="Sub Accounts"
              >
                <GetMySubAccountsTable selectedTab={selectedTab} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </Row>
    </>
  );
};
export default SubAccounts;
