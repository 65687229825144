import React, { useEffect, useState } from "react";
// import Modal from 'react-modal';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Badge, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

import { toast } from "react-toastify";
import { Ring } from "@uiball/loaders";
import Panel from "@/shared/components/Panel";
import "react-toastify/dist/ReactToastify.css";
import {
  getCustomOffers,
  rejectCustomOffer,
  acceptCustomOffer,
} from "../../../utils/services/user.service";

import "../style/getorder.css";
import "../style/getorderasbuyer.css";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import OfferModal from "./OfferModal";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_URL } from "../../../utils/services/user.service";
import axios from "axios";

const CustomOffer = () => {
  const [myCustomOffers, setMyCustomOffers] = useState([]);
  const [getTransactions1, setTransactions1] = useState([]);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [selectedOrderNo, setSelectedOrderNo] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");
  const [isSearchOrderNo, setIsSearchOrderNo] = useState(false);
  const [isSearchAddress, setIsSearchAddress] = useState(false);
  const [isSearchStatus, setIsSearchStatus] = useState(false);
  const [isSearchPaymentStatus, setIsSearchPaymentStatus] = useState(false);
  const [isSearchApprovalStatus, setIsSearchApprovalStatus] = useState(false);
  const [isSearchOrderStatus, setIsSearchOrderStatus] = useState(false);
  const [isSearchAmount, setIsSearchAmount] = useState(false);
  const [isSearchOperation, setIsSearchOperation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState(myCustomOffers);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("all");
  const [offerId, setOfferId] = useState("");

  const loggedInUser = JSON.parse(localStorage.getItem("user"))?.data?.user;

  const fetchOrders = async () => {
    // Check if offerId is valid before making the API call
    if (offerId.length < 23 && parseInt(offerId) > 0) {
      // If offerId is not valid, simply return without calling the API
      return;
    }

    const payload = {
      page_number: 1,
      limit: 10,
      offer_status: selectedPaymentStatus,
      offer_id: offerId,
    };

    setIsLoading(true);
    try {
      const response = await getCustomOffers(payload);
      const responseData = response.data?.data;
      setMyCustomOffers(responseData);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [selectedPaymentStatus, offerId]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleSearchFilterChange = (value) => {
    if (value.value === "order_no") {
      setIsSearchOrderNo(true);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "amount") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(true);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "payment_status") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(true);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "approval_status") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(true);
      setIsSearchOrderStatus(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "order_status") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(true);
      setSelectedSearch(value.value);
    }
  };
  const handleOrderNoChange = (value) => {
    const partialValue = value.target.value;

    setOfferId(partialValue);
  };

  const handlePaymentStatusChange = (value) => {
    setSelectedPaymentStatus(value.value);
  };

  const optionPaymentStatus = [
    { value: "all", label: "all" },
    { value: "active", label: "active" },
    { value: "reject", label: "Rejected" },
  ];

  const resetData = (order) => {
    setIsLoading(true);
    setFilteredOffers(myCustomOffers);
    setIsLoading(false);
  };
  const searchFilters = [
    { value: "order_no", label: "Order No" },
    { value: "address", label: "Delivery Address" },
    { value: "amount", label: "Amount" },
    { value: "payment_status", label: "Payment Status" },
    { value: "approval_status", label: "Approval Status" },
    { value: "order_status", label: "Order Status" },
  ];

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    isPagination: true,
  };
  const header = [
    { accessor: "order_no", Header: " Offer No." },
    { accessor: "quantity", Header: "Quantity" },
    { accessor: "address", Header: "Delivery Address" },
    { accessor: "custom_offer_amount", Header: " Offer Amount" },
    { accessor: "orignal_price", Header: "Original Price" },
    { accessor: "product_id", Header: "Product Id" },
    { accessor: "offer_status", Header: "Offer Status" },
    {
      accessor: "action",
      Header: "Action",
      disableGlobalFilter: true,
      width: 80,
    },
  ];
  const handleAcceptCustomOffer = async (item) => {
    setIsLoading(true);
    try {
      const res = await acceptCustomOffer({ offer_id: item?._id });
      toast.success(res?.data?.Message);
      fetchOrders();
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data.Message);
    }
  };

  const handleRejectCustomOffer = async (item) => {
    setIsLoading(true);
    try {
      const res = await rejectCustomOffer({ offer_id: item?._id });
      toast.success(res?.data?.Message);
      fetchOrders();
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  };

  const data = [];
  myCustomOffers.forEach((item) => {
    data.push({
      order_no: item._id,
      quantity: item?.total_quantity,
      address:
        item?.delivery_address?.length > 15
          ? `${item.delivery_address.substring(0, 12)}...`
          : item?.delivery_address,
      custom_offer_amount: item.custom_offer_amount,
      orignal_price: item?.orignal_price,
      product_id: item?.product_id,
      offer_status: (
        <Badge
          bg={item?.offer_status == "active" ? "success" : "danger"}
          className="mb-2"
          style={{
            paddingBottom: "6px",
            fontSize: "small",
            fontWeight: "400",
          }}
        >
          {item?.offer_status}
        </Badge>
      ),

      action: (
        <HashLink>
          <div className="d-flex gap-1 justify-content-center">
            <button
              className={`btn btn-success success-button btn-sm shadow-none rounded-circle ${
                item?.seller_user_id == loggedInUser?.user_id &&
                item?.offer_status == "active"
                  ? "d-flex"
                  : "d-none"
              }`}
              type="button"
              onClick={() => handleAcceptCustomOffer(item)}
            >
              <i className="fa-solid fa-check py-1 text-[16px]"></i>
            </button>

            <button
              className={`btn btn-danger danger-button btn-sm  shadow-none rounded-circle ${
                item?.seller_user_id == loggedInUser?.user_id &&
                item?.offer_status == "active"
                  ? "d-flex"
                  : "d-none"
              }`}
              type="button"
              onClick={() => handleRejectCustomOffer(item)}
            >
              <i className="fa-solid fa-xmark py-1 text-[16px]"></i>
            </button>

            <button
              className="btn btn-primary purple-button d-flex align-items-center justify-content-center btn-sm shadow-none rounded-circle"
              type="button"
              onClick={() => {
                setModalIsOpen(true);
                setModalData(item);
              }}
            >
              <i
                className="fa fa-eye  text-[16px]"
                aria-hidden="true"
                style={{ paddingTop: "6px", paddingBottom: "6px" }}
              ></i>
            </button>
          </div>
        </HashLink>
      ),
    });
  });

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel lg={12} title="Custom Offers" refreshRequest={() => fetchOrders()}>
        <Row className="mb-5">
          <Col xs={12} md={4} lg={3} hidden>
            <label htmlFor="features" className="form-label text-white mb-0">
              Search Filter
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedSearch}
                  onChange={handleSearchFilterChange}
                  options={searchFilters}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>

          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Offer No
              </label>
              <ChatSearchInput
                type="text"
                value={offerId}
                className="form-control mt-2"
                placeholder="Search by Order No"
                onChange={handleOrderNoChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>

          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0"
            >
              Offer Status
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedPaymentStatus}
                  onChange={handlePaymentStatusChange}
                  options={optionPaymentStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>

          <Col xs={12} md={4} lg={3}>
            <label
              htmlFor="color"
              style={{ fontSize: "small", color: "transparent" }}
              className="form-label mb-0"
            >
              .
            </label>
            <Button
              onClick={() => resetData(1)}
              style={{
                background: "#4ce1b6",
              }}
              size="md"
              className="py-1 btn-block mt-2"
              variant="success"
            >
              Reset <i className="fa fa-refresh" />
            </Button>
          </Col>
          {/* )}       */}
          {!isSearchApprovalStatus &&
            !isSearchOrderNo &&
            !isSearchStatus &&
            !isSearchAddress &&
            !isSearchPaymentStatus &&
            !isSearchOrderStatus &&
            !isSearchAmount &&
            !isSearchOperation && <Col xs={12} md={4} lg={3} />}
          <Col xs={12} md={4} lg={4} />
          <Col xs={12} md={4} lg={2} hidden>
            <label
              htmlFor="color"
              style={{ fontSize: "small", color: "transparent" }}
              className="form-label mb-0"
            >
              .
            </label>
            <Button
              variant="primary"
              className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"
              onClick={() => resetData(1)}
            >
              Reset <i className="fa fa-refresh" />
            </Button>
          </Col>
        </Row>

        <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfig}
        />

        {modalIsOpen && (
          <OfferModal
            modalIsOpen={modalIsOpen}
            modalIsClose={handleCloseModal}
            data={modalData}
          />
        )}
      </Panel>
    </>
  );
};

export default CustomOffer;

const HashLink = styled.button`
  border: none;
  background: transparent;
  display: flex;
  color: #4ce1b6;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
