/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col, Container, Row, Spinner,
} from 'react-bootstrap';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import Panel from '@/shared/components/Panel';
import Tooltip from '@/shared/components/Tooltip';
import { getShortCryptoAddress } from '@/utils/helpers';
import {
    WalletInfoWrapper,
    WalletStatRowTitle,
    WalletStatRowValue,
    WalletStatRowWrapper,
} from '../styled';
import { SidebarLinkIcon } from '../../../Layout/components/sidebar/SidebarLink';
import { Button } from "@/shared/components/Button";

import { colorAccent } from '../../../../utils/palette';
import { blockOrUnblockCard, createCard, getAllCards, getAllWallets, getContractAmount, getReceiveAmount, getStrigaWalletAddress, getTotalBalance, updateCardPassword } from '../../../../utils/services/user.service';
import numeral from 'numeral';
import { Switch } from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment';

const StrigaWalletInfo = ({ dir, subWallet, userInfoData }) => {
    const { t } = useTranslation('common');

    const onCopyWalletAddress = (item) => {
        navigator.clipboard.writeText(item);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [sendAmount, setsendAmount] = useState([]);
    const [receiveAmount, setreceiveAmount] = useState([]);
    const [strigaWalletDetail, setStrigaWalletDetail] = useState(null);
    const [strigaTotalBalance, setStrigatotalBalance] = useState(0);
    const [strigaWAlletAddress, setStrigaWalletAddress] = useState(null)
    const [cardData, setCardData] = useState(null)
    const [checked, setChecked] = useState(true);
    const [physicalCardData, setPhysicalCardData] = useState(null)
    const [physicalChecked, setPhysicalChecked] = useState(true);
    const [bankPassword, setBankPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [ChangePassword, setChangePassword] = useState(false);


    const getBankCardDetails = async () => {
        setIsLoading(true);
        try {
            const res = await getAllCards();
            const data = res?.data?.cards;

            // Find the first physical card
            const physicalCard = data.find(card => card.type === 'PHYSICAL');
            if (physicalCard) {
                setPhysicalCardData(physicalCard);
                setPhysicalChecked(physicalCard.status === "CREATED" ? true : false);
            }

            // Find the first virtual card
            const virtualCard = data.find(card => card.type === 'VIRTUAL');
            if (virtualCard) {
                setCardData(virtualCard);
                setChecked(virtualCard?.status === "ACTIVE" ? true : false);
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getBankCardDetails()

    }, [])

    const formatCardNumber = (number) => {
        const visibleDigits = number?.replace(/\*/g, '*'); // Replace * with • for visibility
        const groups = [];
        let startIndex = 0;

        // Split into groups of 4 characters or less
        while (startIndex < visibleDigits.length) {
            groups.push(visibleDigits.substring(startIndex, startIndex + 4));
            startIndex += 4;
        }

        return groups;
    };

    const formattedCardNumber = formatCardNumber(cardData?.maskedCardNumber || "");
    const formattedPhysicalCardNumber = formatCardNumber(physicalCardData?.maskedCardNumber || "");


    const setBlockOrUnblockCard = async (val, id) => {

        setIsLoading(true)
        try {
            const payload = {
                "status": val == true ? "unblock" : "block",
                "cardId": id
            }
            const res = await blockOrUnblockCard(payload)
            toast.success(res?.data?.Message)
            getBankCardDetails()
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
        }
    }
    const handleChangePassword = async () => {
        setIsLoading(true)
        try {
            const payload = {
                password: newPassword,
                cardId: cardData?.id
            }
            const res = await updateCardPassword(payload)
            toast.success(res?.data?.Message)
            getBankCardDetails()
            setIsLoading(false)
            setChangePassword(false)

        } catch (error) {
            toast.error(error?.response?.data?.Message || error?.response?.data?.message)
            setIsLoading(false)


        }
    }


    const handleChange = (event, id) => {
        setChecked(event.target.checked);
        setBlockOrUnblockCard(event.target.checked, id)
    };
    const handlePhysicalChange = (event, id) => {
        setChecked(event.target.checked);
        setBlockOrUnblockCard(event.target.checked, id)
    };

    const handleCreateBankCard = async (val) => {
        setIsLoading(true)
        try {
            const payload = {
                password: bankPassword,
                type: val
            }
            const res = await createCard(payload)
            toast.success(res?.data?.Message)

            getBankCardDetails()
            setIsLoading(false)

        } catch (error) {
            console.log("🚀 ~ handleCreateBankCard ~ error:", error)
            toast.error(error?.response?.data?.Message || error?.response?.data?.message)
            setIsLoading(false)


        }
    }

    const fetchData1 = async () => {
        getWalletDetail()
    };
    useEffect(() => {
        getWalletDetail();
    }, []);

    const getWalletDetail = async () => {
        setIsLoading(true);
        try {
            const res = await getAllWallets();
            setStrigaWalletDetail(res?.data);
            setIsLoading(false);
        } catch (error) {
            console.log("🚀 ~ getWalletDetail ~ error:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let totalBalance = 0;

        if (strigaWalletDetail?.wallets?.length) {
            strigaWalletDetail.wallets.forEach((wallet) => {
                // Ensure wallet.accounts is an array
                const accounts = Object.values(wallet.accounts);
                accounts.forEach((account) => {
                    if (account?.availableBalance?.hAmount) {
                        totalBalance += account?.availableBalance?.hAmount * account?.availableBalance?.rate;
                    }
                });
            });
        }
        setStrigatotalBalance(Number(totalBalance))
        getStrigaWallet()
    }, [strigaWalletDetail]);



    const getStrigaWallet = async () => {
        const payload = {
            "striga_user_id": subWallet
        }
        const res = await getStrigaWalletAddress(payload)
        setStrigaWalletAddress(res?.data?.blockchainDepositAddress)

    }


    return (
        <Panel xs={12} lg={6} xxl={3} title={t('wallet.page_title')} isLoading={isLoading} refreshRequest={() => fetchData1()}>
            <WalletInfoWrapper>
                <WalletStatRowWrapper>
                    <h4> <b> {!subWallet ? 'Virtual Wallet' : "Bank Wallet"}</b></h4>
                    {!subWallet && <>
                        <WalletStatRowTitle>{t('wallet.address')}</WalletStatRowTitle><WalletStatRowValue variant="small">
                            <span>{getShortCryptoAddress(JSON.parse(localStorage.getItem('user'))?.data?.user?.address) || "--"}</span>
                            <Tooltip text="Copy wallet address" dir={dir} placement="top">
                                <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(JSON.parse(localStorage.getItem('user')).data.user.address)} />
                            </Tooltip>
                        </WalletStatRowValue>
                    </>}

                    {strigaWAlletAddress &&
                        <><WalletStatRowTitle> Wallet Address</WalletStatRowTitle><WalletStatRowValue variant="small">
                            <span>{strigaWAlletAddress?.slice(0, 15)}...{strigaWAlletAddress?.slice(-3)}</span>
                            <Tooltip text="Copy wallet address" dir={dir} placement="top">
                                <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(strigaWAlletAddress)} />
                            </Tooltip>
                        </WalletStatRowValue></>}
                </WalletStatRowWrapper>
                {userInfoData?.striga_iban_no &&
                    <><WalletStatRowTitle>Bank IBAN</WalletStatRowTitle><WalletStatRowValue variant="small">
                        <span>{userInfoData?.striga_iban_no?.slice(0, 15)}...{userInfoData?.striga_iban_no?.slice(-3)}</span>
                        <Tooltip text="Copy wallet address" dir={dir} placement="top">
                            <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(userInfoData?.striga_iban_no)} />
                        </Tooltip>
                    </WalletStatRowValue></>}

                {subWallet && <WalletStatRowWrapper>
                    <WalletStatRowTitle> Wallet Balance</WalletStatRowTitle>
                    <WalletStatRowValue>

                        {`${numeral(strigaTotalBalance).format('0,0.00') || 0} €`}

                    </WalletStatRowValue>
                </WalletStatRowWrapper>}


                {
                    !cardData ? (
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <div className="col-12 mt-1">
                                <InputDiv>
                                    <label htmlFor="email" className="text-white mb-3">
                                        Password <sup style={{
                                            fontSize: '10px'
                                        }}>
                                            (8-36 Characters)
                                        </sup> *
                                    </label>
                                    <ChatSearchInput
                                        name="amount"
                                        type="password"
                                        placeholder="Enter Password"
                                        value={bankPassword}
                                        onChange={(value) => {
                                            const password = value.target.value;
                                            setBankPassword(password);
                                        }}
                                        className={`form-control w-100 `}
                                    />


                                </InputDiv>
                            </div>

                            <FormButton variant="outline-primary" onClick={() => handleCreateBankCard("VIRTUAL")} disabled={!bankPassword || bankPassword.length < 8} className={'col-12 col-md-4'} type="submit">
                                Create Virtual Card
                            </FormButton>
                        </div>
                    ) :
                        (
                            <>
                                <StyledDiv>
                                    <div className='d-flex flex-column gap-2 p-1 main'>
                                        <div className='d-flex justify-content-between'>
                                            <img src='https://i.ibb.co/G9pDnYJ/chip.png' style={{
                                                width: '22px',
                                                height: '22px'
                                            }} alt='chip' />

                                            <p style={{
                                                fontSize: '14px'
                                            }}>Virtual Bank Card</p>
                                        </div>

                                        <div className='d-flex align-items-end flex-row gap-2 gap-md-2'>
                                            {formattedCardNumber.map((group, index) => (
                                                <p key={index}>{group}</p>
                                            ))}
                                        </div>

                                        <div className='d-flex  flex-row justify-content-between'>
                                            <p>CARD HOLDER</p>
                                            <p className='d-flex  text-uppercase'>{cardData?.name}</p>
                                        </div>
                                        <div className='d-flex  flex-row justify-content-between'>
                                            <p>VALID TILL</p>
                                            <p>{moment(cardData?.expiryData).format('MM / YY')}</p>
                                        </div>
                                        <div className='d-flex flex-row align-items-center flex-md-row justify-content-between'>
                                            <span
                                                style={{
                                                    textTransform: "Capitalize",
                                                    float: "left",
                                                }}
                                                className={` px-2 d-flex border rounded ${cardData?.status === "ACTIVE"
                                                    ? "badge-success"
                                                    : "badge-danger"}`}
                                            >
                                                <p> {cardData?.status}</p>
                                            </span>
                                            <Switch onChange={() => { handleChange(cardData?.id) }}
                                                checked={checked} />
                                        </div>
                                    </div>
                                </StyledDiv>

                                {!ChangePassword && <FormButton variant="outline-primary" onClick={() => setChangePassword(true)} className={'col-12'} type="submit">
                                    Change Password
                                </FormButton>}

                            </>
                        )
                }

                {
                    ChangePassword && (
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <div className="col-12 mt-1">
                                <InputDiv>
                                    <label htmlFor="email" className="text-white mb-3">
                                        New Password <sup style={{
                                            fontSize: '10px'
                                        }}>
                                            (8-36 Characters)
                                        </sup> *
                                    </label>
                                    <ChatSearchInput
                                        name="amount"
                                        type="password"
                                        placeholder="Enter New Password"
                                        value={newPassword}
                                        onChange={(value) => {
                                            const password = value.target.value;
                                            setNewPassword(password);
                                        }}
                                        className={`form-control w-100 `}
                                    />


                                </InputDiv>
                            </div>

                            <FormButton variant="outline-primary" onClick={() => handleChangePassword()} disabled={!newPassword || newPassword.length < 8} className={'col-12'} type="submit">
                                Change Password
                            </FormButton>
                        </div>
                    )
                }

                {/* physical card  */}

                {
                    !physicalCardData ? (
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <div className="col-12 mt-1">
                                <InputDiv>
                                    <label htmlFor="email" className="text-white mb-3">
                                        Password <sup style={{
                                            fontSize: '10px'
                                        }}>
                                            (8-36 Characters)
                                        </sup> *
                                    </label>
                                    <ChatSearchInput
                                        name="amount"
                                        type="password"
                                        placeholder="Enter Password"
                                        value={bankPassword}
                                        onChange={(value) => {
                                            const password = value.target.value;
                                            setBankPassword(password);
                                        }}
                                        className={`form-control w-100 `}
                                    />


                                </InputDiv>
                            </div>

                            <FormButton variant="outline-primary" onClick={() => handleCreateBankCard("PHYSICAL")} disabled={!bankPassword || bankPassword.length < 8} className={'col-12'} type="submit">
                                Create Physical Card
                            </FormButton>
                        </div>
                    ) :
                        (
                            <>
                                <StyledDiv>
                                    <div className='d-flex justify-content-between flex-column p-2 main'>
                                        <div className='d-flex justify-content-between'>
                                            <img src='https://i.ibb.co/G9pDnYJ/chip.png' style={{
                                                width: '22px',
                                                height: '22px'
                                            }} alt='chip' />

                                            <p style={{
                                                fontSize: '14px'
                                            }}>Physical Bank Card</p>
                                        </div>

                                        <div className='d-flex align-items-end flex-row gap-2 gap-md-2'>
                                            {formattedPhysicalCardNumber.map((group, index) => (
                                                <p key={index}>{group}</p>
                                            ))}
                                        </div>

                                        <div className='d-flex  flex-row justify-content-between'>
                                            <p>CARD HOLDER</p>
                                            <p className='d-flex  text-uppercase'>{physicalChecked?.name}</p>
                                        </div>
                                        <div className='d-flex  flex-row justify-content-between'>
                                            <p>VALID TILL</p>
                                            <p>{moment(physicalChecked?.expiryData).format('MM / YY')}</p>
                                        </div>
                                    
                                    </div>
                                </StyledDiv>

                                {!ChangePassword && <FormButton variant="outline-primary" onClick={() => setChangePassword(true)} className={'col-12'} type="submit">
                                    Change Password
                                </FormButton>}

                            </>
                        )
                }




            </WalletInfoWrapper>
            <Container hidden>
                <Row xs={12} md={6} lg={3} style={{ textTransform: 'Capitalize' }}>
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <WalletInfoWrapper>

                            <WalletStatRowWrapper>
                                <WalletStatRowTitle>Send</WalletStatRowTitle>
                                <WalletStatRowValue>
                                    {isLoading ? <Spinner style={{ color: colorAccent }} />
                                        : (

                                            `${sendAmount} $`
                                        )}
                                </WalletStatRowValue>
                            </WalletStatRowWrapper>
                            <WalletStatRowWrapper>
                                <WalletStatRowTitle>Receive</WalletStatRowTitle>
                                <WalletStatRowValue>
                                    {isLoading ? <Spinner style={{ color: colorAccent }} />
                                        : (

                                            `${receiveAmount} $`
                                        )}
                                </WalletStatRowValue>
                            </WalletStatRowWrapper>
                        </WalletInfoWrapper>
                    </Col>
                </Row>
            </Container>
        </Panel>
    );
};

StrigaWalletInfo.propTypes = {
    dir: PropTypes.string.isRequired,
};

export default StrigaWalletInfo;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  float: right;
  width: 100%;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const StyledDiv = styled.div`
  background-image: url('https://i.ibb.co/PYss3yv/map.png'), linear-gradient(45deg, #0045c7, #ff2c7d);
  background-blend-mode: overlay;
  border: 1px solid white;
  border-radius: 10px;
  color: rgb(155, 155, 155);
  font-size: 14px;
  font-weight: 500;
  height: 120px;
  line-height: 20px;
//   padding: 16px;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 12px;
    height: 300px;

  }
`;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;